import type { FC } from 'react';
import { SiAllocationRuleset } from 'dto/siAllocationRuleset';
import { TransLabel } from 'i18n/trans/label';
import { Link } from 'react-router-dom';
import { SearchCard } from '@fleet/shared';
import { useSelector } from 'store/utils';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';

interface SiAllocationRulesetCardProps extends SiAllocationRuleset {}

export const SiAllocationRulesetCard: FC<SiAllocationRulesetCardProps> = ({
  id,
  name,
  ownerId,
}) => {
  const businessEntities = useSelector(businessEntitiesSelector);
  return (
    <SearchCard
      title={name}
      list={[
        [
          'owner',
          <TransLabel i18nKey="owner" />,
          businessEntities.find(({ id }) => id === ownerId)?.name,
        ],
      ]}
      {...{
        component: Link,
        to: `/allocation-rulesets/edit/${id}`,
      }}
    />
  );
};
