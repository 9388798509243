import { FC, useCallback } from 'react';
import { setCurrentVehicle } from 'features/vehicle/vehicleActions';
import { useDispatch, useSelector } from 'store/utils';
import { Tabs } from 'components/common/tabs/Tabs';
import { currentVehicleSelector } from 'features/vehicle/vehicleSelector';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import VehicleProfiles from 'routes/vehicles/VehicleProfiles';
import { Designer } from 'routes/designer/Designer';
import classNames from 'classnames';

interface VehiclesProps extends RouteComponentProps {}

export const Vehicles: FC<VehiclesProps> = ({ match, location }) => {
  const dispatch = useDispatch();
  const { path } = match;
  const currentVehicle = useSelector(currentVehicleSelector);
  const onCurrentTabClose = useCallback(
    () => dispatch(setCurrentVehicle()),
    [dispatch]
  );
  const homePath = `${path}/all`;
  const onDesignerRoute = String(location) !== homePath;
  return (
    <>
      <Tabs
        basePath="/vehicles"
        currentEntity={currentVehicle}
        onCurrentTabClose={onCurrentTabClose}
        className={classNames({ 'designer-tabs': onDesignerRoute })}
      ></Tabs>
      <Switch>
        <Route exact path={homePath} component={VehicleProfiles} />
        <Route path={`${path}/:vehicleId?`} component={Designer} />
      </Switch>
    </>
  );
};
