import { Dropdown } from '@fleet/shared/mui';
import React, { FC, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Classifier } from '@fleet/shared/dto/classifier';

interface CompositionsDropdownProps {
  currentCompositionId: number;
  compositions: Array<Classifier<number>>;
}

export const CompositionsDropdown: FC<CompositionsDropdownProps> = ({
  currentCompositionId,
  compositions,
}) => {
  const history = useHistory();
  const { state } = useLocation();
  const currentCompositionName = useMemo(
    () => compositions.find(({ id }) => id === currentCompositionId)?.name,
    [compositions, currentCompositionId]
  );
  const compositionSelectHandler = useCallback(
    (id: string) => {
      history.replace({
        state,
        search: `compositionId=${id}`,
      });
    },
    [history, state]
  );

  return (
    <Dropdown
      className="compositions-control"
      label={currentCompositionName ?? ''}
      options={compositions.map(({ id, name }) => ({
        label: name,
        onClick: compositionSelectHandler.bind(null, String(id)),
        value: String(id),
      }))}
    />
  );
};
