import { FC } from 'react';
import { TransLabel } from 'i18n/trans/label';
import { AllocationScoreTemplate } from 'dto/allocationScoreTemplate';
import { useSelector } from 'store/utils';
import {
  businessEntitiesSelector,
  inventoryClassesOptionsSelector,
} from 'features/classification/classificationSelectors';
import { SearchCard } from '@fleet/shared';
import { Link } from 'react-router-dom';

interface AllocationScoreTemplateCardProps extends AllocationScoreTemplate {}

export const AllocationScoreTemplateCard: FC<AllocationScoreTemplateCardProps> =
  ({
    ownerId,
    name,
    id,
    inventoryClassId,
    passengerCountFrom,
    passengerCountTo,
  }) => {
    const businessEntities = useSelector(businessEntitiesSelector);
    const inventoryClassOptions = useSelector(inventoryClassesOptionsSelector);

    return (
      <SearchCard
        title={name}
        list={[
          [
            'owner',
            <TransLabel i18nKey="owner" />,
            businessEntities.find(({ id }) => id === ownerId)?.name,
          ],
          [
            'inventoryClass',
            <TransLabel i18nKey="inventoryClass" />,
            inventoryClassOptions.find(
              ({ value }) => value === inventoryClassId
            )?.label,
          ],
          [
            'passengerCountFromTo',
            <TransLabel i18nKey="passengerCountFromTo" />,
            passengerCountFrom || passengerCountTo ? (
              `${passengerCountFrom ?? '...'} - ${passengerCountTo ?? '...'}`
            ) : (
              <TransLabel i18nKey="notDefined" />
            ),
          ],
        ]}
        {...{
          component: Link,
          to: `/allocation-score-templates/edit/${id}`,
        }}
      />
    );
  };
