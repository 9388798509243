import type { FC } from 'react';
import type { PaginationParams } from '@fleet/shared/dto/pagination';
import { useCallback, useMemo } from 'react';
import {
  ManageSearch,
  type ManageSearchProps,
} from 'components/manage/ManageSearch';
import {
  LineTemplateSearchForm,
  type LineTemplateSearchFormProps,
} from 'components/lineTemplate/LineTemplateSearchForm';
import { SearchResult } from '@fleet/shared';
import {
  LineTemplateSearchTable,
  type LineTemplateSearchTableProps,
} from 'components/lineTemplate/LineTemplateSearchTable';
import { useDispatch, useSelector } from 'store/utils';
import {
  lineTemplateFilterSelector,
  lineTemplateListSelector,
} from 'features/lineTemplate/lineTemplateSelectors';
import { lineTemplateLoadingSelector } from 'features/loading/loadingSelectors';
import { searchLineTemplates } from 'features/lineTemplate/lineTemplateActions';

export interface LineTemplateSearchProps
  extends LineTemplateSearchFormProps,
    Pick<LineTemplateSearchTableProps, 'controlsAccessor' | 'getSubRow'>,
    Pick<ManageSearchProps, 'height'> {}

export const LineTemplateSearch: FC<LineTemplateSearchProps> = ({
  vehicleCompositionRelation,
  refreshSearch,
  height,
  ...props
}) => {
  const loading = useSelector(lineTemplateLoadingSelector);
  const filter = useSelector(lineTemplateFilterSelector);
  const lineTemplate = useSelector(lineTemplateListSelector);
  const data = useMemo(() => lineTemplate?.items ?? [], [lineTemplate?.items]);

  const dispatch = useDispatch();
  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) => {
      dispatch(searchLineTemplates({ ...filter, ...paginationParams }));
    },
    [filter, dispatch]
  );
  return (
    <ManageSearch
      loading={loading}
      form={
        <LineTemplateSearchForm
          vehicleCompositionRelation={vehicleCompositionRelation}
          refreshSearch={refreshSearch}
        />
      }
      height={height}
    >
      <SearchResult results={data.length} loading={loading}>
        <LineTemplateSearchTable
          data={data}
          limit={lineTemplate?.limit}
          total={lineTemplate?.totalCount}
          offset={lineTemplate?.offset}
          onPageChange={handlePageChange}
          {...props}
        />
      </SearchResult>
    </ManageSearch>
  );
};
