import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  clearCurrentComposition,
  getAssignedComposition,
} from 'features/composition/compositionActions';
import { CompositionVehicle } from 'routes/composition/CompositionVehicle';
import { useDispatch, useSelector } from 'store/utils';
import { CompositionManage } from 'components/compositionManage/CompositionManage';
import { CompositionsPanel } from 'components/palettePanel/CompositionsPanel';
import { setCurrentVehicle } from 'features/vehicle/vehicleActions';
import {
  compositionConstructSelector,
  compositionsSelector,
} from 'features/composition/compositionSelectors';
import { Modal } from '@fleet/shared/mui';
import { CompositionRowDto } from 'dto/composition';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import {
  getLineTemplate,
  updateLineTemplate,
} from 'features/lineTemplate/lineTemplateActions';
import {
  LineTemplateSearchTable,
  LineTemplateSearchTableProps,
} from 'components/lineTemplate/LineTemplateSearchTable';
import qs from 'qs';
import { lineTemplateCurrentSelector } from 'features/lineTemplate/lineTemplateSelectors';
import { ManageComposition } from 'components/manage/ManageComposition';
import { setLoadingKey } from 'features/common/commonActions';
import { COMPOSITION_VEHICLE_LOADING } from 'routes/composition/CompositionViewer';

const useStyles = makeStyles(
  () => ({
    root: {},
    table: { flex: 'unset' },
  }),
  {
    name: 'LineTemplateComposition',
  }
);

interface LineTemplateCompositionProps
  extends Pick<LineTemplateSearchTableProps, 'controlsAccessor'> {
  refreshComposition?: boolean;
}

export const LineTemplateComposition: FC<LineTemplateCompositionProps> = ({
  controlsAccessor,
  refreshComposition,
}) => {
  const { lineTemplateId: id } = useParams<{ lineTemplateId: string }>();
  const { compositionId } = qs.parse<{
    compositionId: string;
  }>(location.search, {
    ignoreQueryPrefix: true,
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fetchLineTemplateComposition = useCallback(async () => {
    dispatch(setLoadingKey(COMPOSITION_VEHICLE_LOADING));
    try {
      await Promise.all([
        dispatch(getLineTemplate(id)).unwrap(),
        dispatch(
          getAssignedComposition({
            compositionId,
            type: 'line-template',
          })
        ).unwrap(),
      ]);
    } finally {
      dispatch(setLoadingKey(null));
    }
  }, [compositionId, dispatch, id]);
  useEffect(() => {
    fetchLineTemplateComposition().then();
    return () => {
      dispatch(clearCurrentComposition());
      dispatch(setCurrentVehicle());
    };
  }, [dispatch, fetchLineTemplateComposition]);
  useEffect(() => {
    refreshComposition && fetchLineTemplateComposition().then();
  }, [fetchLineTemplateComposition, refreshComposition]);

  const lineTemplate = useSelector(lineTemplateCurrentSelector);
  const data = useMemo(
    () => (lineTemplate ? [lineTemplate] : []),
    [lineTemplate]
  );

  const [replaceComposition, setReplaceComposition] =
    useState<CompositionRowDto | null>(null);
  const compositionConstruct = useSelector(compositionConstructSelector);
  const compositionOptions = useSelector(compositionsSelector);

  const onCompositionDrop = useCallback(
    async (compositionId: string) => {
      const compositionToReplace = compositionOptions.find(
        ({ id }) => id === +compositionId!
      )!;
      setReplaceComposition(compositionToReplace);
    },
    [compositionOptions]
  );

  const replaceCurrentComposition = useCallback(async () => {
    const { id } = replaceComposition!;
    const { vehicleCompositions } = lineTemplate!;
    const compositionToReplace = vehicleCompositions.find(
      ({ lineTemplateVehicleCompositionId }) =>
        lineTemplateVehicleCompositionId === +compositionId!
    )!;
    const {
      lineTemplateVehicleCompositionId,
      startDate,
      endDate,
      departureTimes,
      vehicleCompositionDirectionId,
    } = compositionToReplace;

    await dispatch(
      updateLineTemplate({
        startDate,
        endDate,
        departureTimes,
        lineTemplateId: lineTemplateVehicleCompositionId,
        vehicleCompositionId: id,
        vehicleCompositionDirectionId,
      })
    ).unwrap();

    await fetchLineTemplateComposition();
  }, [
    compositionId,
    dispatch,
    fetchLineTemplateComposition,
    lineTemplate,
    replaceComposition,
  ]);

  const onVehicleChange = useCallback(
    (orderNumber: number) => {
      dispatch(
        setCurrentVehicle(
          compositionConstruct!.compositionVehiclesData![orderNumber - 1]!
        )
      );
    },
    [compositionConstruct, dispatch]
  );

  const vehicleTabsRender = useCallback(
    (props) => (
      <CompositionVehicle
        key={props.vehicle.orderNumber}
        {...props}
        readOnly
        actions={{ onVehicleChange }}
        transportationTypeId={compositionConstruct?.transportationTypeId}
      />
    ),
    [compositionConstruct?.transportationTypeId, onVehicleChange]
  );
  const lineTemplateCompositions = useMemo(() => {
    return lineTemplate
      ? lineTemplate.vehicleCompositions.map(
          ({ lineTemplateVehicleCompositionId, name }) => ({
            id: lineTemplateVehicleCompositionId,
            name,
          })
        )
      : [];
  }, [lineTemplate]);

  const classes = useStyles();
  if (!lineTemplate) return null;

  return (
    <ManageComposition
      className={classes.root}
      title={<TransTitle i18nKey="manageCompositionForLineTemplate" />}
      table={
        <LineTemplateSearchTable
          className={classes.table}
          data={data}
          {...(compositionConstruct && { controlsAccessor })}
        />
      }
    >
      <CompositionManage
        compositionsList={lineTemplateCompositions}
        palettePanel={<CompositionsPanel />}
        onViewerDrop={onCompositionDrop}
        vehicleTabsRenderFn={vehicleTabsRender}
        readOnly
      />
      {replaceComposition && (
        <Modal
          open
          title={<TransTitle i18nKey="replaceConfirmation" />}
          message={
            t('message.replacingCurrentComposition', {
              defaultValue:
                'You are replacing current composition with {{name}}',
              name: replaceComposition.name,
            })!
          }
          actionButton={{
            label: <TransButton i18nKey="update" />,
            onClick: replaceCurrentComposition,
          }}
          onClose={setReplaceComposition.bind(null, null)}
        />
      )}
    </ManageComposition>
  );
};
