import { createAction } from '@reduxjs/toolkit';
import { readVehicle } from 'features/vehicle/vehicleActions';
import { ManageableSpace } from '@fleet/widget/dto/floor';
import { FloorElement, FloorSize } from '@fleet/widget/dto/floor';
import { ElementCategory } from '@fleet/widget/dto/element';
import { RootState } from 'store';
import { createAsyncThunk } from 'store/utils';
import { prepareFloorElementPayload } from 'features/utils';
import { api } from '@fleet/shared';

export const getArguments = (state: RootState) => {
  const {
    vehicle: { currentVehicle },
    common: { currentBusinessEntityId },
    floor: { list, activeFloorIdx },
  } = state;
  const vehicleId = currentVehicle!.id;
  return {
    pathPrefix: `/organizations/${currentBusinessEntityId}/vehicles/${vehicleId}`,
    vehicleId,
    floorId: list[activeFloorIdx] && list[activeFloorIdx].id,
  };
};

export const setActiveFloorIdx = createAction<number>('setActiveFloorIdx');
export const addElement =
  createAction<{ floorElements: Array<FloorElement> }>('addElement');

export const createFloor = createAsyncThunk<Promise<void>, undefined>(
  'floor/create',
  async (_, { dispatch, getState }) => {
    const size = { x: 320, y: 612 };
    const { vehicleId, pathPrefix } = getArguments(getState());
    await api.post(`${pathPrefix}/floors`, { size });

    dispatch(readVehicle(vehicleId!));
  }
);

export const updateFloor = createAsyncThunk<
  Promise<void>,
  { id: number; size: FloorSize }
>('floor/update', async ({ id, size }, { dispatch, getState }) => {
  const { vehicleId, pathPrefix } = getArguments(getState());
  await api.put(`${pathPrefix}/floors/${id}`, { size });

  dispatch(readVehicle(vehicleId!));
});

export const deleteFloor = createAsyncThunk<Promise<void>, number>(
  'floor/delete',
  async (floorId, { dispatch, getState }) => {
    const { vehicleId, pathPrefix } = getArguments(getState());
    await api.delete(`${pathPrefix}/floors/${floorId}`);

    dispatch(setActiveFloorIdx(0));
    dispatch(readVehicle(vehicleId!));
  }
);

export const addManageableSpace = createAsyncThunk<
  Promise<void>,
  { floorId: number; manageableSpace: Partial<ManageableSpace> }
>(
  'floor/addManageableSpace',
  async ({ floorId, manageableSpace }, { dispatch, getState }) => {
    const { vehicleId, pathPrefix } = getArguments(getState());
    await api.post(`${pathPrefix}/floors/${floorId}/manageable-spaces`, {
      ...manageableSpace,
      typeId: manageableSpace.type!.id,
    });

    dispatch(readVehicle(vehicleId!));
  }
);
export const updateManageableSpace = createAsyncThunk<
  Promise<void>,
  { floorId: number; id: number; updatePayload: Partial<ManageableSpace> }
>(
  'floor/updateManageableSpace',
  async ({ floorId, id, updatePayload }, { dispatch, getState }) => {
    const state = getState();
    const { vehicleId, pathPrefix } = getArguments(state);
    const currentFloor = state.floor.list.find(({ id }) => id === floorId);
    const currentManageableSpace = currentFloor!.manageableSpaces.find(
      (space) => space.id === id
    );
    await api.put(`${pathPrefix}/floors/${floorId}/manageable-spaces/${id}`, {
      ...currentManageableSpace,
      ...updatePayload,
      typeId: updatePayload.type!.id,
    });

    dispatch(readVehicle(vehicleId!));
  }
);

export const deleteManageableSpace = createAsyncThunk<
  Promise<void>,
  { floorId: number; id: number }
>(
  'floor/deleteManageableSpace',
  async ({ floorId, id }, { dispatch, getState }) => {
    const { vehicleId, pathPrefix } = getArguments(getState());

    await api.delete(`${pathPrefix}/floors/${floorId}/manageable-spaces/${id}`);

    dispatch(readVehicle(vehicleId!));
  }
);

export const createFloorElement = createAsyncThunk<
  Promise<void>,
  Array<Partial<FloorElement>>
>('floor/element/create', async (floorElements, { dispatch, getState }) => {
  const { vehicleId, pathPrefix, floorId } = getArguments(getState());
  await api.post(`${pathPrefix}/floors/${floorId}/floor-elements`, {
    floorElements: floorElements.map(prepareFloorElementPayload),
  });

  await dispatch(readVehicle(vehicleId!));
});

export const copyFloorElement = createAsyncThunk<
  Promise<void>,
  Array<Partial<FloorElement>>
>('floor/element/create', async (floorElements, { dispatch, getState }) => {
  const { vehicleId, pathPrefix, floorId } = getArguments(getState());
  await api.post(`${pathPrefix}/floors/${floorId}/floor-elements/copy`, {
    floorElements: floorElements.map(({ id, category, coordinates }) => ({
      id,
      category,
      coordinates,
    })),
  });

  await dispatch(readVehicle(vehicleId!));
});

export const updateFloorElements = createAsyncThunk<
  unknown,
  Array<FloorElement>
>('floor/element/update', async (floorElements, { dispatch, getState }) => {
  const { vehicleId, pathPrefix, floorId } = getArguments(getState());
  if (!floorElements.length) return;
  try {
    await api.put(`${pathPrefix}/floors/${floorId}/floor-elements`, {
      floorElements: floorElements.map(prepareFloorElementPayload),
    });
  } catch (e) {
    return Promise.reject(e);
  } finally {
    await dispatch(readVehicle(vehicleId!));
  }
});

export const deleteElement = createAsyncThunk<
  Promise<void>,
  {
    category: ElementCategory;
    ids: Array<string>;
  }
>('floor/element/delete', async ({ category, ids }, { dispatch, getState }) => {
  const { vehicleId, pathPrefix, floorId } = getArguments(getState());

  await api.delete(
    `${pathPrefix}/floors/${floorId}/floor-elements/${category}/${ids.join(
      ','
    )}`
  );

  await dispatch(readVehicle(vehicleId!));
});
