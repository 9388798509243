import { createSlice } from '@reduxjs/toolkit';
import {
  getVehicles,
  setCurrentVehicle,
  setSortBy,
} from 'features/vehicle/vehicleActions';
import { getVehicleSnugs } from 'features/vehicle/vehicleSnugActions';
import type { Vehicle } from '@fleet/widget/dto/vehicle';
import type { Snugs as VehicleSnugs } from '@fleet/widget/dto/floor';
import type { VehicleList } from 'dto/vehicle';
import type { SortBy } from 'components/search/SearchTable';

export interface VehicleState {
  list: VehicleList;
  snugs: VehicleSnugs;
  sortBy: SortBy;
  currentVehicle?: Vehicle;
}

const initialState: VehicleState = {
  list: [],
  snugs: [],
  sortBy: { id: 'modified', desc: true },
};

export const { reducer: vehicleReducer } = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVehicles.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(setCurrentVehicle, (state, { payload: vehicle }) => {
        if (vehicle) {
          state.currentVehicle = vehicle;
        } else {
          delete state.currentVehicle;
        }
      })
      .addCase(getVehicleSnugs.fulfilled, (state, { payload }) => {
        state.snugs = payload;
      })
      .addCase(setSortBy, (state, action) => {
        state.sortBy = { ...state.sortBy, ...action.payload };
      });
  },
});
