import { selector } from 'store/utils';

export const selectSiServiceAllocationRulesetsFilter = selector(
  (state) => state.siServiceAllocationRuleset.filter
);
export const selectSiServiceAllocationRulesets = selector(
  (state) => state.siServiceAllocationRuleset.list
);
export const selectSiServiceAllocationRuleset = selector(
  (state) => state.siServiceAllocationRuleset.current
);
