import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import type { VehicleCompositionRelation } from 'dto/composition';
import type { TripFilterDto } from 'dto/trip';
import type { Config as FinalFormConfig } from 'final-form';
import {
  Button,
  CheckboxField,
  FormControl,
  formSubmit,
  DateField,
  FormProvider,
  RadioGroupField,
  SearchForm,
  SelectField,
  ToggleButtonGroupField,
  useForm,
} from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { useCallback, useEffect, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'store/utils';
import { assignEntityFilterOptionsSelector } from 'features/classification/classificationSelectors';
import { TransLabel } from 'i18n/trans/label';
import { Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { searchTrips } from 'features/trip/tripActions';
import { formatDate, isoDateTimeFormat } from '@fleet/shared/utils/date';
import { tripFilterSelector } from 'features/trip/tripSelectors';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'TripSearchForm',
  }
);

export interface TripSearchFormProps {
  vehicleCompositionRelation: VehicleCompositionRelation;
  refreshSearch?: boolean;
}

export const TripSearchForm: FC<TripSearchFormProps> = ({
  vehicleCompositionRelation,
  refreshSearch,
}) => {
  const filter = useSelector(tripFilterSelector);
  const initialValues = useMemo<Partial<TripFilterDto>>(
    () => ({
      isTripActive: true,
      serviceCodes: [],
      subContractorIds: [],
      tripDateFromTo: {
        from: formatDate(new Date(), isoDateTimeFormat.split("'T'")[0]),
      },
      vehicleCompositionRelation,
      ...filter,
    }),
    [filter, vehicleCompositionRelation]
  );
  const dispatch = useDispatch();
  useEffect(() => {
    refreshSearch && dispatch(searchTrips());
  }, [dispatch, refreshSearch]);
  const onSubmit = useCallback<FinalFormConfig<TripFilterDto>['onSubmit']>(
    ({ vehicleCompositionRelation, ...values }) =>
      formSubmit(() =>
        dispatch(
          searchTrips({
            // TODO, probably change it to boolean?
            ...(vehicleCompositionRelation && {
              vehicleCompositionRelation: vehicleCompositionRelation
                ? 'has_compositions'
                : 'all',
            }),
            ...values,
            offset: 0,
          })
        ).unwrap()
      ),
    [dispatch]
  );
  const { form, handleSubmit } = useForm<TripFilterDto>({
    initialValues,
    onSubmit,
  });

  const {
    lines,
    serviceCodes,
    vehicleCompositions,
    subContractors,
    tripStatuses,
    days,
  } = useSelector(assignEntityFilterOptionsSelector);

  const classes = useStyles();
  return (
    <SearchForm
      className={classes.root}
      title={<TransTitle i18nKey="search" />}
    >
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={5} spacing={2}>
            <Grid item xs={1}>
              <SelectField
                name="lineId"
                label={<TransLabel i18nKey="lineNumber" />}
                options={lines}
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="serviceCodes"
                label={<TransLabel i18nKey="serviceCode" />}
                options={serviceCodes}
                multiple
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="subContractorIds"
                label={<TransLabel i18nKey="subContractor" />}
                options={subContractors}
                multiple
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="vehicleCompositionId"
                label={<TransLabel i18nKey="vehicleCompositionsConnected" />}
                options={vehicleCompositions}
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="tripStatus"
                label={<TransLabel i18nKey="tripStatus" />}
                options={tripStatuses}
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <DateField
                name="tripDateFromTo"
                label={<TransLabel i18nKey="tripDateFromTo" />}
                selectsRange
              />
            </Grid>
            <Grid item xs="auto">
              <ToggleButtonGroupField
                name="departureDays"
                label={<TransLabel i18nKey="departureDays" />}
                options={days}
                multiple
              />
            </Grid>
            <Grid item xs="auto">
              <RadioGroupField
                name="isTripActive"
                label={<TransLabel i18nKey="active" />}
                options="BOOL_ONLY"
                inline
              />
            </Grid>
            {vehicleCompositionRelation !== 'all' && (
              <Grid item xs="auto">
                <FormControl label="&nbsp;">
                  <CheckboxField
                    name="vehicleCompositionRelation"
                    label={<TransLabel i18nKey="hasCompositions" />}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
