import { DragEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { useTranslation } from 'react-i18next';
import { getVehicles } from 'features/vehicle/vehicleActions';
import { vehicleListSelector } from 'features/vehicle/vehicleSelector';
import { Input, Tooltip } from '@fleet/shared/mui';
import { setIsDraggingFromPalette } from 'features/common/commonActions';
import { VehicleType } from 'dto/vehicle';
import { TransTitle } from 'i18n/trans/title';
import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { LayoutColScrollable } from 'components/layout/LayoutColScrollable';

export const VehiclesPanel = ({
  vehicleTypeId,
}: {
  vehicleTypeId?: VehicleType;
}) => {
  const [searchStr, setSearchStr] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getVehicles({
        vehicleTypeId,
      })
    );
  }, [dispatch, vehicleTypeId]);
  const vehicleList = useSelector(vehicleListSelector);
  const handleDragStart = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      dispatch(setIsDraggingFromPalette(true));
      event.dataTransfer.setData('text', event.currentTarget.id);
    },
    [dispatch]
  );

  const handleDragEnd = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      dispatch(setIsDraggingFromPalette(false));
      event.dataTransfer.clearData();
    },
    [dispatch]
  );

  const filteredVehicles = useMemo(
    () =>
      vehicleList.filter(
        ({ name }) =>
          name.toLocaleLowerCase().indexOf(searchStr.toLocaleLowerCase()) > -1
      ),
    [vehicleList, searchStr]
  );

  return (
    <LayoutColScrollable>
      <Typography variant="h2" paragraph>
        <TransTitle i18nKey="vehiclePlans" />
      </Typography>
      <Grid container columns={1} spacing={2}>
        <Grid item xs={1}>
          <Input
            className="search-input"
            placeholder={t('label.find', 'Find')!}
            icon="search"
            value={searchStr}
            onChange={(event) => setSearchStr(event.target.value)}
          />
        </Grid>

        {filteredVehicles.map(({ id, name }) => (
          <Grid key={id} item xs={1}>
            <Tooltip
              content={name}
              placement="bottom"
              PopperProps={{
                disablePortal: true,
              }}
            >
              <Paper
                id={String(id)}
                draggable="true"
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                sx={{ p: 1 }}
              >
                {name}
              </Paper>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </LayoutColScrollable>
  );
};
