import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransValidate = createTrans({
  allocatableInventoryUpperLimitAllowedOnlyForCompartments: (
    <Trans
      i18nKey="validate.allocatableInventoryUpperLimitAllowedOnlyForCompartments"
      defaults="Allocatable inventory lower limit percentage allowed only for compartments"
    />
  ),
});
