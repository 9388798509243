import { FC, useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { CardHeader, Layout, Loadable, SearchResult } from '@fleet/shared';
import { RouteComponentProps } from 'react-router-dom';
import {
  clearCurrentComposition,
  getComposition,
} from 'features/composition/compositionActions';
import { useDispatch, useSelector } from 'store/utils';
import { compositionConstructSelector } from 'features/composition/compositionSelectors';
import { LineTemplateSearch } from 'components/lineTemplate/LineTemplateSearch';
import {
  LineTemplateDto,
  LineTemplateVehicleComposition,
} from 'dto/lineTemplate';
import { LineTemplateEditModal } from 'routes/assignment/LineTemplateEditModal';
import { LineTemplateDetails } from 'components/lineTemplate/LineTemplateDetails';
import { RelationsSearchWrapper } from 'components/relationsSearchWrapper/RelationsSearchWrapper';
import { useCompositionManage } from 'hooks/useCompositionManage';
import { Button, Icon } from '@fleet/shared/mui';
import { Stack, Typography } from '@mui/material';
import { ModalProps } from '@fleet/shared/mui/Modal';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { TransButton } from 'i18n/trans/button';
import {
  getCompositionLineTemplates,
  setLineTemplates,
} from 'features/lineTemplate/lineTemplateActions';
import {
  LineTemplateSearchTable,
  LineTemplateSearchTableProps,
} from 'components/lineTemplate/LineTemplateSearchTable';
import { getCompositionTemplatesLoadingSelector } from 'features/loading/loadingSelectors';

const useStyles = makeStyles(
  () => ({
    assigned: {
      flex: 'unset',
      minHeight: 'unset',
      paddingBottom: 0,
    },
  }),
  {
    name: 'LineAssignment',
  }
);

interface LineAssignmentProps
  extends RouteComponentProps<{ compositionId: string }> {}

export const LineAssignment: FC<LineAssignmentProps> = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(
    () => () => {
      dispatch(setLineTemplates());
      dispatch(clearCurrentComposition());
    },
    [dispatch]
  );

  const { compositionId } = match.params;
  const { state, onModalClose, getModalHandler } = useCompositionManage<
    LineTemplateDto,
    LineTemplateVehicleComposition
  >();
  const loading = useSelector(getCompositionTemplatesLoadingSelector);
  const compositionConstruct = useSelector(compositionConstructSelector);
  const getCurrentCompositionLineTemplates = useCallback(
    async (params?: PaginationParams) => {
      await dispatch(
        getCompositionLineTemplates({
          vehicleCompositionId: +compositionId,
          ...params,
        })
      ).unwrap();
    },
    [compositionId, dispatch]
  );

  const onModalCloseHandler = useCallback<Required<ModalProps>['onClose']>(
    async (event, reason) => {
      reason === 'action' && (await getCurrentCompositionLineTemplates());
      onModalClose(event, reason);
    },
    [getCurrentCompositionLineTemplates, onModalClose]
  );

  const getCompositionWithLineTemplates = useCallback(async () => {
    await dispatch(getComposition(compositionId));
    await getCurrentCompositionLineTemplates();
  }, [compositionId, dispatch, getCurrentCompositionLineTemplates]);

  const getCurrentVehicleComposition = useCallback(
    (lineTemplate: LineTemplateDto) =>
      lineTemplate.vehicleCompositions.find(
        ({ id }) => id === compositionConstruct?.id
      ),
    [compositionConstruct]
  );
  const getSubRow = useCallback<
    Required<LineTemplateSearchTableProps>['getSubRow']
  >(
    (row) => (
      <LineTemplateDetails
        path="/manage/line-template"
        getModalHandler={getModalHandler}
        {...row}
      />
    ),
    [getModalHandler]
  );

  const addCompositionControl = useCallback(
    (data: LineTemplateDto[]) => {
      return (
        <Button
          variant="text"
          startIcon={<Icon name="plus" />}
          onClick={getModalHandler({
            data,
            vehicleComposition: compositionConstruct!,
            action: 'add',
          })}
          label={
            <Typography variant="body2">
              <TransLabel i18nKey="addComposition" />
            </Typography>
          }
        />
      );
    },
    [compositionConstruct, getModalHandler]
  );

  const connectedLineTemplatesControls = useCallback(
    (data: LineTemplateDto[]) => {
      const currentVehicleComposition = getCurrentVehicleComposition(data[0])!;

      return (
        <Stack
          direction="row"
          justifyContent="center"
          sx={{ whiteSpace: 'nowrap' }}
        >
          <Button
            variant="text"
            startIcon={<Icon name="replace" />}
            onClick={getModalHandler({
              data,
              vehicleComposition: currentVehicleComposition,
              action: 'replace',
            })}
            label={
              <Typography variant="body2">
                <TransButton i18nKey="update" />
              </Typography>
            }
          />
          <Button
            variant="text"
            startIcon={<Icon name="close" />}
            onClick={getModalHandler({
              data,
              vehicleComposition: currentVehicleComposition,
              action: 'delete',
            })}
            label={
              <Typography variant="body2">
                <TransButton i18nKey="delete" />
              </Typography>
            }
          />
        </Stack>
      );
    },
    [getCurrentVehicleComposition, getModalHandler]
  );
  const assignedLinesPresent =
    !!compositionConstruct?.lineTemplates?.data?.length;

  const title = useMemo(() => {
    if (!compositionConstruct) return '';
    const { name, code } = compositionConstruct;

    return [name, code].filter(Boolean).join('/');
  }, [compositionConstruct]);

  const classes = useStyles();
  return (
    <RelationsSearchWrapper onMount={getCompositionWithLineTemplates}>
      <Layout
        className={classes.assigned}
        header={<CardHeader title={title} />}
      >
        <Loadable loading={loading}>
          <Typography variant="subtitle" sx={{ p: 3 }}>
            <TransTitle i18nKey="lineTemplatesConnected" />
            {assignedLinesPresent && (
              <Typography variant="body2" component="span" sx={{ ml: 2 }}>
                <TransLabel
                  i18nKey="qtyTemplates"
                  values={{
                    count: compositionConstruct!.lineTemplates!.totalCount,
                  }}
                  tOptions={{ postProcess: 'interval' }}
                />
              </Typography>
            )}
          </Typography>
          <SearchResult
            loading={false}
            results={assignedLinesPresent}
            message={<TransLabel i18nKey="noLineTemplatesAssigned" />}
            icon={false}
          >
            {assignedLinesPresent && (
              <>
                <LineTemplateSearchTable
                  vehicleConstructId={compositionConstruct!.id}
                  data={compositionConstruct!.lineTemplates!.data}
                  total={compositionConstruct!.lineTemplates!.totalCount}
                  offset={compositionConstruct!.lineTemplates!.offset}
                  onPageChange={getCurrentCompositionLineTemplates}
                  controlsAccessor={connectedLineTemplatesControls}
                  getSubRow={(row) => (
                    <LineTemplateDetails
                      path="/manage/line-template"
                      vehicleConstructId={compositionConstruct!.id}
                      {...row}
                    />
                  )}
                  plugins={false}
                />
              </>
            )}
          </SearchResult>
        </Loadable>
      </Layout>

      <LineTemplateSearch
        vehicleCompositionRelation="all"
        refreshSearch={state.refreshSearch}
        controlsAccessor={addCompositionControl}
        getSubRow={getSubRow}
        height="auto"
      />

      {!!state.data.length && (
        <LineTemplateEditModal
          lineTemplates={state.data}
          vehicleComposition={state.vehicleComposition!}
          action={state.action}
          onClose={onModalCloseHandler}
        />
      )}
    </RelationsSearchWrapper>
  );
};
