import type { FC } from 'react';
import type { TripSearchTableProps } from 'components/trip/TripSearchTable';
import { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { RelationsSearchWrapper } from 'components/relationsSearchWrapper/RelationsSearchWrapper';
import { useCompositionManage } from 'hooks/useCompositionManage';
import { TripSearch } from 'components/trip/TripSearch';
import { TripDetails } from 'components/trip/TripDetails';
import { TripComposition } from 'routes/management/TripComposition';
import { TripEditModal } from 'routes/assignment/TripEditModal';
import { Typography } from '@mui/material';
import { Button, Icon } from '@fleet/shared/mui';
import { TransLabel } from 'i18n/trans/label';
import { TransButton } from 'i18n/trans/button';
import qs from 'qs';
import { TripDto, TripVehicleComposition } from 'dto/trip';

const useStyles = makeStyles(
  () => ({
    button: { whiteSpace: 'nowrap' },
  }),
  {
    name: 'Trip',
  }
);

interface TripProps extends RouteComponentProps {}

export const Trip: FC<TripProps> = ({ match: { path }, location }) => {
  const { compositionId } = qs.parse<{
    compositionId: string;
  }>(location.search, {
    ignoreQueryPrefix: true,
  });
  const { state, onModalClose, getModalHandler } = useCompositionManage<
    TripDto,
    TripVehicleComposition
  >();

  const classes = useStyles();
  const addCompositionControl = useCallback<
    Required<TripSearchTableProps>['controlsAccessor']
  >(
    (trips) => (
      <Button
        variant="text"
        className={classes.button}
        startIcon={<Icon name="plus" />}
        onClick={getModalHandler!({
          data: trips,
          vehicleComposition: undefined,
          action: 'addComposition',
        })}
        label={
          <Typography variant="body2">
            <TransLabel i18nKey="addComposition" />
          </Typography>
        }
      />
    ),
    [classes.button, getModalHandler]
  );
  const updateCompositionControl = useCallback<
    Required<TripSearchTableProps>['controlsAccessor']
  >(
    ([trip]) => (
      <Button
        variant="text"
        startIcon={<Icon name="replace" />}
        onClick={getModalHandler!({
          data: [trip],
          vehicleComposition: trip.vehicleCompositions.find(
            ({ tripVehicleCompositionId }) =>
              tripVehicleCompositionId === +compositionId
          )!,
          action: 'replace',
        })}
        label={
          <Typography variant="body2">
            <TransButton i18nKey="update" />
          </Typography>
        }
      />
    ),
    [compositionId, getModalHandler]
  );

  const renderSubRow = useCallback<
    Required<TripSearchTableProps>['renderSubRow']
  >(
    (row, selectedRows) => (
      <TripDetails
        path={path}
        selectedRows={selectedRows}
        getModalHandler={getModalHandler}
        {...row}
      />
    ),
    [getModalHandler, path]
  );

  return (
    <RelationsSearchWrapper>
      <Switch>
        <Route exact path={path}>
          <TripSearch
            vehicleCompositionRelation="has_compositions"
            refreshSearch={state.refreshSearch}
            controlsAccessor={addCompositionControl}
            renderSubRow={renderSubRow}
          />
        </Route>
        <Route path={`${path}/:tripId`}>
          <TripComposition
            controlsAccessor={updateCompositionControl}
            refreshComposition={state.refreshSearch}
          />
        </Route>
      </Switch>
      {Boolean(state.data?.length) && (
        <TripEditModal
          compositionId={compositionId}
          trips={state.data}
          vehicleComposition={state.vehicleComposition!}
          action={state.action}
          onClose={onModalClose}
        />
      )}
    </RelationsSearchWrapper>
  );
};
