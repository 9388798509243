import { useCallback } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import {
  compositionConstructSelector,
  compositionConstructVehicleSelector,
} from 'features/composition/compositionSelectors';
import {
  updateComposition,
  updateCompositionVehicle,
} from 'features/composition/compositionActions';
import {
  CarriageProperties,
  CarriagePropertiesProps,
} from 'components/propertiesPanel/CarriageProperties';
import { LayoutColScrollable } from 'components/layout/LayoutColScrollable';

export const CompositionProperties = () => {
  const currentCompositionVehicle = useSelector(
    compositionConstructVehicleSelector
  );
  const dispatch = useDispatch();
  const compositionConstruct = useSelector(compositionConstructSelector)!;
  const handleChangeVehicleFlipped = useCallback(() => {
    dispatch(
      updateCompositionVehicle({
        compositionId: `${compositionConstruct.id}`,
        compositionVehicleId: `${currentCompositionVehicle!.id}`,
        orderNumber: currentCompositionVehicle!.orderNumber,
        number: currentCompositionVehicle!.number!,
        salesOpeningPriority: currentCompositionVehicle!.salesOpeningPriority,
        salesOpeningThresholdPercentage:
          currentCompositionVehicle!.salesOpeningThresholdPercentage,
        isVehicleFlipped: !currentCompositionVehicle!.isVehicleFlipped,
      })
    );
  }, [dispatch, compositionConstruct, currentCompositionVehicle]);

  const updateCarriageNumber = useCallback<
    Required<CarriagePropertiesProps>['onCarriageChange']
  >(
    (payload) => {
      dispatch(
        updateCompositionVehicle({
          compositionId: `${compositionConstruct.id}`,
          compositionVehicleId: `${currentCompositionVehicle!.id}`,
          orderNumber: currentCompositionVehicle!.orderNumber,
          isVehicleFlipped: currentCompositionVehicle!.isVehicleFlipped,
          number: currentCompositionVehicle!.number!,
          salesOpeningPriority: currentCompositionVehicle!.salesOpeningPriority,
          salesOpeningThresholdPercentage:
            currentCompositionVehicle!.salesOpeningThresholdPercentage,
          ...payload,
        })
      );
    },
    [dispatch, compositionConstruct, currentCompositionVehicle]
  );

  const updateCompositionData = useCallback<
    Required<CarriagePropertiesProps>['updateCompositionData']
  >(
    (fieldName, e) => {
      dispatch(
        updateComposition({
          compositionId: compositionConstruct.id,
          name: compositionConstruct.name,
          code: compositionConstruct.code,
          [fieldName]: e.target.value,
        })
      );
    },
    [compositionConstruct, dispatch]
  );

  return (
    <LayoutColScrollable>
      <CarriageProperties
        updateCompositionData={updateCompositionData}
        onDirectionChange={handleChangeVehicleFlipped}
        onCarriageChange={updateCarriageNumber}
      />
    </LayoutColScrollable>
  );
};
