import { type FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout, Loadable } from '@fleet/shared';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'store/utils';
import { InventoryClassDetails } from 'routes/inventoryClass/InventoryClassDetails';
import { InventoryClassTable } from 'routes/inventoryClass/InventoryClassTable';
import { dataLoadingSelector } from 'features/common/commonSelectors';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'InventoryClass',
  }
);

interface InventoryClassProps {}

export const InventoryClass: FC<InventoryClassProps> = () => {
  const loading = useSelector(dataLoadingSelector);

  const classes = useStyles();
  return (
    <Loadable loading={loading}>
      <Layout
        className={classes.root}
        drawer={
          <Switch>
            <Route
              path="/inventory-class/:action(create|edit)/:id?"
              component={InventoryClassDetails}
              exact
            />
          </Switch>
        }
      >
        <InventoryClassTable />
      </Layout>
    </Loadable>
  );
};
