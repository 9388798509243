import type { FC, ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import {
  ElementCategory,
  PreparedPaletteElement,
} from '@fleet/widget/dto/element';
import { GridProps, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useContext } from 'react';
import { ViewerContext } from '@fleet/widget/components/viewer/Context';
import { PalettePanelElement } from 'components/palettePanel/PalettePanelElement';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '.Mui-disabled &': {
        cursor: 'not-allowed',
      },
      '& + $root': {
        marginTop: theme.spacing(2),
      },
    },
  }),
  {
    name: 'PalettePanelGroup',
  }
);

interface PalettePanelGroupProps extends Pick<GridProps, 'columns'> {
  title?: ReactNode;
  elements: Array<PreparedPaletteElement>;
  offset?: number;
}

export const PalettePanelGroup: FC<PalettePanelGroupProps> = ({
  title,
  elements,
  columns = 3,
  offset,
}) => {
  const { scale } = useContext(ViewerContext);

  const classes = useStyles();
  if (!elements.length) return null;

  return (
    <div className={classes.root}>
      {title && (
        <Typography variant="body1" fontWeight={600} gutterBottom>
          {title}
        </Typography>
      )}
      <Grid container columns={columns} spacing={1}>
        {elements.map((element, index) => {
          const { category, width, height } = element;
          return (
            <Grid
              key={element.elementId}
              item
              xs={category === ElementCategory.sign && width > 24 ? 2 : 1}
            >
              <PalettePanelElement
                scale={scale}
                {...element}
                {...(offset && {
                  height: height + offset * index,
                })}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
