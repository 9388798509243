import { FC, MouseEvent, useCallback, useMemo } from 'react';
import {
  LineTemplateDto,
  LineTemplateVehicleComposition,
} from 'dto/lineTemplate';
import { Row } from 'react-table';
import {
  Button,
  Collapsible,
  Icon,
  ReadOnlyField as Field,
} from '@fleet/shared/mui';
import { Timetable } from 'components/lineTemplate/Timetable';
import { formatDate } from '@fleet/shared/utils/date';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import { useSelector } from 'store/utils';
import { classifiersSelector } from 'features/classification/classificationSelectors';
import { TransButton } from 'i18n/trans/button';
import { ControlTooltip } from 'components/common/table/ControlTooltip';

// TODO clean up repeated styles
const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      whiteSpace: 'normal',
      position: 'relative',
    },
    card: {
      width: '50%',
      padding: '16px 24px',
      '&:first-of-type': {
        background: 'var(--bg-light)',
      },
      '&:last-of-type': {
        background: 'var(--bg)',
      },
    },
    dots: {
      position: 'absolute',
      right: 12,
    },
    timetable: {
      '&.Mui-expanded': {
        marginBottom: 0,
      },
      '& .MuiButtonBase-root': {
        display: 'inline-flex',
        padding: 0,
        background: 'transparent',
        color: theme.palette.text.primary,
        '&.MuiAccordionSummary-root': {
          minHeight: 16,
        },
        '& .MuiTypography-root': {
          fontSize: 12,
          lineHeight: '16px',
        },
      },
    },
    emptyControl: {
      position: 'absolute',
      top: 20,
      right: 20,

      '& .MuiButton-text': {
        paddingTop: 0,
      },
    },
    infoSection: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    endDateInfo: {
      '&.MuiFormControl-root': {
        paddingRight: '16px',
      },
    },
  }),
  {
    name: 'LineTemplateDetails',
  }
);

interface LineTemplateDetailsProps extends Row<LineTemplateDto> {
  path: string;
  vehicleConstructId?: number;
  getModalHandler?: (params: {
    data: LineTemplateDto[];
    action?: 'add' | 'replace' | 'delete' | 'addComposition';
    vehicleComposition: LineTemplateVehicleComposition | {};
  }) => (e?: MouseEvent) => void;
}

export const LineTemplateDetails: FC<LineTemplateDetailsProps> = ({
  path,
  getModalHandler,
  vehicleConstructId,
  ...row
}) => {
  const classes = useStyles();
  const {
    name,
    subContractor,
    startDate,
    endDate,
    departureTimes,
    vehicleCompositions,
  } = row.original;
  const getFormattedDate = useCallback((dateStr) => formatDate(dateStr), []);
  const preparedConnectedVehicles = useMemo(() => {
    return vehicleConstructId
      ? vehicleCompositions.filter(({ id }) => id === vehicleConstructId)
      : vehicleCompositions;
  }, [vehicleCompositions, vehicleConstructId]);
  const classifiers = useSelector(classifiersSelector);

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <h5>
          <TransTitle i18nKey="lineTemplateInformation" />
        </h5>
        <div className={classes.infoSection}>
          <Field label={<TransLabel i18nKey="templateName" />} value={name} />
          <Field
            label={<TransLabel i18nKey="subContractor" />}
            value={subContractor || <TransLabel i18nKey="noSubContractor" />}
          />
          <Field
            label={<TransLabel i18nKey="startDate" />}
            value={getFormattedDate(startDate)}
          />
          <Field
            label={<TransLabel i18nKey="endDate" />}
            value={getFormattedDate(endDate)}
          />
        </div>

        <Collapsible
          className={classes.timetable}
          title={<TransLabel i18nKey="timetable" />}
          arrowPosition="right"
        >
          <Timetable
            departureTimes={departureTimes}
            initialValue={departureTimes}
          />
        </Collapsible>
      </div>
      <div className={classes.card}>
        <h5>
          <TransTitle i18nKey="connectedVehicleCompositionInformation" />
        </h5>
        {preparedConnectedVehicles.map((composition, idx) => {
          const departureTimesValue = composition.departureTimesFromTemplate
            ? departureTimes
            : composition.departureTimes;
          return (
            <div key={idx}>
              {getModalHandler && (
                <>
                  <ControlTooltip dotsClassName={classes.dots} dotsSize={20}>
                    <Button
                      variant="text"
                      startIcon={<Icon name="edit" />}
                      component={Link}
                      to={{
                        pathname: `${path}/${row.original.id}`,
                        state: { lineTemplate: row.original },
                        search: `compositionId=${composition.lineTemplateVehicleCompositionId}`,
                      }}
                      label={<TransButton i18nKey="manage" />}
                    />

                    <Button
                      variant="text"
                      startIcon={<Icon name="replace" />}
                      onClick={getModalHandler!({
                        data: [row.original],
                        vehicleComposition: composition,
                        action: 'replace',
                      })}
                      label={<TransButton i18nKey="update" />}
                    />
                    <Button
                      variant="text"
                      startIcon={<Icon name="delete" />}
                      onClick={getModalHandler!({
                        data: [row.original],
                        vehicleComposition: composition,
                        action: 'delete',
                      })}
                      label={<TransButton i18nKey="delete" />}
                    />
                  </ControlTooltip>
                </>
              )}
              <div className={classes.infoSection}>
                <Field
                  label={<TransLabel i18nKey="vehicle" />}
                  value={composition.name}
                />
                <Field
                  label={<TransLabel i18nKey="code" />}
                  value={composition.code}
                />
                <Field
                  label={<TransLabel i18nKey="direction" />}
                  value={
                    classifiers.VEHICLE_COMPOSITION_DIRECTION.find(
                      ({ id }) =>
                        id === composition.vehicleCompositionDirectionId
                    )?.name
                  }
                />
                <Field
                  label={<TransLabel i18nKey="connectionStartDate" />}
                  value={getFormattedDate(composition.startDate)}
                />
                <Field
                  label={<TransLabel i18nKey="connectionEndDate" />}
                  className={classes.endDateInfo}
                  value={getFormattedDate(composition.endDate)}
                />
              </div>
              <Collapsible
                className={classes.timetable}
                title={<TransLabel i18nKey="timetable" />}
                arrowPosition="right"
              >
                <Timetable
                  departureTimes={departureTimes}
                  initialValue={departureTimesValue}
                />
              </Collapsible>
            </div>
          );
        })}
        {!preparedConnectedVehicles.length && (
          <div>
            <TransLabel i18nKey="noVehicleConnections" />

            {getModalHandler && (
              <Box className={classes.emptyControl}>
                <Button
                  variant="text"
                  startIcon={<Icon name="plus" />}
                  onClick={getModalHandler!({
                    data: [row.original],
                    vehicleComposition: {},
                    action: 'addComposition',
                  })}
                  label={
                    <Typography variant="body2">
                      <TransLabel i18nKey="addComposition" />
                    </Typography>
                  }
                />
              </Box>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
