import { useMemo } from 'react';
import { FloorElement } from '@fleet/widget/dto/floor';
import { InventoryType } from 'dto/organization';
import _get from 'lodash/get';

const every = <T, K extends keyof T>(arr: Array<T>, key: K | string) =>
  arr.every((item, _, [first]) => _get(item, key) === _get(first, key));

export const useInventory = (nodes: Array<FloorElement>) =>
  useMemo(() => {
    const [selected] = nodes;
    if (
      selected &&
      (selected.category === 'compartment' || every(nodes, 'typeId'))
    ) {
      return {
        type: ((types) => {
          return types[selected.typeId as keyof typeof types];
        })({
          'INTERNAL_TYPE.COMPARTMENT': InventoryType.COMPARTMENT,
          'PLACE_TYPE.SEAT': InventoryType.SEAT,
          'PLACE_TYPE.BERTH': InventoryType.BED,
        }),
        inventoryClassId: every(nodes, 'inventoryClass.id')
          ? selected.inventoryClass?.id
          : undefined,
        rank: every(nodes, 'rank') ? selected.rank : undefined,
        blockingReasonId: every(nodes, 'blockingReason.id')
          ? selected.blockingReason?.id
          : undefined,
      };
    }
  }, [nodes]);
