import type { FC } from 'react';
import type { PaginationParams } from '@fleet/shared/dto/pagination';
import { useCallback, useMemo } from 'react';
import {
  ManageSearch,
  ManageSearchProps,
} from 'components/manage/ManageSearch';
import {
  TripSearchForm,
  type TripSearchFormProps,
} from 'components/trip/TripSearchForm';
import { SearchResult } from '@fleet/shared';
import {
  TripSearchTable,
  type TripSearchTableProps,
} from 'components/trip/TripSearchTable';
import { useDispatch, useSelector } from 'store/utils';
import {
  tripFilterSelector,
  tripListSelector,
} from 'features/trip/tripSelectors';
import { tripLoadingSelector } from 'features/loading/loadingSelectors';
import { searchTrips } from 'features/trip/tripActions';

interface TripSearchProps
  extends TripSearchFormProps,
    Pick<
      TripSearchTableProps,
      'controlsAccessor' | 'getSubRow' | 'renderSubRow'
    >,
    Pick<ManageSearchProps, 'height'> {}

export const TripSearch: FC<TripSearchProps> = ({
  vehicleCompositionRelation,
  refreshSearch,
  height,
  ...props
}) => {
  const loading = useSelector(tripLoadingSelector);
  const filter = useSelector(tripFilterSelector);

  const tripList = useSelector(tripListSelector);
  const data = useMemo(() => tripList?.items ?? [], [tripList?.items]);

  const dispatch = useDispatch();
  const handlePageChange = useCallback(
    (paginationParams: PaginationParams) =>
      dispatch(searchTrips({ ...filter, ...paginationParams })).unwrap(),
    [filter, dispatch]
  );

  return (
    <ManageSearch
      loading={loading}
      form={
        <TripSearchForm
          vehicleCompositionRelation={vehicleCompositionRelation}
          refreshSearch={refreshSearch}
        />
      }
      height={height}
    >
      <SearchResult results={data.length} loading={loading}>
        <TripSearchTable
          data={data}
          limit={tripList?.limit}
          total={tripList?.totalCount}
          offset={tripList?.offset}
          onPageChange={handlePageChange}
          {...props}
        />
      </SearchResult>
    </ManageSearch>
  );
};
