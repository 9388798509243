import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransMessage = createTrans({
  allChangesSaved: (
    <Trans i18nKey="message.allChangesSaved" defaults="All changes saved" />
  ),
  allocationScoreTemplateCreated: (
    <Trans
      i18nKey="message.allocationScoreTemplateCreated"
      defaults="Allocation score template created"
    />
  ),
  allocationScoreTemplateDeleted: (
    <Trans
      i18nKey="message.allocationScoreTemplateDeleted"
      defaults="Allocation score template deleted"
    />
  ),
  allocationScoreTemplateUpdated: (
    <Trans
      i18nKey="message.allocationScoreTemplateUpdated"
      defaults="Allocation score template updated"
    />
  ),
  areYouSureYouWantToRemoveEntity: (
    <Trans
      i18nKey="message.areYouSureYouWantToRemoveEntity"
      defaults="Are you sure you want to remove the {{entity}}?"
    />
  ),
  bothSalesOpeningFilled: (
    <Trans
      i18nKey="message.bothSalesOpeningFilled"
      defaults="Either both of the new fields are filled or none of them is filled."
    />
  ),
  deleteContent: (
    <Trans
      i18nKey="message.deleteContent"
      defaults="Delete {{name}}? You can't undo this."
    />
  ),
  deleteVehicleCompositionFromTrip: (
    <Trans
      i18nKey="message.deleteVehicleCompositionContent"
      defaults="Are you sure you want to remove vehicle composition {{compositionName}} from trip {{tripName}}?"
    />
  ),
  dragAndDropVehicle: (
    <Trans
      i18nKey="message.dragAndDropVehicle"
      defaults="Drag and drop vehicle plan to artboard to start the construction."
    />
  ),
  fileSizeMax50Kb: (
    <Trans
      i18nKey="message.fileSizeMax50Kb"
      defaults="File can not exceed 50 kB"
    />
  ),
  intersectedElementPresent: (
    <Trans
      i18nKey="message.intersectedElementPresent"
      defaults="Intersected element is present on this coordinates"
    />
  ),
  inventoryClassCreated: (
    <Trans
      i18nKey="message.inventoryClassCreated"
      defaults="Inventory class created"
    />
  ),
  inventoryClassUpdated: (
    <Trans
      i18nKey="message.inventoryClassUpdated"
      defaults="Inventory class updated"
    />
  ),
  noCompositionsCreated: (
    <Trans
      i18nKey="message.noCompositionsCreated"
      defaults="There are no vehicle compositions created yet. Start new vehicle composition."
    />
  ),
  notAllActionsVisible: (
    <Trans
      i18nKey="message.notAllActionsVisible"
      defaults="Not all actions are visible in bulk edit."
    />
  ),
  outsideOfCanvas: (
    <Trans
      i18nKey="message.outsideOfCanvas"
      defaults="Element is outside of canvas"
    />
  ),
  reassigningTheSeats: (
    <Trans
      i18nKey="message.reassigningTheSeats"
      defaults="Reassigning the seats, please wait..."
    />
  ),
  replacingCurrentComposition: (
    <Trans
      i18nKey="message.replacingCurrentComposition"
      defaults="You are replacing current composition with {{name}}"
    />
  ),
  seatGroupsRecreated: (
    <Trans
      i18nKey="message.seatGroupsRecreated"
      defaults="Seat groups recreated"
    />
  ),
  serviceSiAllocationRulesetCreated: (
    <Trans
      i18nKey="modal.title.serviceSiAllocationRulesetCreated"
      defaults="Service sleeper inventory allocation ruleset created"
    />
  ),
  serviceSiAllocationRulesetCopied: (
    <Trans
      i18nKey="modal.title.serviceSiAllocationRulesetCopied"
      defaults="Service sleeper inventory allocation ruleset copied"
    />
  ),
  serviceSiAllocationRulesetsDeleted: (
    <Trans
      i18nKey="modal.title.serviceSiAllocationRulesetsDeleted"
      defaults="Service sleeper inventory allocation rulesets deleted"
    />
  ),
  serviceSiAllocationRulesetUpdated: (
    <Trans
      i18nKey="modal.title.serviceSiAllocationRulesetUpdated"
      defaults="Service sleeper inventory allocation ruleset updated"
    />
  ),
  siAllocationRulesetCreated: (
    <Trans
      i18nKey="message.siAllocationRulesetCreated"
      defaults="Sleeper inventory allocation ruleset created"
    />
  ),
  siAllocationRulesetDeleted: (
    <Trans
      i18nKey="message.siAllocationRulesetDeleted"
      defaults="Sleeper inventory allocation ruleset deleted"
    />
  ),
  siAllocationRulesetUpdated: (
    <Trans
      i18nKey="message.siAllocationRulesetUpdated"
      defaults="Sleeper inventory allocation ruleset updated"
    />
  ),
  vehiclesEmpty: (
    <Trans
      i18nKey="message.vehiclesEmpty"
      defaults="There are no vehicle designs created yet. Start new vehicle design."
    />
  ),
});
