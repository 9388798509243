import { createAsyncThunk } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { createAction } from '@reduxjs/toolkit';
import _first from 'lodash/first';
import _last from 'lodash/last';
import _find from 'lodash/find';
import {
  readVehicle,
  setCurrentVehicle,
} from 'features/vehicle/vehicleActions';
import {
  AssignedComposition,
  CompositionConstructDto,
  CompositionRowDto,
  TripCompositionProcess,
} from 'dto/composition';
import { CompositionConstructVehicleDto } from '@fleet/widget/dto/composition';
import { TransportationTypeId } from '@fleet/widget/dto/transportation';
import qs from 'qs';
import { api } from '@fleet/shared';
import {
  compositionConstructSelector,
  compositionConstructVehicleSelector,
} from 'features/composition/compositionSelectors';

export const setCompositionProcessId = createAction<string | undefined>(
  'setCompositionProcessId'
);

export const createComposition = createAsyncThunk<number, void>(
  'createComposition',
  async (_, { getState }) => {
    const state = getState();
    return (
      await api.post(
        `/organizations/${currentBusinessEntityIdSelector(
          state
        )}/vehicle-compositions`,
        {
          name: `Untitled-${state.composition.list.length}`,
        }
      )
    ).data.id;
  }
);

export const getCompositions = createAsyncThunk<
  { vehicleCompositions: Array<CompositionRowDto> },
  { searchString: string; transportationTypeId?: string } | undefined
>('getCompositions', async (params, { getState }) => {
  const state = getState();
  return (
    await api.get<{ vehicleCompositions: Array<CompositionRowDto> }>(
      `/organizations/${currentBusinessEntityIdSelector(
        state
      )}/vehicle-compositions/all?${qs.stringify({ ...params })}`
    )
  ).data;
});

export const clearCurrentComposition = createAction('clearCurrentComposition');

export const getComposition = createAsyncThunk<CompositionConstructDto, string>(
  'getComposition',
  async (compositionId, { getState }) => {
    const state = getState();
    const composition = (
      await api.get<CompositionConstructDto>(
        `/organizations/${currentBusinessEntityIdSelector(
          state
        )}/vehicle-compositions/${compositionId}`
      )
    ).data;

    const currentCompositionVehicle =
      compositionConstructVehicleSelector(state);
    const { compositionVehicles } = composition;
    const [vehicle] = compositionVehicles;
    const currentVehicle = compositionVehicles.find(
      ({ id }) => id === currentCompositionVehicle?.id
    );
    return {
      ...composition,
      id: Number(compositionId),
      vehicle: currentVehicle ?? vehicle,
    };
  }
);

export const getAssignedComposition = createAsyncThunk<
  CompositionConstructDto,
  {
    compositionId: string;
    type: 'trip' | 'line-template';
    orderNumber?: number;
  }
>(
  'getAssignedComposition',
  async ({ compositionId, type, orderNumber }, { getState, dispatch }) => {
    const state = getState();
    const organizationId = currentBusinessEntityIdSelector(state);

    const composition = (
      await api.get<AssignedComposition>(
        `/organizations/${organizationId}/${type}-vehicle-compositions/${compositionId}`
      )
    ).data;

    const {
      vehicles,
      tripVehicleCompositionId,
      lineTemplateVehicleCompositionId,
      vehicleCompositionDirection,
      vehicleCompositionCode,
      vehicleCompositionId,
      vehicleCompositionName,
      vehicleCompositionTransportationType,
      ...restComposition
    } = composition;
    let compositionTotalCapacity = {};
    let processHistory = {};

    if (type === 'trip') {
      processHistory = (
        await api.get<{ processes: TripCompositionProcess[] }>(
          `/organizations/${organizationId}/${type}-vehicle-compositions/${compositionId}/process-history`
        )
      ).data;
      const { totalCapacity } = composition;
      const { totalPlaces, ...inventoryClasses } =
        totalCapacity.inventoryClasses.reduce(
          (acc, { inventoryClass, count }) => ({
            ...acc,
            [inventoryClass.name]: count,
            totalPlaces: acc.totalPlaces + count,
          }),
          { totalPlaces: 0 }
        );
      const { totalManageableSpaces, ...manageableSpaces } =
        totalCapacity.manageableSpaces.reduce(
          (acc, { manageableSpace, count }) => ({
            ...acc,
            [manageableSpace.name]: count,
            totalManageableSpaces: acc.totalManageableSpaces + count,
          }),
          { totalManageableSpaces: 0 }
        );

      compositionTotalCapacity = {
        totalCapacity: {
          totalPlaces,
          totalManageableSpaces,
          inventoryClasses,
          manageableSpaces,
        },
      };
    }
    const selectedVehicle =
      vehicles.find((vehicle) => vehicle.orderNumber === orderNumber) ||
      _first(vehicles);

    dispatch(setCurrentVehicle(selectedVehicle));

    return {
      id:
        type === 'trip'
          ? tripVehicleCompositionId!
          : lineTemplateVehicleCompositionId!,
      vehicleCompositionId,
      name: vehicleCompositionName,
      code: vehicleCompositionCode,
      vehicleCompositionDirection,
      vehicle: selectedVehicle
        ? {
            id: selectedVehicle.id,
            vehicleId: selectedVehicle.id,
            orderNumber: selectedVehicle.orderNumber,
            isVehicleFlipped: selectedVehicle.isVehicleFlipped,
            isBlocked: selectedVehicle.isBlocked,
            number: selectedVehicle.number,
            salesOpeningPriority: selectedVehicle.salesOpeningPriority,
            salesOpeningThresholdPercentage:
              selectedVehicle.salesOpeningThresholdPercentage,
            blocks: selectedVehicle.blocks,
          }
        : undefined,
      compositionVehicles: vehicles.map(
        ({
          id,
          orderNumber,
          isVehicleFlipped,
          floors,
          isBlocked,
          blocks,
          number,
          salesOpeningPriority,
          salesOpeningThresholdPercentage,
        }) => ({
          id: id,
          vehicleId: id,
          orderNumber,
          isVehicleFlipped,
          floors,
          isBlocked,
          blocks,
          number,
          salesOpeningPriority,
          salesOpeningThresholdPercentage,
        })
      ),
      compositionVehiclesData: vehicles,
      transportationTypeId: vehicleCompositionTransportationType.id,
      transportationTypeName: vehicleCompositionTransportationType.name,
      ...restComposition,
      ...processHistory,
      ...compositionTotalCapacity,
    } as unknown as CompositionConstructDto;
  }
);

export const setCurrentCompositionVehicle = createAction<
  CompositionConstructVehicleDto | undefined
>('setCurrentCompositionVehicle');

export const setCompositionsTransportType = createAction<TransportationTypeId>(
  'setCompositionsTransportType'
);

export const duplicateComposition = createAsyncThunk(
  'duplicateComposition',
  async (id: number, { dispatch, getState }) => {
    const composition = (
      await api.post(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/vehicle-compositions/${id}/duplicate`
      )
    ).data;

    dispatch(getCompositions());

    return composition.id;
  }
);

export const deleteComposition = createAsyncThunk(
  'deleteComposition',
  async (id: number, { dispatch, getState }) => {
    await api.delete(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/vehicle-compositions/${id}`
    );

    dispatch(getCompositions());
  }
);

export const updateComposition = createAsyncThunk<
  unknown,
  {
    compositionId: number | string;
    name: string;
    code?: string;
    number?: string;
  }
>(
  'updateComposition',
  async ({ compositionId, code, ...data }, { getState, dispatch }) => {
    const compositionConstruct = compositionConstructSelector(getState());

    await api.put(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/vehicle-compositions/${compositionId}`,
      {
        ...data,
        code: code ?? compositionConstruct?.code,
      }
    );

    await dispatch(getComposition(`${compositionId}`));
  }
);

const addVehicleToComposition = createAsyncThunk<
  Pick<CompositionConstructVehicleDto, 'id' | 'orderNumber'>,
  {
    compositionId: string;
    vehicleId: string;
  }
>(
  'addVehicleToComposition',
  async ({ compositionId, vehicleId }, { getState }) =>
    (
      await api.post(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/vehicle-compositions/${compositionId}/vehicles`,
        { id: vehicleId }
      )
    ).data
);

export const pushVehicleToComposition = createAsyncThunk<
  unknown,
  {
    compositionId: string;
    vehicleId: string;
  }
>('pushVehicleToComposition', async (data, { dispatch }) => {
  await dispatch(addVehicleToComposition(data));
  const [{ compositionVehicles }] = await Promise.all([
    dispatch(getComposition(`${data.compositionId}`)).unwrap(),
    dispatch(readVehicle(Number(data.vehicleId))),
  ]);

  const vehicle = _last(compositionVehicles)!;
  dispatch(setCurrentCompositionVehicle(vehicle));
});

export const duplicateVehicleComposition = createAsyncThunk<
  unknown,
  {
    compositionId: string;
    compositionVehicleId: string;
    orderNumber: number;
    isVehicleFlipped: boolean;
    number: string;
  }
>(
  'duplicateVehicleComposition',
  async (
    { compositionId, compositionVehicleId, ...data },
    { dispatch, getState }
  ) => {
    (
      await api.post(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/vehicle-compositions/${compositionId}/vehicles/${compositionVehicleId}/copy`,
        data
      )
    ).data;
    const { compositionVehicles } = await dispatch(
      getComposition(`${compositionId}`)
    ).unwrap();
    const vehicle = _find(
      compositionVehicles,
      (vehicle) => `${vehicle.id}` === compositionVehicleId
    )!;
    dispatch(setCurrentCompositionVehicle(vehicle));
  }
);

export const updateCompositionVehicle = createAsyncThunk<
  unknown,
  {
    compositionId: string;
    compositionVehicleId: string;
    orderNumber?: number;
    isVehicleFlipped?: boolean;
    isBlocked?: boolean;
    number: string;
    salesOpeningPriority?: number;
    salesOpeningThresholdPercentage?: number;
    blockingReasonId?: string;
    blockingOriginStopId?: number;
    blockingDestinationStopId?: number;
  }
>(
  'updateCompositionVehicle',
  async (
    { compositionId, compositionVehicleId, ...data },
    { getState, dispatch }
  ) => {
    await api.put(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/vehicle-compositions/${compositionId}/vehicles/${compositionVehicleId}`,
      data
    );

    const { compositionVehicles } = await dispatch(
      getComposition(`${compositionId}`)
    ).unwrap();
    const vehicle = _find(
      compositionVehicles,
      (vehicle) => `${vehicle.id}` === compositionVehicleId
    )!;
    dispatch(setCurrentCompositionVehicle(vehicle));
  }
);

export const deleteCompositionVehicle = createAsyncThunk<
  unknown,
  { compositionId: string; compositionVehicleId: string }
>(
  'deleteCompositionVehicle',
  async ({ compositionId, compositionVehicleId }, { getState, dispatch }) => {
    await api.delete(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/vehicle-compositions/${compositionId}/vehicles/${compositionVehicleId}`
    );

    const { compositionVehicles } = await dispatch(
      getComposition(`${compositionId}`)
    ).unwrap();

    const vehicle = _first(compositionVehicles)!;
    if (vehicle) {
      dispatch(setCurrentCompositionVehicle(vehicle));
      dispatch(readVehicle(Number(vehicle.vehicleId)));
    } else {
      dispatch(setCurrentVehicle());
    }
  }
);
