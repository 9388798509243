import type { ElementType } from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, type PaperProps, type PaperTypeMap } from '@mui/material';
import classNames from 'classnames';
import { Icon } from '@fleet/shared/mui';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 12,
      background: '#F6F6F6',
      color: '#757B86',
      border: '1px solid #E1E1E1',
      textDecoration: 'none',
      fontSize: 8,
      '&$error': {
        borderColor: theme.palette.error.main,
      },
      '&:before': {
        content: '""',
        width: 1,
        marginLeft: -1,
        paddingTop: '100%',
      },
      '& > img': {
        margin: 'auto',
        maxWidth: '100%',
      },
    },
    error: {},
    selected: {},
    empty: {},
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
  {
    name: 'ElementIcon',
  }
);

export type ElementIconProps<
  D extends ElementType,
  P = {
    icon: string;
    name?: string;
    width?: number;
    height?: number;
    error?: boolean;
  }
> = PaperProps<D, P>;

export const ElementIcon = <
  D extends ElementType = PaperTypeMap['defaultComponent']
>({
  icon,
  name,
  width,
  height,
  error,
  className,
  ...props
}: ElementIconProps<D>) => {
  const classes = useStyles();

  return (
    <Paper
      className={classNames(classes.root, className, {
        [classes.empty]: !icon,
        [classes.error]: error,
      })}
      elevation={0}
      square
      {...props}
    >
      {icon ? (
        <img src={icon} alt={name} width={width} height={height} />
      ) : (
        <div className={classes.content}>
          <Icon name="image" />
          <span>{name}</span>
        </div>
      )}
    </Paper>
  );
};
