import { type FC, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import {
  AddButton,
  FormProvider,
  formSubmit,
  PeriodField,
  RadioGroupField,
  SearchForm,
  SelectField,
  useForm,
} from '@fleet/shared';
import { Button, FormControl } from '@fleet/shared/mui';
import { Grid, Stack } from '@mui/material';
import { TransLabel } from 'i18n/trans/label';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';
import { SiServiceAllocationRulesetQueryParams } from 'dto/siServiceAllocationRuleset';
import { useDispatch, useSelector } from 'store/utils';
import {
  getSiServiceAllocationRulesets,
  setServiceAllocationRulesetsFilter,
} from 'features/siServiceAllocationRuleset/siServiceAllocationRulesetActions';
import { selectSiServiceAllocationRulesetsFilter } from 'features/siServiceAllocationRuleset/siServiceAllocationRulesetSelectors';
import { siAllocationRulesetOptionsSelector } from 'features/siAllocationRuleset/siAllocationRulesetSelectors';
import { useHistory } from 'react-router-dom';
import { serviceCodeOptionsSelector } from 'features/classification/classificationSelectors';
import { pick } from 'lodash';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'SiServiceAllocationRulesetSearchForm',
  }
);

interface SiServiceAllocationRulesetSearchFormProps {}

export const SiServiceAllocationRulesetSearchForm: FC<
  SiServiceAllocationRulesetSearchFormProps
> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const filter = useSelector(selectSiServiceAllocationRulesetsFilter);

  const onSubmit = useCallback(
    (values: SiServiceAllocationRulesetQueryParams) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getSiServiceAllocationRulesets(values));
      }),
    [dispatch]
  );
  const { form, handleSubmit } = useForm<SiServiceAllocationRulesetQueryParams>(
    {
      onSubmit,
      initialValues: filter,
      subscription: { dirty: true },
    }
  );
  const handleReset = useCallback(() => {
    form.reset();
    dispatch(
      setServiceAllocationRulesetsFilter(pick(filter, ['limit', 'offset']))
    );
  }, [form, dispatch, filter]);

  const siAllocationRulesetOptions = useSelector(
    siAllocationRulesetOptionsSelector
  );
  const serviceCodeOptions = useSelector(serviceCodeOptionsSelector);

  const classes = useStyles();
  return (
    <SearchForm
      className={classes.root}
      title={<TransTitle i18nKey="serviceSiAllocationRuleset" />}
      action={
        <AddButton
          onClick={() => history.push(`/service-allocation-rulesets/create`)}
        >
          <TransButton i18nKey="add" />
        </AddButton>
      }
    >
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={4} spacing={2}>
            <Grid item xs={1}>
              <SelectField
                name="sleeperInventoryAllocationRulesetId"
                label={
                  <TransLabel i18nKey="sleeperInventoryAllocationRuleset" />
                }
                options={siAllocationRulesetOptions}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="serviceCodes"
                label={<TransLabel i18nKey="serviceCodes" />}
                options={serviceCodeOptions}
                multiple
              />
            </Grid>
            <PeriodField
              from={{
                name: 'validityFrom.from',
                label: <TransLabel i18nKey="validFrom" />,
                isClearable: true,
                popperProps: { strategy: 'fixed' },
              }}
              to={{
                name: 'validityFrom.to',
                label: null,
                isClearable: true,
                popperProps: { strategy: 'fixed' },
              }}
            />
            <PeriodField
              from={{
                name: 'validityTo.from',
                label: <TransLabel i18nKey="validTo" />,
                isClearable: true,
                popperProps: { strategy: 'fixed' },
              }}
              to={{
                name: 'validityTo.to',
                label: null,
                isClearable: true,
                popperProps: { strategy: 'fixed' },
              }}
            />

            <Grid item xs={1}>
              <RadioGroupField
                name="isException"
                label={<TransLabel i18nKey="isException" />}
                options="BOOL"
                inline
              />
            </Grid>

            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
