import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransModal = createTrans({
  allocationScoreTemplateDeletionDescription: (
    <Trans
      i18nKey="modal.title.allocationScoreTemplateDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected allocation score template? You can't undo this];(2-inf)[Are you sure you want to delete selected allocation score templates? You can't undo this]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  allocationScoreTemplateDeletionTitle: (
    <Trans
      i18nKey="modal.title.allocationScoreTemplateDeletionTitle"
      defaults="Delete allocation score template"
    />
  ),
  carriageBlockingPropertyDeletionDescription: (
    <Trans
      i18nKey="modal.title.carriageBlockingPropertyDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected carriage blocking property? You can't undo this];(2-inf)[Are you sure you want to delete selected carriage blocking properties? You can't undo this]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  carriageBlockingPropertyDeletionTitle: (
    <Trans
      i18nKey="modal.title.carriageBlockingPropertyDeletionTitle"
      defaults="Delete carriage blocking property"
    />
  ),
  localizationDeletionDescription: (
    <Trans
      i18nKey="modal.title.localizationDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected localization? You can't undo this];(2-inf)[Are you sure you want to delete selected localizations? You can't undo this]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  localizationDeletionTitle: (
    <Trans
      i18nKey="modal.title.localizationDeletionTitle"
      defaults="Delete localization"
    />
  ),
  serviceSiAllocationRulesetDeletionDescription: (
    <Trans
      i18nKey="modal.title.serviceSiAllocationRulesetDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected service sleeper inventory allocation ruleset? You can't undo this];(2-inf)[Are you sure you want to delete selected service sleeper inventory allocation rulesets? You can't undo this]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  serviceSiAllocationRulesetDeletionTitle: (
    <Trans
      i18nKey="modal.title.serviceSiAllocationRulesetDeletionTitle"
      defaults="Delete service sleeper inventory allocation ruleset"
    />
  ),
  siAllocationRulesetDeletionDescription: (
    <Trans
      i18nKey="modal.title.siAllocationRulesetDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected sleeper inventory allocation ruleset? You can't undo this];(2-inf)[Are you sure you want to delete selected sleeper inventory allocation rulesets? You can't undo this]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  siAllocationRulesetDeletionTitle: (
    <Trans
      i18nKey="modal.title.siAllocationRulesetDeletionTitle"
      defaults="Delete sleeper inventory allocation ruleset"
    />
  ),
  siAllocationRulesetRuleDeletionDescription: (
    <Trans
      i18nKey="modal.title.siAllocationRulesetRuleDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected sleeper inventory allocation ruleset rule? You can't undo this];(2-inf)[Are you sure you want to delete selected sleeper inventory allocation ruleset rules? You can't undo this]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  siAllocationRulesetRuleDeletionTitle: (
    <Trans
      i18nKey="modal.title.siAllocationRulesetRuleDeletionTitle"
      defaults="Delete sleeper inventory allocation ruleset rule?"
    />
  ),
  snugDeletionDescription: (
    <Trans
      i18nKey="modal.title.snugDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected snug? You can't undo this];(2-inf)[Are you sure you want to delete selected snugs? You can't undo this]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  snugDeletionTitle: (
    <Trans i18nKey="modal.title.snugDeletionTitle" defaults="Delete snug" />
  ),
});
