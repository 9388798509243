import { type ReactNode, useMemo, useCallback } from 'react';
import type { ManageDto } from 'dto/manage';
import type {
  GetSubRow,
  TableColumn,
  TableColumns,
  TableProps,
} from '@fleet/shared/components/Table';
import type { CellProps, Row, TableOptions } from 'react-table';
import {
  type PluginHook,
  useExpanded,
  usePagination,
  useRowSelect,
} from 'react-table';
import {
  Table,
  useIndeterminateRowSelectCheckbox,
  useRowExpandToggle,
  useTable,
} from '@fleet/shared';
import { TransLabel } from 'i18n/trans/label';

export interface ManageTableBaseProps<D extends ManageDto>
  extends Pick<
      TableOptions<D>,
      'data' | 'limit' | 'total' | 'offset' | 'onPageChange'
    >,
    Omit<TableProps<D>, 'table'> {
  vehicleConstructId?: number;
  controlsAccessor?: (rows: Array<D>) => ReactNode;
  renderSubRow?: (row: Row<D>, selectedRows: Array<D>) => ReactNode;
  plugins?: boolean;
}

export interface ManageTableProps<D extends ManageDto>
  extends ManageTableBaseProps<D>,
    Pick<TableOptions<D>, 'columns'> {}

export function ManageTable<D extends ManageDto>(props: ManageTableProps<D>) {
  const {
    data,
    limit,
    total,
    offset,
    onPageChange,
    renderSubRow,
    columns: propsColumns,
    vehicleConstructId,
    controlsAccessor,
    plugins = true,
    ...tableProps
  } = props;

  const columns = useMemo<TableColumns<D>>(() => {
    const columns: Array<TableColumn<D>> = [...propsColumns];

    if (!vehicleConstructId)
      columns.push(
        {
          id: 'connectedVehicles',
          Header: <TransLabel i18nKey="vehicleCompositionsConnected" />,
          accessor: ({ vehicleCompositions }) =>
            vehicleCompositions
              .map(({ name, code }) => [name, code].filter(Boolean).join('/'))
              .join(', '),
        },
        {
          accessor: 'id',
          Header: ({ selectedFlatRows }) =>
            selectedFlatRows.length ? (
              controlsAccessor?.(
                selectedFlatRows.map(({ original }) => original)
              ) ?? null
            ) : (
              <span />
            ),
          Cell: ({ selectedFlatRows, row }: CellProps<D>) =>
            selectedFlatRows?.length
              ? null
              : controlsAccessor?.([row.original]) ?? null,
          width: 176,
          disableSortBy: true,
        }
      );

    if (vehicleConstructId && controlsAccessor)
      columns.push({
        id: 'controls',
        accessor: (row) => controlsAccessor([row]),
        width: 184,
        disableSortBy: true,
      });

    return columns;
  }, [controlsAccessor, propsColumns, vehicleConstructId]);
  const table = useTable<D>(
    {
      data,
      columns,
      limit,
      total,
      offset,
      onPageChange,
      autoResetExpanded: false,
      ...(!total && {
        initialState: { pageSize: -1 },
        useControlledState: (state) => ({
          ...state,
          expanded: { '0': true } as Record<string, boolean>,
        }),
      }),
    },
    ...([
      useExpanded,
      total && useRowExpandToggle,
      total && usePagination,
      total && plugins && useIndeterminateRowSelectCheckbox,
      useRowSelect,
    ].filter(Boolean) as PluginHook<D>[])
  );

  const selectedRows = useMemo(
    () => table.selectedFlatRows.map(({ original }) => original),
    [table.selectedFlatRows]
  );

  const getSubRow = useCallback<GetSubRow<D>>(
    (row) => renderSubRow?.(row, selectedRows),
    [renderSubRow, selectedRows]
  );

  return <Table<D> table={table} getSubRow={getSubRow} {...tableProps} />;
}
