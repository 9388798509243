import { useMemo } from 'react';
import { ClassificationGroup } from 'dto/classification';
import { useSelector } from 'store/utils';
import { makeClassificationOptions } from '@fleet/shared';
import { classifiersSelector } from 'features/classification/classificationSelectors';

export const useClassificationOptions = <T extends ClassificationGroup>(
  groupName: T
) => {
  const classifications = useSelector(classifiersSelector)[groupName];
  return useMemo(
    () =>
      makeClassificationOptions<typeof classifications[number]>(
        classifications ?? []
      ),
    [classifications]
  );
};

export const useClassificationMap = <T extends ClassificationGroup>(
  groupName: T
) => {
  const classifications = useClassificationOptions(groupName);
  return useMemo(
    () => new Map(classifications.map(({ value, label }) => [value, label])),
    [classifications]
  );
};
