import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  CheckboxField,
  FormControl,
  FormProvider,
  formSubmit,
  PeriodField,
  RadioGroupField,
  SearchForm,
  SelectField,
  ToggleButtonGroupField,
  useForm,
} from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'store/utils';
import { assignEntityFilterOptionsSelector } from 'features/classification/classificationSelectors';
import { TransLabel } from 'i18n/trans/label';
import { useCallback, useEffect, useMemo } from 'react';
import { Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';

import { LineTemplateFilterDto } from 'dto/lineTemplate';
import { formatDate } from '@fleet/shared/utils/date';
import { searchLineTemplates } from 'features/lineTemplate/lineTemplateActions';
import { VehicleCompositionRelation } from 'dto/composition';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'LineTemplateSearchForm',
  }
);

export interface LineTemplateSearchFormProps {
  vehicleCompositionRelation: VehicleCompositionRelation;
  refreshSearch?: boolean;
}

export const LineTemplateSearchForm: FC<LineTemplateSearchFormProps> = ({
  vehicleCompositionRelation,
  refreshSearch,
}) => {
  const lineTemplateDateDefaultFormatted = useMemo(
    () => formatDate(new Date(), 'yyyy-MM-dd'),
    []
  );
  const initialValues = useMemo(
    () => ({
      hasIntervalDepartures: false,
      vehicleCompositionRelation,
      lineTemplateStartDateTo: lineTemplateDateDefaultFormatted,
      lineTemplateEndDateFrom: lineTemplateDateDefaultFormatted,
    }),
    [lineTemplateDateDefaultFormatted, vehicleCompositionRelation]
  );
  const dispatch = useDispatch();
  useEffect(() => {
    refreshSearch && dispatch(searchLineTemplates());
  }, [dispatch, refreshSearch]);
  const onSubmit = useCallback(
    ({
      vehicleCompositionRelation,
      ...values
    }: Partial<LineTemplateFilterDto>) =>
      formSubmit(() =>
        dispatch(
          searchLineTemplates({
            // TODO, probably change it to boolean?
            vehicleCompositionRelation: vehicleCompositionRelation
              ? 'has_compositions'
              : 'all',
            ...values,
          })
        ).unwrap()
      ),
    [dispatch]
  );
  const { form, handleSubmit } = useForm({
    initialValues,
    onSubmit,
  });
  const { lines, serviceCodes, vehicleCompositions, subContractors, days } =
    useSelector(assignEntityFilterOptionsSelector);
  const intervalDeparturesOptions = useMemo(
    () => [
      { label: <TransLabel i18nKey="departureDays" />, value: false },
      { label: <TransLabel i18nKey="departureIntervals" />, value: true },
    ],
    []
  );

  const classes = useStyles();

  return (
    <SearchForm
      className={classes.root}
      title={<TransTitle i18nKey="lineTemplates" />}
    >
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={4} spacing={2}>
            <Grid item xs={1}>
              <SelectField
                name="lineId"
                label={<TransLabel i18nKey="lineNumber" />}
                options={lines}
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="serviceCodes"
                label={<TransLabel i18nKey="serviceCode" />}
                options={serviceCodes}
                showEmptyOption
                multiple
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="subContractorIds"
                label={<TransLabel i18nKey="subContractor" />}
                options={subContractors}
                showEmptyOption
                multiple
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="vehicleCompositionId"
                label={<TransLabel i18nKey="connectedVehicles" />}
                options={vehicleCompositions}
                showEmptyOption
              />
            </Grid>
            <PeriodField
              from={{
                name: 'lineTemplateStartDateFrom',
                label: <TransLabel i18nKey="templateStartDateFrom" />,
                isClearable: true,
              }}
              to={{
                name: 'lineTemplateStartDateTo',
                label: <TransLabel i18nKey="templateStartDateTo" />,
                isClearable: true,
              }}
            />
            <PeriodField
              from={{
                name: 'lineTemplateEndDateFrom',
                label: <TransLabel i18nKey="templateEndDateFrom" />,
                isClearable: true,
              }}
              to={{
                name: 'lineTemplateEndDateTo',
                label: <TransLabel i18nKey="templateEndDateTo" />,
                isClearable: true,
              }}
            />
            <Grid item xs="auto">
              <ToggleButtonGroupField
                name="departureDays"
                label={<TransLabel i18nKey="departureDays" />}
                options={days}
                multiple
              />
            </Grid>
            <Grid item xs="auto">
              <RadioGroupField
                name="hasIntervalDepartures"
                label={<TransLabel i18nKey="departureDays" />}
                options={intervalDeparturesOptions}
                inline
              />
            </Grid>
            {vehicleCompositionRelation !== 'all' && (
              <Grid item xs="auto">
                <FormControl label="&nbsp;">
                  <CheckboxField
                    name="vehicleCompositionRelation"
                    label={<TransLabel i18nKey="hasCompositions" />}
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
