import { createAction } from '@reduxjs/toolkit';

export const setLoadingKey = createAction<string | null>('setLoadingKey');

export const setIsDraggingFromPalette = createAction<boolean>(
  'setIsDraggingFromPalette'
);

export const setCurrentBusinessEntity = createAction<number>(
  'setCurrentOrganisation'
);
