import { StrictMode, Suspense } from 'react';
import { render } from 'react-dom';
import { Loader, theme, ThemeProvider } from '@fleet/shared/mui';
import App from 'App';
import { Provider } from 'react-redux';
import { Provider as AlertProvider } from 'react-alert';
import { store } from 'store';
import 'i18n';
import 'styles/index.scss';
import { initializeSentry } from 'helpers/sentryConfig';
import AxiosErrorsInterceptorWrapper from 'wrappers/AxiosErrorsInterceptorWrapper';
import { alertProviderProps } from '@fleet/shared/components/Alert';
import { AuthProvider, baseOidcConfig } from '@fleet/shared';

initializeSentry();

// fix for google translate issue
// https://github.com/facebook/react/issues/11538
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  // @ts-ignore
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot remove a child from a different parent',
          child,
          this
        );
      }
      return child;
    }
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  // @ts-ignore
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this
        );
      }
      return newNode;
    }

    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    return originalInsertBefore.apply(this, arguments);
  };
}

render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loader active size="fullscreen" />}>
        <Provider store={store}>
          <AuthProvider {...baseOidcConfig}>
            <AlertProvider {...alertProviderProps}>
              <AxiosErrorsInterceptorWrapper>
                <App />
              </AxiosErrorsInterceptorWrapper>
            </AlertProvider>
          </AuthProvider>
        </Provider>
      </Suspense>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);
