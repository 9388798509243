import type { FC, ReactNode } from 'react';
import type { LoadableProps, Theme } from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import { Layout, type LayoutProps, Loadable } from '@fleet/shared';
import { Divider } from '@mui/material';

const useStyles = makeStyles<Theme, Pick<LayoutProps, 'height'>>(
  () => ({
    root: ({ height }) => ({
      ...(height === 'auto' && {
        flex: 1,
        minHeight: 'unset',
      }),
    }),
  }),
  {
    name: 'ManageSearch',
  }
);

export interface ManageSearchProps
  extends Pick<LoadableProps, 'loading'>,
    Pick<LayoutProps, 'height'> {
  form: ReactNode;
}

export const ManageSearch: FC<ManageSearchProps> = ({
  loading,
  height,
  form,
  children,
}) => {
  const classes = useStyles({ height });
  return (
    <Loadable loading={loading}>
      <Layout className={classes.root}>
        <>
          {form}
          <Divider />
          {children}
        </>
      </Layout>
    </Loadable>
  );
};
