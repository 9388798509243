import { createAsyncThunk } from 'store/utils';
import { CarriageBlockingValues } from 'dto/vehicle';
import { api } from '@fleet/shared';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import qs from 'qs';

type Entity = 'line-template' | 'trip';

export const blockCompositionVehicle = createAsyncThunk<
  unknown,
  {
    entity: Entity;
    id?: string;
    compositionId: string;
    vehicleId: string;
  } & CarriageBlockingValues
>(
  'blockCompositionVehicle',
  async (
    { id, entity, compositionId, vehicleId, ...payload },
    { getState, rejectWithValue }
  ) => {
    try {
      await (id ? api.put : api.post)<{ id: number }>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/${entity}-vehicle-compositions/${compositionId}/${
          entity === 'trip' ? 'vehicle-composition-' : ''
        }vehicles/${vehicleId}/block${id ? `/${id}` : ''}`,
        payload
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deleteCompositionVehicleBlocks = createAsyncThunk<
  unknown,
  {
    entity: Entity;
    compositionId: string;
    vehicleId: string;
    ids: Array<string>;
  }
>(
  'deleteCompositionVehicleBlocks',
  async ({ entity, compositionId, vehicleId, ids }, { getState }) => {
    await api.delete(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/${entity}-vehicle-compositions/${compositionId}/${
        entity === 'trip' ? 'vehicle-composition-' : ''
      }vehicles/${vehicleId}/block/bulk-delete${qs.stringify(
        { ids },
        {
          addQueryPrefix: true,
          arrayFormat: 'comma',
        }
      )}`
    );
  }
);
