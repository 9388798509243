import { useCallback, useMemo } from 'react';
import { Select, SelectProps } from '@fleet/shared/mui';
import { useSelector } from 'store/utils';
import { inventoryClassesSelector } from 'features/classification/classificationSelectors';
import { InventoryType } from 'dto/organization';
import { Classifier } from '@fleet/shared/dto/classifier';

interface InventoryClassSelectProps<T extends string>
  extends Omit<SelectProps<T>, 'options' | 'onChange'> {
  inventoryType?: InventoryType;
  onChange?: (value: T, properties: Array<Classifier>) => void;
}

export function InventoryClassSelect({
  inventoryType,
  onChange,
  ...props
}: InventoryClassSelectProps<string>) {
  const inventoryClasses = useSelector(inventoryClassesSelector);
  const inventoryClassPropertiesById = useMemo(
    () =>
      new Map(inventoryClasses.map(({ id, properties }) => [id, properties])),
    [inventoryClasses]
  );

  const options = useMemo(
    () =>
      (inventoryType
        ? inventoryClasses.filter(
            (inventoryClass) =>
              inventoryClass.inventoryType.id === inventoryType &&
              inventoryClass.isActive
          )
        : inventoryClasses
      ).map(({ name, id }) => ({ label: name, value: id })),
    [inventoryClasses, inventoryType]
  );

  const handleChange = useCallback<Required<SelectProps<string>>['onChange']>(
    (value) => {
      onChange?.(value, inventoryClassPropertiesById.get(value) ?? []);
    },
    [inventoryClassPropertiesById, onChange]
  );

  return (
    <Select<string> options={options} onChange={handleChange} {...props} />
  );
}
