import type { FC } from 'react';
import { useCallback, useContext } from 'react';
import { Input, Select, Tooltip } from '@fleet/shared/mui';
import { useDispatch, useSelector } from 'store/utils';
import { createFloor } from 'features/floor/floorActions';
import { FloorProperties } from 'routes/designer/FloorProperties';
import { dataLoadingSelector } from 'features/common/commonSelectors';
import { inventoryClassesOptionsSelector } from 'features/classification/classificationSelectors';
import { DesignerPropertiesPanelProps } from 'dto/propertiesPanel';
import { ViewerContext } from '@fleet/widget/components/viewer/Context';
import { TransTitle } from 'i18n/trans/title';
import { TransLabel } from 'i18n/trans/label';
import { Divider, Typography } from '@mui/material';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { VehicleSnugs } from 'routes/designer/VehicleSnugs';

type VehiclePropertiesProps = {
  updateVehicleData: DesignerPropertiesPanelProps['updateVehicleData'];
};

export const VehicleProperties: FC<VehiclePropertiesProps> = ({
  updateVehicleData,
}) => {
  const { vehicle, floors } = useContext(ViewerContext);
  const dispatch = useDispatch();
  const designerLoading = useSelector(dataLoadingSelector);
  const inventoryClassOptions = useSelector(inventoryClassesOptionsSelector);
  const vehicleTypeOptions = useClassificationOptions(
    ClassificationGroup.VEHICLE_TYPE
  );
  const addFloor = useCallback(() => dispatch(createFloor()), [dispatch]);

  return (
    <div className="designer-vehicle-properties">
      <Typography variant="subtitle">
        <TransTitle i18nKey="vehicleProperties" />
      </Typography>
      <Input
        label={<TransLabel i18nKey="name" />}
        value={vehicle?.name}
        onBlur={({ target: { value: name } }) => updateVehicleData({ name })}
        multiline
        required={!designerLoading}
        margin="dense"
      />
      <Input
        label={<TransLabel i18nKey="code" />}
        value={vehicle?.code || ''}
        onBlur={({ target: { value: code } }) => updateVehicleData({ code })}
        required={!designerLoading}
        margin="dense"
      />
      <Select
        disabled
        label={<TransLabel i18nKey="type" />}
        value={vehicle?.type?.id ?? null}
        options={vehicleTypeOptions}
        onChange={(value) => {
          updateVehicleData({ type: { id: value as string } });
        }}
        margin="dense"
      />
      <Input
        className="standing-places-input"
        label={<TransLabel i18nKey="numberOfStandingPlaces" />}
        value={vehicle?.maxAllowedStandingPassengerCount || 0}
        type="number"
        onBlur={({ target: { value: num } }) =>
          updateVehicleData({ maxAllowedStandingPassengerCount: +num })
        }
        margin="dense"
      />
      <Select
        label={<TransLabel i18nKey="standingInventoryClass" />}
        value={vehicle?.standingInventoryClassId ?? null}
        options={inventoryClassOptions}
        onChange={(value) => {
          updateVehicleData({
            standingInventoryClassId: value as string,
          });
        }}
        margin="dense"
        showEmptyOption
      />

      <Divider sx={{ my: 2 }} />

      <VehicleSnugs />

      <Divider sx={{ my: 2 }} />

      <div className="header-flex">
        <Typography variant="subtitle">
          <TransLabel i18nKey="floors" />
        </Typography>
        <div className="floors-controls">
          <span>{floors.length}</span>
          {floors.length < 3 && (
            <Tooltip content={<TransLabel i18nKey="addFloor" />}>
              <span className="circle-btn" onClick={addFloor}>
                +
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      {floors.map((floor, idx) => (
        <FloorProperties key={idx} {...floor} floorCount={floors.length} />
      ))}
    </div>
  );
};
