import { createAsyncThunk } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import { api } from '@fleet/shared';
import { AllocationScoreTemplate } from 'dto/allocationScoreTemplate';

export const clearAllocationScoreTemplates = createAction(
  'allocationScoreTemplate/clearAllocationScoreTemplates'
);

export const setAllocationScoreTemplate = createAction<
  AllocationScoreTemplate | undefined
>('allocationScoreTemplate/setAllocationScoreTemplate');

export const getAllocationScoreTemplates = createAsyncThunk<
  Array<AllocationScoreTemplate>
>(
  'allocationScoreTemplate/getAllocationScoreTemplates',
  async (_, { getState }) =>
    (
      await api.get<{ items: Array<AllocationScoreTemplate> }>(
        `/allocation-score-templates${qs.stringify(
          { ownerId: currentBusinessEntityIdSelector(getState()) },
          {
            addQueryPrefix: true,
          }
        )}`
      )
    ).data.items
);

export const getAllocationScoreTemplate = createAsyncThunk<
  AllocationScoreTemplate,
  string
>(
  'allocationScoreTemplate/getAllocationScoreTemplate',
  async (id) => (await api.get(`/allocation-score-templates/${id}`)).data
);

export const createOrUpdateAllocationScoreTemplate = createAsyncThunk<
  AllocationScoreTemplate,
  Partial<AllocationScoreTemplate>
>(
  'allocationScoreTemplate/createOrUpdateAllocationScoreTemplate',
  async ({ id, ...payload }) =>
    (
      await (id ? api.put : api.post)(
        `/allocation-score-templates${id ? `/${id}` : ''}`,
        payload
      )
    ).data
);

export const deleteAllocationScoreTemplate = createAsyncThunk<void, undefined>(
  'allocationScoreTemplate/deleteAllocationScoreTemplate',
  async (_, { dispatch, getState }) => {
    const {
      allocationScoreTemplate: { current },
    } = getState();

    await api.delete(
      `/allocation-score-templates/bulk-delete${qs.stringify(
        { ids: [current?.id] },
        {
          addQueryPrefix: true,
          arrayFormat: 'comma',
        }
      )}`
    );
    await dispatch(getAllocationScoreTemplates());
  }
);
