import { Classifier } from '@fleet/shared/dto/classifier';

export enum InventoryType {
  COMPARTMENT = 'INVENTORY_TYPE.COMPARTMENT',
  CARGO = 'INVENTORY_TYPE.CARGO',
  SEAT = 'INVENTORY_TYPE.SEAT',
  BED = 'INVENTORY_TYPE.BED',
}

export enum InventoryBlockingLevel {
  CARRIAGE = 'INVENTORY_BLOCKING_LEVEL.CARRIAGE',
  COMPARTMENT = 'INVENTORY_BLOCKING_LEVEL.COMPARTMENT',
  PLACE = 'INVENTORY_BLOCKING_LEVEL.PLACE',
}

export interface InventoryBlockingReason extends Classifier {
  blockingType: Classifier<InventoryBlockingLevel>;
}
