import { createSelector } from '@reduxjs/toolkit';
import { floorElementsSelector } from 'features/classification/classificationSelectors';
import { prepareActiveFloor } from '@fleet/widget/utils/prepareActiveFloor';
import { selector } from 'store/utils';

export const floorListSelector = selector((state) => state.floor.list);

export const selectActiveFloor = selector(
  (state) => floorListSelector(state)[state.floor.activeFloorIdx]
);
export const activeFloorSelector = createSelector(
  [selectActiveFloor, floorElementsSelector],
  prepareActiveFloor
);
