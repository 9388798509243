import { createSlice } from '@reduxjs/toolkit';
import type { Pagination } from '@fleet/shared/dto/pagination';
import type { LineTemplateDto, LineTemplateFilterDto } from 'dto/lineTemplate';
import {
  setLineTemplate,
  setLineTemplateFilter,
  setLineTemplates,
} from 'features/lineTemplate/lineTemplateActions';
import _findIndex from 'lodash/findIndex';

export interface LineTemplateState {
  filter: Partial<LineTemplateFilterDto>;
  list?: Pagination<LineTemplateDto>;
  current?: LineTemplateDto;
}

const initialState: LineTemplateState = { filter: {} };

export const { reducer: lineTemplateReducer } = createSlice({
  name: 'lineTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setLineTemplateFilter, (state, action) => {
        state.filter = action.payload;
      })
      .addCase(setLineTemplates, (state, action) => {
        state.list = action.payload;
      })
      .addCase(setLineTemplate, (state, { payload }) => {
        state.current = payload;
        const list = state.list?.items;
        if (!list || !payload) return;
        list.splice(_findIndex(list, { id: payload.id }), 1, payload);
      });
  },
});
