import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { Vehicle } from '@fleet/widget/dto/vehicle';
import { VehicleList, VehicleType } from 'dto/vehicle';
import { SortBy } from 'components/search/SearchTable';
import _isEmpty from 'lodash/isEmpty';
import { createFloor } from 'features/floor/floorActions';
import qs from 'qs';
import { getVehicleSnugs } from 'features/vehicle/vehicleSnugActions';
import { api } from '@fleet/shared';

export const getVehicles = createAsyncThunk<
  VehicleList,
  { searchString?: string; vehicleTypeId?: VehicleType } | undefined
>(
  'vehicle/getVehicles',
  async (params, { getState }) =>
    (
      await api.get(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/vehicles/all?${qs.stringify(params)}`
      )
    ).data.vehicles
);

export const setSortBy = createAction<SortBy>('setSortBy');
export const setCurrentVehicle = createAction<Vehicle | undefined>(
  'setCurrentVehicle'
);
export const createVehicle = createAsyncThunk<number, string>(
  'vehicle/addVehicle',
  async (vehicleTypeId, { getState }) => {
    const state = getState();
    const [placeNumberingSystem] =
      state.classification.PLACE_NUMBERING_SYSTEM.filter(
        ({ id }) => id.indexOf('PLACE_NUMBERING_SYSTEM') > -1
      );

    const { id } = (
      await api.post<Vehicle>(
        `/organizations/${currentBusinessEntityIdSelector(state)}/vehicles`,
        {
          name: `Untitled-${state.vehicle.list.length}`,
          code: `Code-${state.vehicle.list.length}`,
          typeId: vehicleTypeId,
          placeNumberingSystemId: placeNumberingSystem.id,
        }
      )
    ).data;

    return id;
  }
);
export const readVehicle = createAsyncThunk<Vehicle, number>(
  'vehicle/getVehicle',
  async (id, { dispatch, getState }) => {
    const vehicle = (
      await api.get(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/vehicles/${id}`
      )
    ).data;
    dispatch(setCurrentVehicle(vehicle));

    _isEmpty(vehicle.floors)
      ? dispatch(createFloor())
      : dispatch(getVehicleSnugs());

    return vehicle;
  }
);
export const updateVehicle = createAsyncThunk<Promise<void>, Partial<Vehicle>>(
  'vehicle/updateVehicle',
  async (vehicle, { getState, dispatch }) => {
    const state = getState();

    const { type, placeNumberingSystem } = vehicle;
    const payload = {
      ...vehicle,
      floors: state.floor.list,
      typeId: type!.id,
      placeNumberingSystemId: placeNumberingSystem?.id,
    };
    await api.put(
      `/organizations/${currentBusinessEntityIdSelector(state)}/vehicles/${
        vehicle.id
      }`,
      payload
    );

    dispatch(readVehicle(payload.id!));
  }
);
export const deleteVehicle = createAsyncThunk<Promise<void>, number>(
  'vehicle/deleteVehicle',
  async (id, { dispatch, getState }) => {
    await api.delete(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/vehicles/${id}`
    );

    dispatch(getVehicles());
  }
);
export const duplicateVehicle = createAsyncThunk<Promise<number>, number>(
  'vehicle/duplicateVehicle',
  async (vehicleToDuplicateId, { dispatch, getState }) => {
    const { id } = (
      await api.post(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/vehicles/${vehicleToDuplicateId}/duplicate`
      )
    ).data;

    dispatch(getVehicles());

    return id;
  }
);
