import { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { Compositions } from 'routes/composition/Compositions';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { LineAssignment } from 'routes/assignment/LineAssignment';
import { Vehicles } from 'routes/vehicles/Vehicles';
import { LineTemplate } from 'routes/management/LineTemplate';
import { Trip } from 'routes/management/Trip';
import { TripAssignment } from 'routes/assignment/TripAssignment';
import { Header } from 'components/header';
import { Elements } from 'routes/elements/Elements';
import { InventoryClass } from 'routes/inventoryClass/InventoryClass';
import { AllocationScoreTemplate } from 'routes/allocationScoreTemplate/AllocationScoreTemplate';
import { SiAllocationRulesetCards } from 'routes/siAllocationRuleset/SiAllocationRulesetCards';
import { SiAllocationRulesetDetails } from 'routes/siAllocationRuleset/SiAllocationRulesetDetails';
import {
  getBusinessEntities,
  getClassifications,
} from 'features/classification/classificationActions';
import { appLoadingSelector } from 'features/loading/loadingSelectors';
import { SiServiceAllocationRulesetList } from 'routes/siServiceAllocationRuleset/SiServiceAllocationRulesetList';
import classNames from 'classnames';
import { AuthWrapper, useAuth } from '@fleet/shared';

export const Application = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const appLoading = useSelector(appLoadingSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);

  const isWindowsOS = useMemo(
    () => navigator.userAgent.indexOf('Win') > -1,
    []
  );

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getBusinessEntities());
    }
  }, [auth.isAuthenticated, dispatch]);

  useEffect(() => {
    if (auth.isAuthenticated && currentBusinessEntityId) {
      dispatch(getClassifications());
    }
  }, [auth.isAuthenticated, dispatch, currentBusinessEntityId]);

  return (
    <AuthWrapper appLoading={appLoading}>
      <Header />
      <div
        key={currentBusinessEntityId}
        className={classNames('app-content', { 'windows-os': isWindowsOS })}
      >
        <Switch>
          <Route exact path="/">
            <Redirect to="/vehicles/all" />
          </Route>
          <Route path="/vehicles" component={Vehicles} />
          <Route path="/compositions" component={Compositions} />

          <Route path="/manage/trip" component={Trip} />
          <Route path="/manage/line-template" component={LineTemplate} />
          <Route
            path="/assign/line-template/:compositionId"
            component={LineAssignment}
          />
          <Route
            path="/assign/trip/:compositionId"
            component={TripAssignment}
          />

          <Route path="/elements/:category?" component={Elements} />
          <Route
            path="/inventory-class/:action(create|edit)?/:id?"
            component={InventoryClass}
          />
          <Route
            path="/allocation-score-templates"
            component={AllocationScoreTemplate}
          />
          <Route
            path="/allocation-rulesets"
            component={SiAllocationRulesetCards}
            exact
          />
          <Route
            path="/allocation-rulesets/:action(create|edit)/:id?"
            component={SiAllocationRulesetDetails}
          />
          <Route
            path="/service-allocation-rulesets/:action(create|edit)?/:id?"
            component={SiServiceAllocationRulesetList}
          />
        </Switch>
      </div>
    </AuthWrapper>
  );
};
