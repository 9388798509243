import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSelector } from 'store/utils';
import { ExternalLink, Icon, ReadOnlyField as Field } from '@fleet/shared/mui';
import { compositionConstructSelector } from 'features/composition/compositionSelectors';
import { useParams } from 'react-router';
import { formatDate } from '@fleet/shared/utils/date';
import { compareDesc } from 'date-fns';
import { TransLabel } from 'i18n/trans/label';

export interface ModificationHistoryProps {
  open: boolean;
  onToggle: (arg: boolean) => void;
}

export const ModificationHistory: FC<ModificationHistoryProps> = ({
  open,
  onToggle,
}) => {
  const { t } = useTranslation();
  const compositionConstruct = useSelector(compositionConstructSelector);
  const { tripId } = useParams<{ tripId?: string }>();

  if (!tripId || !compositionConstruct?.processes?.length) return null;

  return (
    <div className={classNames('modification-history', { open })}>
      <div
        className="menu-toggle process-history-toggle"
        onClick={() => onToggle(!open)}
      >
        <Icon
          name={`direction-${open ? 'right' : 'left'}`}
          color="black"
          width={14}
          height={14}
        />
        <span className="link underline">
          <TransLabel
            i18nKey={
              open ? 'closeModificationHistory' : 'openModificationHistory'
            }
          />
        </span>
      </div>
      <div className="process-list">
        {[...compositionConstruct!.processes]
          .sort((a, b) =>
            compareDesc(
              new Date(a.createdOn.dateTime),
              new Date(b.createdOn.dateTime)
            )
          )
          .map(({ type, createdOn, createdByUser, processId }) => (
            <div className="process" key={processId}>
              <Field
                labelPosition="left"
                label={<TransLabel i18nKey="process" />}
                value={type.name}
              />
              <Field
                labelPosition="left"
                label={<TransLabel i18nKey="time" />}
                value={formatDate(createdOn.dateTime)}
              />
              <Field
                labelPosition="left"
                label={<TransLabel i18nKey="user" />}
                value={`${createdByUser.firstName} ${createdByUser.lastName}`}
              />
              <ExternalLink
                sx={{
                  display: 'block',
                  marginBottom: '16px',
                }}
                path={`/Admin/ProcessLogList/Logs/${processId}`}
                content={t('label.logs', 'Logs')}
              />
              <ExternalLink
                path={`/Trips/ProblematicTicketList?processId=${processId}&tripId=${tripId}`}
                content={t(
                  'label.problematicTransactions',
                  'Problematic transactions'
                )}
              />
            </div>
          ))}
      </div>
    </div>
  );
};
