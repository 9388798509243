import { selector } from 'store/utils';

export const loadingKeySelector = selector((state) => state.common.loadingKey);
export const currentBusinessEntityIdSelector = selector(
  (state) => state.common.currentBusinessEntityId!
);

export const isDraggingFromPaletteSelector = selector(
  (state) => state.common.isDraggingFromPalette
);

export const dataLoadingSelector = selector((state) => state.common.loading);
