import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/utils';
import { Pagination } from '@fleet/shared/dto/pagination';
import {
  LineTemplateDto,
  LineTemplateFilterDto,
  LineTemplatePayload,
} from 'dto/lineTemplate';
import { api } from '@fleet/shared';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { TripFilterDto } from 'dto/trip';
import { setTripFilter } from 'features/trip/tripActions';
import { lineTemplateCurrentSelector } from 'features/lineTemplate/lineTemplateSelectors';
import { Option } from '@fleet/shared/dto/option';

export const setLineTemplateFilter = createAction<
  Partial<LineTemplateFilterDto>
>('lineTemplate/setFilter');

export const fetchLineTemplates = createAsyncThunk<
  Pagination<LineTemplateDto>,
  Partial<LineTemplateFilterDto> | undefined
>(
  'lineTemplate/fetch',
  async (values, { getState }) =>
    (
      await api.post<Pagination<LineTemplateDto>>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/line-templates/get`,
        values,
        { baseURL: process.env.REACT_APP_API_LM }
      )
    ).data
);

export const setLineTemplates = createAction<
  Pagination<LineTemplateDto> | undefined
>('lineTemplate/setList');
export const searchLineTemplates = createAsyncThunk<
  Pagination<LineTemplateDto>,
  Partial<LineTemplateFilterDto> | undefined
>('lineTemplate/search', async (values, { dispatch, getState }) => {
  if (values) dispatch(setLineTemplateFilter(values));

  const state = getState();
  const { filter } = state.lineTemplate;
  const data = await dispatch(fetchLineTemplates(filter)).unwrap();
  dispatch(setLineTemplates(data));
  return data;
});

export const setLineTemplate = createAction<LineTemplateDto | undefined>(
  'lineTemplate/setCurrent'
);
export const getLineTemplate = createAsyncThunk<LineTemplateDto, string>(
  'lineTemplate/getCurrent',
  async (id, { dispatch }) => {
    const {
      items: [lineTemplate],
    } = await dispatch(fetchLineTemplates({ id: +id })).unwrap();

    dispatch(setLineTemplate(lineTemplate));
    return lineTemplate;
  }
);

export const setCurrentCompositionLineTemplates = createAction<{
  data: LineTemplateDto[];
  totalCount: number;
  offset: number;
}>('setCurrentCompositionLineTemplates');
export const getCompositionLineTemplates = createAsyncThunk<
  void,
  Partial<TripFilterDto>
>('lineTemplate/compositionTemplates', async (values, { dispatch }) => {
  if (values) dispatch(setTripFilter(values));
  const { items, totalCount, offset } = await dispatch(
    fetchLineTemplates(values)
  ).unwrap();
  dispatch(
    setCurrentCompositionLineTemplates({ data: items, totalCount, offset })
  );
});
export const removeLineTemplateRelation = createAsyncThunk<unknown, number>(
  'removeLineTemplateRelation',
  async (id, { getState }) => {
    await api.delete<never>(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/line-template-vehicle-compositions/${id}`
    );
  }
);
export const assignLineTemplate = createAsyncThunk<
  unknown,
  LineTemplatePayload
>('assignLineTemplate', async (payload, { getState }) => {
  await api.post<{ id: number }>(
    `/organizations/${currentBusinessEntityIdSelector(
      getState()
    )}/line-template-vehicle-compositions${
      payload.lineTemplateIds ? '/bulk' : ''
    }`,
    payload
  );
});
export const updateLineTemplate = createAsyncThunk<
  unknown,
  LineTemplatePayload
>(
  'updateLineTemplate',
  async ({ lineTemplateId, ...payload }, { getState, rejectWithValue }) => {
    try {
      await api.put<{ id: number }>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/line-template-vehicle-compositions/${lineTemplateId}`,
        payload
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getLineTemplateStops = createAsyncThunk<Array<Option<number>>>(
  'lineTemplate/getStops',
  async (_, { getState }) => {
    const lineTemplate = lineTemplateCurrentSelector(getState());
    if (!lineTemplate?.id) return [];
    const {
      data: { items },
    } = await api.get<{ items: Array<{ id: number; stopName: string }> }>(
      `/line-templates/${lineTemplate.id}/route-stops`,
      { baseURL: process.env.REACT_APP_API_LM }
    );
    return items.map(({ id, stopName }) => ({
      value: id,
      label: stopName,
    }));
  }
);
