import { createSlice } from '@reduxjs/toolkit';
import {
  getCompositions,
  getComposition,
  setCurrentCompositionVehicle,
  clearCurrentComposition,
  setCompositionProcessId,
  getAssignedComposition,
  setCompositionsTransportType,
} from 'features/composition/compositionActions';
import type {
  CompositionConstructDto,
  CompositionRowDto,
} from 'dto/composition';
import { TransportationTypeId } from '@fleet/widget/dto/transportation';
import type { LineTemplateDto, LineTemplateFilterDto } from 'dto/lineTemplate';
import {
  getTripStops,
  setCurrentCompositionTrips,
} from 'features/trip/tripActions';
import {
  getLineTemplateStops,
  setCurrentCompositionLineTemplates,
} from 'features/lineTemplate/lineTemplateActions';
import { type Option } from '@fleet/shared/dto/option';

export interface CompositionState {
  list: Array<CompositionRowDto>;
  construct?: CompositionConstructDto | null;
  transportationTypeId?: TransportationTypeId;
  lineTemplatesFilter: Partial<LineTemplateFilterDto>;
  lineTemplates: {
    data?: LineTemplateDto[];
    offset?: number;
    totalCount?: number;
    loading?: boolean;
  };
  stops?: Array<Option<number>>;
}

const initialState: CompositionState = {
  list: [],
  lineTemplatesFilter: {
    limit: 10,
  },
  lineTemplates: {},
};

export const { reducer: compositionReducer } = createSlice({
  name: 'composition',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompositions.fulfilled, (state, action) => {
        state.list = action.payload.vehicleCompositions;
      })
      .addCase(getComposition.fulfilled, (state, action) => {
        state.construct = action.payload;
      })
      .addCase(getAssignedComposition.fulfilled, (state, action) => {
        state.construct = action.payload;
      })
      .addCase(getAssignedComposition.rejected, (state) => {
        state.construct = null;
      })
      .addCase(setCompositionProcessId, (state, action) => {
        if (state.construct) {
          state.construct.ongoingModificationProcessId = action.payload;
        }
      })
      .addCase(clearCurrentComposition, (state) => {
        delete state.construct;
      })
      .addCase(setCurrentCompositionVehicle, (state, action) => {
        state.construct!.vehicle = action.payload;
      })
      .addCase(setCurrentCompositionLineTemplates, (state, action) => {
        state.construct!.lineTemplates = action.payload;
      })
      .addCase(setCurrentCompositionTrips, (state, action) => {
        state.construct!.trips = action.payload;
      })
      .addCase(setCompositionsTransportType, (state, action) => {
        state.transportationTypeId = action.payload;
      })
      .addCase(getLineTemplateStops.fulfilled, (state, action) => {
        state.stops = action.payload;
      })
      .addCase(getTripStops.fulfilled, (state, action) => {
        state.stops = action.payload;
      });
  },
});
