import type { FC } from 'react';
import { useContext, useMemo } from 'react';
import { VehicleProperties } from 'routes/designer/VehicleProperties';
import { SelectionProperties } from 'routes/designer/SelectionProperties';
import { useSelector } from 'store/utils';
import { Icon, Loader } from '@fleet/shared/mui';
import { TransLabel } from 'i18n/trans/label';
import classNames from 'classnames';
import { useSelectionManager } from 'routes/designer/SelectionManager';
import { DesignerPropertiesPanelProps } from 'dto/propertiesPanel';
import { ViewerContext } from '@fleet/widget/components/viewer/Context';
import { SeatsFilter, useSeatsFilter } from 'routes/designer/SeatsFilter';
import { dataLoadingSelector } from 'features/common/commonSelectors';
import { LayoutColScrollable } from 'components/layout/LayoutColScrollable';

export const DesignerPropertiesPanel: FC<DesignerPropertiesPanelProps> = ({
  updateSelection,
  updateVehicleData,
  initialLoading,
}) => {
  const designerLoading = useSelector(dataLoadingSelector);
  const [{ nodes: selectedNodes, snug }] = useSelectionManager();
  const { setFilteredPlaces, filteredPlaces } = useContext(ViewerContext);
  const selectionActive = useMemo(
    () => Boolean(selectedNodes.length && !snug?.editable),
    [selectedNodes.length, snug?.editable]
  );
  const seatsFilter = useSeatsFilter({
    setFilteredPlaces,
  });

  return (
    <>
      <div
        className={classNames('save-control', {
          hidden: initialLoading,
        })}
      >
        {designerLoading ? (
          <>
            <Loader active size="xs" />
            <span className="saving-label">
              <TransLabel i18nKey="savingChanges" />
            </span>
          </>
        ) : (
          <>
            <Icon name="save" width={11} height={13} />
            <span className="success-label">
              <TransLabel i18nKey="allChangesSaved" />
            </span>
          </>
        )}
      </div>
      <LayoutColScrollable className="designer-properties-panel">
        <SeatsFilter
          toggleLabel={(active) => (
            <TransLabel i18nKey={`seatFilters.${active ? 'hide' : 'show'}`} />
          )}
          filter={seatsFilter}
          filteredPlaces={filteredPlaces}
        />
        {selectionActive ? (
          <SelectionProperties updateSelection={updateSelection} />
        ) : (
          <VehicleProperties updateVehicleData={updateVehicleData} />
        )}
      </LayoutColScrollable>
    </>
  );
};
