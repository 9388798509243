import { createLoadingSelector } from 'store/utils';
import {
  getBusinessEntities,
  getClassifications,
} from 'features/classification/classificationActions';
import {
  copySiAllocationRuleset,
  createSiAllocationRuleset,
  createUpdateSiAllocationRulesetRule,
  deleteSiAllocationRuleset,
  deleteSiAllocationRulesetRule,
  getSiAllocationRuleset,
  updateSiAllocationRuleset,
} from 'features/siAllocationRuleset/siAllocationRulesetActions';
import {
  createUpdateSiServiceAllocationRuleset,
  deleteSiServiceAllocationRuleset,
  getSiServiceAllocationRuleset,
  getSiServiceAllocationRulesets,
} from 'features/siServiceAllocationRuleset/siServiceAllocationRulesetActions';
import { getVehicles } from 'features/vehicle/vehicleActions';
import {
  getComposition,
  getCompositions,
} from 'features/composition/compositionActions';
import {
  getCompositionLineTemplates,
  getLineTemplate,
  getLineTemplateStops,
  searchLineTemplates,
} from 'features/lineTemplate/lineTemplateActions';
import {
  getCompositionTrips,
  getTrip,
  getTripStops,
  searchTrips,
} from 'features/trip/tripActions';

export const appLoadingSelector = createLoadingSelector(
  getBusinessEntities,
  getClassifications
);

export const vehiclesLoadingSelector = createLoadingSelector(getVehicles);
export const compositionsLoadingSelector =
  createLoadingSelector(getCompositions);

export const siAllocationRulesetLoadingSelector = createLoadingSelector(
  getSiAllocationRuleset,
  createSiAllocationRuleset,
  updateSiAllocationRuleset,
  copySiAllocationRuleset,
  deleteSiAllocationRuleset,
  createUpdateSiAllocationRulesetRule,
  deleteSiAllocationRulesetRule
);

export const serviceSiAllocationRulesetsLoadingSelector = createLoadingSelector(
  getSiServiceAllocationRulesets
);

export const serviceSiAllocationRulesetLoadingSelector = createLoadingSelector(
  getSiServiceAllocationRuleset,
  createUpdateSiServiceAllocationRuleset,
  deleteSiServiceAllocationRuleset
);
export const lineTemplateLoadingSelector = createLoadingSelector(
  searchLineTemplates,
  getLineTemplate,
  getLineTemplateStops
);
export const getCompositionTemplatesLoadingSelector = createLoadingSelector(
  getComposition,
  getCompositionLineTemplates
);

export const tripLoadingSelector = createLoadingSelector(
  searchTrips,
  getTrip,
  getTripStops
);
export const getCompositionTripsLoadingSelector = createLoadingSelector(
  getComposition,
  getCompositionTrips
);
