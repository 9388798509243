import { DragEvent, SyntheticEvent, useCallback, useMemo } from 'react';
import { CompositionVehicleTabs } from '@fleet/widget/components/CompositionVehicleTabs';
import { CompositionVehicle } from 'routes/composition/CompositionVehicle';
import { ViewerProvider } from '@fleet/widget/components/viewer/Context';
import { useDispatch, useSelector } from 'store/utils';
import {
  compositionConstructSelector,
  compositionConstructVehicleSelector,
} from 'features/composition/compositionSelectors';
import { currentVehicleSelector } from 'features/vehicle/vehicleSelector';
import {
  activeFloorSelector,
  floorListSelector,
} from 'features/floor/floorSelectors';
import { useHistory, useParams } from 'react-router';
import {
  deleteComposition,
  duplicateComposition,
  pushVehicleToComposition,
  updateComposition,
} from 'features/composition/compositionActions';
import { ViewerCanvasContainer } from '@fleet/widget/components/viewer/CanvasContainer';
import { ViewerCanvas } from '@fleet/widget/components/viewer/Canvas';
import { ViewerControls } from 'components/viewer/ViewerControls';
import { ConnectedLoader } from 'components/common/loader/ConnectedLoader';
import { setLoadingKey } from 'features/common/commonActions';
import { Icon } from '@fleet/shared';
import { TransMessage } from 'i18n/trans/message';
import { TransportationTypeId } from '@fleet/widget/dto/transportation';

export const COMPOSITION_VEHICLE_LOADING = 'COMPOSITION_VEHICLE_LOADING';

export const CompositionViewer = () => {
  const compositionConstruct = useSelector(compositionConstructSelector);
  const currentCompositionVehicle = useSelector(
    compositionConstructVehicleSelector
  );
  const currentVehicle = useSelector(currentVehicleSelector)!;
  const floors = useSelector(floorListSelector);
  const currentFloor = useSelector(activeFloorSelector);
  const displayTabs = useMemo(
    () =>
      compositionConstruct?.transportationTypeId &&
      [TransportationTypeId.BUS, TransportationTypeId.TRAIN].includes(
        compositionConstruct.transportationTypeId
      ),
    [compositionConstruct?.transportationTypeId]
  );
  const handleDragOver = useCallback((event: SyntheticEvent) => {
    event.preventDefault();
  }, []);

  const { compositionId } = useParams<{ compositionId: string }>();
  const dispatch = useDispatch();
  const handleDrop = useCallback(
    async (event: DragEvent) => {
      dispatch(setLoadingKey(COMPOSITION_VEHICLE_LOADING));

      try {
        const vehicleId = event.dataTransfer.getData('text');
        await dispatch(pushVehicleToComposition({ compositionId, vehicleId }));
      } catch (e) {
      } finally {
        dispatch(setLoadingKey(null));
      }
    },
    [dispatch, compositionId]
  );
  const history = useHistory();
  const actions = useMemo(() => {
    const id = +compositionId;
    return {
      rename: (name: string) => {
        dispatch(updateComposition({ compositionId: id, name }));
      },
      duplicate: async () => {
        const duplicateId = await dispatch(duplicateComposition(id)).unwrap();
        history.push(`/compositions/${duplicateId}`);
      },
      delete: async () => {
        await dispatch(deleteComposition(id));
        history.push('/compositions/all');
      },
    };
  }, [compositionId, dispatch, history]);

  if (!compositionConstruct) return null;
  const {
    name: compositionName,
    compositionVehicles,
    transportationTypeId,
  } = compositionConstruct;

  return (
    <div
      className="composition-viewer"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <ConnectedLoader
        loadingKey={COMPOSITION_VEHICLE_LOADING}
        size="container"
        hideOverlay
      />
      <div className="composition-viewer-container">
        <ViewerProvider
          vehicle={{
            ...currentVehicle,
            name: compositionName,
          }}
          floors={floors}
          currentFloor={currentFloor}
        >
          <div className="fleet-viewer scrollable">
            <ViewerControls actions={actions} />
            <div className="composition-wrapper">
              <div className="left-padding" />
              {!compositionVehicles.length && (
                <div className="empty-composition-message">
                  <Icon name="drag-n-drop" width={91} height={67} />
                  <p>
                    <TransMessage i18nKey="dragAndDropVehicle" />
                  </p>
                </div>
              )}
              <ViewerCanvasContainer>
                <ViewerCanvas
                  {...(currentCompositionVehicle?.isVehicleFlipped && {
                    rotation: 180,
                  })}
                />
              </ViewerCanvasContainer>
              {displayTabs ? (
                <CompositionVehicleTabs
                  component="div"
                  className="right-padding composition-vehicles"
                  vehicles={compositionVehicles}
                  children={(props) => (
                    <CompositionVehicle
                      key={props.vehicle.id}
                      {...props}
                      transportationTypeId={transportationTypeId!}
                      reordering={Boolean(compositionVehicles.length > 1)}
                    />
                  )}
                />
              ) : (
                <div className="right-padding" />
              )}
            </div>
          </div>
        </ViewerProvider>
      </div>
    </div>
  );
};
