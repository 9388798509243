import type { FC, FocusEvent } from 'react';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Input, Select, ToggleButtonGroup } from '@fleet/shared/mui';
import { InventoryClassSelect } from 'components/common/select/InventoryClass';
import { ViewerContext } from '@fleet/widget/components/viewer/Context';
import type { DesignerPropertiesPanelProps } from 'dto/propertiesPanel';
import { useSelector } from 'store/utils';
import { classifiersSelector } from 'features/classification/classificationSelectors';
import { Vector2d } from 'konva/lib/types';
import { useAlert } from 'react-alert';
import Konva from 'konva';
import { ORIENTATION_ID } from '@fleet/widget/dto/floor';
import { Box, Button, InputAdornment, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TransMessage } from 'i18n/trans/message';
import { TransTitle } from 'i18n/trans/title';
import { TransLabel } from 'i18n/trans/label';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import { useSelectionManager } from 'routes/designer/SelectionManager';
import { RankSelect } from 'components/common/select/RankSelect';
import { InventoryType } from 'dto/organization';
import { useInventory } from 'hooks/useInventory';
import { Properties } from 'components/propertiesPanel/Properties';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { TransButton } from 'i18n/trans/button';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '& .MuiTypography-body1.MuiTypography-gutterBottom': {
        marginTop: theme.spacing(3),
        fontWeight: 'bold',
      },
    },
    directionToggle: {
      '& .MuiButtonBase-root': {
        padding: '5px 8px',
      },
      marginBottom: '12px',
    },
  }),
  {
    name: 'SelectionProperties',
  }
);

interface SelectionPropertiesProps
  extends Pick<DesignerPropertiesPanelProps, 'updateSelection'> {}

export const SelectionProperties: FC<SelectionPropertiesProps> = ({
  updateSelection,
}) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const { selectBoxRef } = useContext(ViewerContext);
  const [
    { nodes, compartment: selectedCompartment },
    { setCompartment: setSelectedCompartment },
  ] = useSelectionManager();
  const [selected] = nodes;
  const isSameCategoryNodes = useMemo(
    () =>
      selected?.category === 'compartment' ||
      nodes.every(({ category }) => category === selected.category),
    [nodes, selected.category]
  );

  const classifiers = useSelector(classifiersSelector);
  const orientationOptions = useMemo(
    () =>
      classifiers.ORIENTATION.map(({ id, name }) => ({
        label: name,
        value: id,
        icon: {
          [ORIENTATION_ID.UP]: 'direction-top',
          [ORIENTATION_ID.RIGHT]: 'direction-right',
          [ORIENTATION_ID.DOWN]: 'direction-bottom',
          [ORIENTATION_ID.LEFT]: 'direction-left',
        }[id],
      })),
    [classifiers.ORIENTATION]
  );

  const mountingOptions = useClassificationOptions(
    ClassificationGroup.MOUNTING
  );

  const compartment = useMemo(
    () => (selectedCompartment?.editable ? selectedCompartment : undefined),
    [selectedCompartment]
  );
  const toggleEditCompartmentContent = useCallback(async () => {
    if (!selectedCompartment) return;
    setSelectedCompartment?.({
      ...selectedCompartment,
      editable: !selectedCompartment.editable,
    });
  }, [selectedCompartment, setSelectedCompartment]);
  const singleSelection =
    compartment ?? nodes.length === 1
      ? selected
      : selected?.category === 'compartment'
      ? selected
      : undefined;
  const multipleElementsSelected = !compartment && nodes.length > 1;
  const singleSignSelected = singleSelection?.category === 'sign';
  const singlePlaceSelected = singleSelection?.category === 'place';
  const singleCompartmentSelected = singleSelection?.category === 'compartment';
  const singleSelected = singlePlaceSelected || singleCompartmentSelected;
  const isBerthSelected = singleSelection?.typeId === 'PLACE_TYPE.BERTH';
  const compartmentNodes = useMemo(() => {
    if (!singleCompartmentSelected) return [];
    return nodes.filter(({ category }) => category !== 'compartment');
  }, [nodes, singleCompartmentSelected]);

  const nodesInventoryClass = useInventory(nodes);
  const compartmentNodesInventoryClass = useInventory(compartmentNodes);

  const multipleSeatsSelected =
    multipleElementsSelected &&
    nodes.every(({ category }) => category === 'place');
  const hideDirectionToggle =
    singleCompartmentSelected ||
    singleSignSelected ||
    (multipleElementsSelected && !multipleSeatsSelected);

  const internalElementSelected = singleSelection?.category === 'internal';
  const selectionOrientationId = singleSelection?.orientation?.id;
  const getElementCoordinate = useCallback(
    (axis: 'x' | 'y') =>
      multipleSeatsSelected ? 'Mixed' : selected.coordinates[axis],
    [multipleSeatsSelected, selected.coordinates]
  );
  const seatNumberValue = useMemo(() => {
    if (singleSelected) return selected.number ?? '';
  }, [selected, singleSelected]);
  const onPlaceNumberChange = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const prevNumber = nodes[0].number || undefined;
      const { value: number } = e.target;

      if (prevNumber !== number) {
        updateSelection({ number });
      }
    },
    [nodes, updateSelection]
  );

  const updateElPosition = useCallback(
    async (axis: 'x' | 'y', e: FocusEvent<HTMLInputElement>) => {
      if (nodes.length !== 1) return;
      const [selected] = nodes;
      const ignoreIntersections = selected.typeId.indexOf('WALL') > -1;
      const stage = selectBoxRef.current!.getStage()!;
      const layer = stage.children![0];
      const [width, height] = [
        ORIENTATION_ID.LEFT,
        ORIENTATION_ID.RIGHT,
      ].includes(selected.orientation?.id)
        ? [selected.height, selected.width]
        : [selected.width, selected.height];
      const { x, y } = {
        x: getElementCoordinate('x'),
        y: getElementCoordinate('y'),
        [axis]: +e.target.value,
      } as Vector2d;
      if (x === +selected.coordinates.x && y === +selected.coordinates.y) {
        return;
      }
      const hasIntersection =
        !ignoreIntersections &&
        [
          { x, y },
          { x: x + width, y },
          { x, y: y + height },
          { x: x + width, y: y + height },
        ].some((coord) => {
          const intersections = layer
            .getAllIntersections(coord)
            ?.map((el) => el.getParent())
            .filter(
              ({ attrs }) =>
                attrs.props.typeId.indexOf('WALL') === -1 &&
                attrs.props.id !== selected.id
            );
          return intersections.length;
        });

      if (hasIntersection) {
        alert.show(<TransMessage i18nKey="intersectedElementPresent" />, {
          type: 'error',
          timeout: 3000,
        });
        e.target.value = '' + getElementCoordinate(axis);
      } else {
        const currentNode = layer.findOne(
          (node: Konva.Node) =>
            node.getType() === 'Group' && node.attrs.props?.id === selected.id
        );
        currentNode.setAttrs({ x, y });
        await updateSelection({ coordinates: { x, y } });
        // layer
        //   .findOne('#element-controls-tooltip')
        //   ?.setAttrs({ x: x - 4, y: y - 4 });
      }
    },
    [alert, getElementCoordinate, nodes, selectBoxRef, updateSelection]
  );

  const classes = useStyles();

  return (
    <>
      <div
        className={classNames('designer-selection-properties', classes.root)}
      >
        <Typography variant="subtitle">
          <TransTitle
            i18nKey="elementProperties"
            values={{
              count: isSameCategoryNodes ? 1 : 2,
              element: selected?.type,
            }}
            tOptions={{ postProcess: 'interval' }}
          />
        </Typography>
        {multipleSeatsSelected && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'var(--warning)',
              marginBottom: '16px',
              '& .MuiTypography-root': {
                marginLeft: '10px',
              },
            }}
          >
            <Icon name="warning" />
            <Typography>
              <TransMessage i18nKey="notAllActionsVisible" />
            </Typography>
          </Box>
        )}
        <Input
          startAdornment={<InputAdornment position="start">X: </InputAdornment>}
          value={String(getElementCoordinate('x'))}
          onBlur={updateElPosition.bind(null, 'x')}
          disabled={multipleSeatsSelected}
          label={<TransTitle i18nKey="location" />}
          margin="dense"
        />
        <Input
          startAdornment={<InputAdornment position="start">Y: </InputAdornment>}
          value={String(getElementCoordinate('y'))}
          onBlur={updateElPosition.bind(null, 'y')}
          disabled={multipleSeatsSelected}
          margin="dense"
        />
        {singleSelected && (
          <>
            <Input
              value={seatNumberValue}
              onBlur={onPlaceNumberChange}
              label={
                <TransLabel
                  i18nKey="compartmentNumber"
                  {...(singlePlaceSelected && {
                    i18nKey: isBerthSelected ? 'berthNumber' : 'seatNumber',
                  })}
                />
              }
              margin="dense"
              resetOnFail
            />
          </>
        )}
        {singleCompartmentSelected && (
          <>
            <Typography variant="body1" gutterBottom>
              <TransTitle i18nKey="contents" />
            </Typography>

            {compartmentNodes.map(({ id, type, number, coordinates }) => (
              <Grid key={id} container columns={3} alignItems="center">
                <Grid item xs>
                  {type}
                </Grid>
                <Grid item xs="auto">
                  {number ?? (
                    <>
                      X: {coordinates.x}
                      &nbsp; Y: {coordinates.y}
                    </>
                  )}
                </Grid>
              </Grid>
            ))}

            <Button
              onClick={toggleEditCompartmentContent}
              variant="text"
              size="small"
              startIcon={
                <Icon
                  name={selectedCompartment?.editable ? 'deactivate' : 'plus'}
                />
              }
              color={selectedCompartment?.editable ? 'error' : undefined}
            >
              <TransButton
                i18nKey={
                  selectedCompartment?.editable
                    ? 'editContentsStop'
                    : 'editContents'
                }
              />
            </Button>
          </>
        )}
        {!hideDirectionToggle && (
          <>
            <Typography variant="body1" gutterBottom>
              <TransTitle i18nKey="orientation" />
            </Typography>
            <ToggleButtonGroup
              className={classes.directionToggle}
              color="secondary"
              value={selectionOrientationId}
              options={orientationOptions}
              onChange={(orientationId) =>
                updateSelection({ orientation: { id: orientationId } })
              }
            />
          </>
        )}

        {internalElementSelected && (
          <Select
            placeholder={t('label.chooseMounting', 'Choose mounting')!}
            value={selected?.mounting?.id ?? null}
            options={mountingOptions}
            onChange={(value) => {
              updateSelection({ mounting: { id: value as string } });
            }}
            label={<TransTitle i18nKey="mounting" />}
            margin="dense"
            showEmptyOption
          />
        )}

        {(singlePlaceSelected || multipleSeatsSelected) && (
          <>
            <RankSelect
              value={nodesInventoryClass?.rank ?? null}
              onChange={(rank) => updateSelection({ rank })}
              label={<TransTitle i18nKey="rank" />}
              margin="dense"
            />

            <InventoryClassSelect
              value={nodesInventoryClass?.inventoryClassId ?? null}
              onChange={(inventoryClassId, properties) =>
                updateSelection({
                  inventoryClass: { id: inventoryClassId },
                  properties,
                })
              }
              inventoryType={nodesInventoryClass?.type}
              label={<TransTitle i18nKey="class" />}
              margin="dense"
            />
          </>
        )}
        {singleCompartmentSelected && (
          <>
            <InventoryClassSelect
              label={<TransLabel i18nKey="compartment" />}
              value={selected?.inventoryClass?.id ?? null}
              onChange={(inventoryClassId, properties) =>
                updateSelection({
                  inventoryClass: { id: inventoryClassId },
                  properties,
                })
              }
              inventoryType={InventoryType.COMPARTMENT}
              margin="dense"
            />
            {compartmentNodesInventoryClass && (
              <InventoryClassSelect
                label={<TransLabel i18nKey="contents" />}
                margin="dense"
                value={compartmentNodesInventoryClass.inventoryClassId}
                onChange={(inventoryClassId, properties) =>
                  updateSelection(
                    {
                      inventoryClass: { id: inventoryClassId },
                      properties,
                    },
                    true
                  )
                }
                inventoryType={compartmentNodesInventoryClass.type}
              />
            )}

            <RankSelect
              value={singleSelection?.rank ?? null}
              onChange={(rank) => updateSelection({ rank })}
              label={<TransTitle i18nKey="rank" />}
              margin="dense"
            />
          </>
        )}
        {nodesInventoryClass?.type && (
          <Properties
            typeId={nodesInventoryClass.type}
            onConfirm={updateSelection}
            {...((singleCompartmentSelected || singlePlaceSelected) && {
              properties: selected.properties,
              blockingReason: selected.blockingReason,
              dimensions: selected.dimensions,
            })}
            title={<TransTitle i18nKey="properties" />}
          />
        )}
      </div>
    </>
  );
};
