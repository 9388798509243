import { type FC, useCallback, useMemo } from 'react';
import { type PreparedPaletteElement } from '@fleet/widget/dto/element';
import { generatePath, NavLink, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams } from 'react-router';
import { DrawerForm, DrawerFormCloseButton } from '@fleet/shared';
import { ElementIcon } from 'routes/elements/ElementIcon';
import { useDispatch } from 'store/utils';
import {
  updateFloorElementIcon,
  updateSignDimensions,
  updateSignLocalizations,
} from 'features/element/elementActions';
import { TransTitle } from 'i18n/trans/title';
import { CardHeader, Typography } from '@mui/material';
import { ElementDetails } from 'routes/elements/ElementDetails';
import { ElementPayload } from 'dto/element';

const useStyles = makeStyles(
  (theme) => ({
    element: {
      '&$selected': {
        borderColor: theme.palette.primary.main,
      },
    },
    selected: {},
  }),
  {
    name: 'Element',
  }
);

interface ElementProps {
  data: PreparedPaletteElement;
}

export const Element: FC<ElementProps> = ({ data }) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  const { category, paletteCategory } = data;
  const handleCloseDetails = useCallback(() => {
    history.push(
      generatePath(path, {
        category,
      }),
      {
        disableScrollTo: true,
      }
    );
  }, [history, path, category]);

  const title = useMemo(() => {
    switch (category) {
      case 'internal':
        switch (paletteCategory) {
          case 'walls':
          case 'wallsAngled':
          case 'wallsTemp':
            return <TransTitle i18nKey="walls" />;
          case 'doors':
            return <TransTitle i18nKey="windowsAndDoors" />;
          case 'tables':
            return <TransTitle i18nKey="tables" />;
        }
        return '';
      default:
        return <TransTitle i18nKey={category} />;
    }
  }, [category, paletteCategory]);

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async ({ localizations, ...data }: ElementPayload) => {
      if (localizations.length)
        await dispatch(updateSignLocalizations({ id: data.id, localizations }));
      if (data.icon?.name || data.icon === null)
        await dispatch(updateFloorElementIcon(data));
      if (data.icon?.content && (data.width || data.height))
        await dispatch(updateSignDimensions(data));
      handleCloseDetails();
    },
    [dispatch, handleCloseDetails]
  );

  const isOpen = useMemo(
    () => Boolean(id && id === data.elementId),
    [data.elementId, id]
  );
  const initialValues = useMemo<ElementPayload>(
    () => ({
      // Display data
      name: data.name,
      type: data.type,
      subTypeId: data.subTypeId,
      // Request data
      id: data.elementId,
      category: data.category,
      icon: {
        content: data.icon,
      },
      iconCode: data.iconCode,
      localizations: [],
      width: data.width,
      height: data.height,
    }),
    [data]
  );

  return (
    <>
      <ElementIcon<NavLink>
        component={NavLink}
        to={{
          pathname: generatePath(path, {
            category: data.category,
            id: data.elementId,
          }),
          state: {
            disableScrollTo: true,
          },
        }}
        activeClassName={classes.selected}
        className={classes.element}
        icon={data.icon}
        name={data.name}
        width={data.width}
      />

      <DrawerForm
        open={isOpen}
        onClose={handleCloseDetails}
        arrow={false}
        width={480}
        disablePortal={false}
        hideBackdrop={false}
      >
        <CardHeader
          title={<Typography variant="subtitle">{title}</Typography>}
          action={<DrawerFormCloseButton onClick={handleCloseDetails} />}
        />
        <ElementDetails initialValues={initialValues} onSubmit={onSubmit} />
      </DrawerForm>
    </>
  );
};
