import type { FC, MouseEventHandler } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, Stack, Typography } from '@mui/material';
import { TransLabel } from 'i18n/trans/label';
import { useDispatch, useSelector } from 'store/utils';
import { vehicleSnugsSelector } from 'features/vehicle/vehicleSelector';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { ConfirmDeleteModal, Icon, useModal } from '@fleet/shared';
import { TransModal } from 'i18n/trans/modal';
import { Snug } from '@fleet/widget/dto/floor';
import {
  createVehicleSnug,
  deleteVehicleSnug,
} from 'features/vehicle/vehicleSnugActions';
import { useSelectionManager } from 'routes/designer/SelectionManager';
import classNames from 'classnames';
import { Button, Collapsible } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { ViewerContext } from '@fleet/widget/components/viewer/Context';
import _uniq from 'lodash/uniq';

const useStyles = makeStyles(
  (theme) => ({
    title: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(),
    },
    collapse: {
      '&.Mui-expanded': {
        marginBottom: 0,
      },
      '& .MuiAccordionSummary-root': {
        boxShadow: theme.shadows[1],
        padding: theme.spacing(1),
      },
      '& .MuiAccordionDetails-root ': {
        paddingTop: 0,
      },
    },
    list: {
      margin: '0 -12px',
    },
    listItem: {
      cursor: 'pointer',
    },
  }),
  {
    name: 'VehicleSnugs',
  }
);

interface VehicleSnugProps extends Snug {
  onDelete?: MouseEventHandler<HTMLButtonElement>;
  onStopEditing?: MouseEventHandler<HTMLButtonElement>;
}
interface VehicleSnugsProps {}

const VehicleSnug: FC<VehicleSnugProps> = ({
  id,
  placeIds,
  onDelete,
  onStopEditing,
  ...props
}) => {
  const [{ snug }, { setSnug }] = useSelectionManager();

  const handleEditSnugs = useCallback<MouseEventHandler<HTMLButtonElement>>(
    async (event) => {
      setSnug({ id, placeIds, editable: !Boolean(snug?.editable) });
      onStopEditing?.(event);
    },
    [id, onStopEditing, placeIds, setSnug, snug?.editable]
  );
  const handleViewSnugs = useCallback(
    () => setSnug({ id, placeIds }),
    [id, placeIds, setSnug]
  );

  const classes = useStyles();
  return (
    <ListItem
      key={id}
      className={classNames(classes.listItem)}
      disablePadding
      {...props}
      secondaryAction={
        <>
          {snug?.editable && snug.id === id ? (
            <Button
              onClick={handleEditSnugs}
              variant="text"
              color="error"
              icon="deactivate"
              sx={{ px: 0 }}
            >
              <TransButton i18nKey="editStop" />
            </Button>
          ) : (
            <>
              <IconButton
                edge={Boolean(id) ? 'start' : 'end'}
                color="primary"
                onClick={handleEditSnugs}
              >
                <Icon name="edit" />
              </IconButton>
              {Boolean(id) && (
                <IconButton
                  data-id={id}
                  edge="end"
                  color="error"
                  onClick={onDelete}
                >
                  <Icon name="trash" />
                </IconButton>
              )}
            </>
          )}
        </>
      }
    >
      <ListItemButton
        sx={{
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: 'common.white',
          },
        }}
        onClick={handleViewSnugs}
        selected={snug?.id === id}
      >
        <Typography
          variant="body1"
          fontWeight="700"
          sx={{ color: 'primary.main' }}
        >
          <TransLabel i18nKey="snug" />
        </Typography>
        &nbsp;
        <Typography
          variant="body1"
          fontWeight="400"
          sx={{ color: 'text.secondary' }}
        >
          <TransLabel
            i18nKey="snugSeats"
            values={{ count: placeIds.length }}
            tOptions={{ postProcess: 'interval' }}
          />
        </Typography>
      </ListItemButton>
    </ListItem>
  );
};

export const VehicleNewSnugButton = () => {
  const [{ snug }, { setSnug }] = useSelectionManager();
  const [creatable, setCreatable] = useState(false);

  const { currentFloor } = useContext(ViewerContext);
  useEffect(() => {
    setCreatable(false);
  }, [currentFloor?.id]);

  const handleAddVehicleSnug = useCallback(() => {
    setSnug({ placeIds: [], editable: true });
    setCreatable(true);
  }, [setSnug]);

  const dispatch = useDispatch();
  const handleCreateVehicleSnug = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(async () => {
    if (snug && !snug.id) {
      Boolean(snug.placeIds.length) &&
        (await dispatch(createVehicleSnug(snug)).unwrap());
      setCreatable(false);
      setSnug(undefined);
    }
  }, [dispatch, setSnug, snug]);

  return !creatable || snug?.id ? (
    <ListItem disablePadding>
      <Button onClick={handleAddVehicleSnug} variant="text" icon="add">
        <TransButton i18nKey="snugAddNew" />
      </Button>
    </ListItem>
  ) : (
    <VehicleSnug
      placeIds={snug!.placeIds}
      onStopEditing={handleCreateVehicleSnug}
    />
  );
};

export const VehicleSnugs: FC<VehicleSnugsProps> = () => {
  const snugs = useSelector(vehicleSnugsSelector);
  const snugsPlaceIds = useMemo(
    () =>
      _uniq(
        snugs.reduce<Array<string>>(
          (acc, snug) => [...acc, ...snug.placeIds],
          []
        )
      ),
    [snugs]
  );

  const [{ relatedPlaceIds, snug }, { setRelatedPlaceIds, setSnug }] =
    useSelectionManager();

  const allSnugsVisible = useMemo(
    () =>
      snugsPlaceIds.length && relatedPlaceIds?.length === snugsPlaceIds.length,
    [relatedPlaceIds?.length, snugsPlaceIds.length]
  );
  const toggleAllSnugsVisible = useCallback(() => {
    setSnug(undefined);
    setRelatedPlaceIds(allSnugsVisible ? [] : snugsPlaceIds);
  }, [allSnugsVisible, setRelatedPlaceIds, setSnug, snugsPlaceIds]);

  const [snugIdToDeletion, setSnugIdToDeletion] =
    useState<Required<Snug>['id']>();
  const { currentFloor } = useContext(ViewerContext);
  useEffect(() => {
    setSnug(undefined);
    setSnugIdToDeletion(undefined);
  }, [currentFloor?.id, setSnug]);

  const modal = useModal();
  const handleOpenDeletionModal = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(
    (event) => {
      setSnugIdToDeletion(event.currentTarget.dataset.id);
      modal.onOpen();
    },
    [modal]
  );
  const handleCloseDeletionModal = useCallback(() => {
    setSnugIdToDeletion(undefined);
    modal.onClose();
  }, [modal]);

  const dispatch = useDispatch();
  const handleDelete = useCallback(async () => {
    if (snugIdToDeletion) await dispatch(deleteVehicleSnug(snugIdToDeletion));
    if (snug?.id === snugIdToDeletion) setSnug(undefined);
    handleCloseDeletionModal();
  }, [dispatch, handleCloseDeletionModal, setSnug, snug?.id, snugIdToDeletion]);

  const classes = useStyles();
  return (
    <>
      <ConfirmDeleteModal
        handleDelete={handleDelete}
        title={<TransModal i18nKey="snugDeletionTitle" />}
        description={<></>}
        isOpen={modal.open}
        onClose={handleCloseDeletionModal}
      />

      <Stack className={classes.title}>
        <Typography variant="subtitle">
          <TransLabel i18nKey="snugs" />
        </Typography>
        <Button
          onClick={toggleAllSnugsVisible}
          variant="text"
          size="small"
          icon={allSnugsVisible ? 'show' : 'hide'}
          disabled={!Boolean(snugsPlaceIds.length)}
        >
          <TransButton
            i18nKey={allSnugsVisible ? 'snugsAllHide' : 'snugsAllShow'}
          />
        </Button>
      </Stack>
      <Collapsible
        className={classes.collapse}
        title={
          <TransLabel
            i18nKey="snugsCount"
            values={{ count: snugs.length }}
            tOptions={{ postProcess: 'interval' }}
          />
        }
      >
        <List className={classes.list}>
          {snugs.map(({ id, ...props }) => (
            <VehicleSnug
              key={id}
              id={id}
              {...props}
              onDelete={handleOpenDeletionModal}
            />
          ))}
          <VehicleNewSnugButton />
        </List>
      </Collapsible>
    </>
  );
};
