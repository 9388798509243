import { selector } from 'store/utils';

export const inventoryClassesSelector = selector(
  (state) => state.inventoryClass.list
);
export const currentInventoryClassSelector = selector(
  (state) => state.inventoryClass.current
);
export const inventoryClassFilterSelector = selector(
  (state) => state.inventoryClass.filter
);
