import type { FC } from 'react';
import { Icon, Tooltip } from '@fleet/shared/mui';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface ControlTooltipProps {
  dotsSize?: 20 | 24;
  dotsClassName?: string;
}

const useStyles = makeStyles(
  (theme) => ({
    controlPopup: {
      borderRadius: 3,
      display: 'flex',
      flexDirection: 'column',

      '& .MuiButton-text': {
        padding: '4px',
        color: theme.palette.text.primary,
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap',

        '&:hover': {
          color: theme.palette.action.hoverText,
        },
      },
    },
  }),
  {
    name: 'ControlTooltip',
  }
);

export const ControlTooltip: FC<ControlTooltipProps> = ({
  dotsSize = 24,
  dotsClassName,
  children,
}) => {
  const classes = useStyles();
  return (
    <Tooltip
      theme="light"
      placement="bottom-end"
      delay={[300, 0]}
      content={<Box className={classes.controlPopup}>{children}</Box>}
    >
      <Icon
        className={dotsClassName}
        name="dots"
        height={dotsSize}
        width={dotsSize}
      />
    </Tooltip>
  );
};
