import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  CardHeader,
  ConditionField,
  DrawerForm,
  DrawerFormCloseButton,
  Field,
  FormControl,
  FormProvider,
  formSubmit,
  RadioGroupField,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { CardContent, Grid, Stack, Typography } from '@mui/material';
import { InventoryClass, InventoryClassPayload } from 'dto/inventoryClass';
import {
  createOrUpdateInventoryClass,
  getInventoryClass,
  getInventoryClasses,
  setInventoryClass,
} from 'features/inventoryClass/inventoryClassActions';
import {
  currentInventoryClassSelector,
  inventoryClassFilterSelector,
} from 'features/inventoryClass/inventoryClassSelectors';
import { TransTitle } from 'i18n/trans/title';
import { useAlert } from 'react-alert';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { TransLabel } from 'i18n/trans/label';
import { TransMessage } from 'i18n/trans/message';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  Properties,
  PropertiesProps,
} from 'components/propertiesPanel/Properties';
import { InventoryType } from 'dto/organization';
import { TransButton } from 'i18n/trans/button';

interface InventoryClassDetailsProps {}

export const InventoryClassDetails: FC<InventoryClassDetailsProps> = () => {
  const { action, id } = useParams<{
    action: 'create' | 'edit';
    id?: string;
  }>();
  const isEditing = useMemo(
    () => action === 'edit' && Boolean(id),
    [action, id]
  );
  const { state } = useLocation<{
    inventoryType: InventoryClass['inventoryType'];
  }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const currentInventoryClass = useSelector(currentInventoryClassSelector);
  const filter = useSelector(inventoryClassFilterSelector);

  const inventoryTypeOptions = useClassificationOptions(
    ClassificationGroup.INVENTORY_TYPE
  );
  const comfortLevelOptions = useClassificationOptions(
    ClassificationGroup.COMFORT_LEVEL
  );
  const serviceClassOptions = useClassificationOptions(
    ClassificationGroup.SERVICE_CLASS
  );
  const bedSubTypeOptions = useClassificationOptions(
    ClassificationGroup.BED_INVENTORY_SUB_TYPE
  );

  const splitsOptions = useMemo(
    () =>
      [...Array(3).keys()].map((value) => ({
        value,
        label: `${value}`,
      })),
    []
  );

  useEffect(() => {
    if (action === 'edit' && id) {
      dispatch(getInventoryClass(id));
    }

    return () => {
      dispatch(setInventoryClass());
    };
  }, [action, dispatch, id]);

  const closeDrawer = useCallback(() => {
    history.replace('/inventory-class');
  }, [history]);

  const onSubmit = useCallback(
    (values: InventoryClassPayload) =>
      formSubmit(async () => {
        const data = await dispatch(
          createOrUpdateInventoryClass(values)
        ).unwrap();

        alert.success(
          <TransMessage
            i18nKey={
              values.id ? 'inventoryClassUpdated' : 'inventoryClassCreated'
            }
          />
        );

        history.push(`/inventory-class/edit/${data.id}`);
        await dispatch(getInventoryClasses(filter));
      }),
    [dispatch, alert, history, filter]
  );

  const initialValues = useMemo(
    () => ({
      inventoryType: state?.inventoryType.id,
      isActive: false,
      isAssignmentEnabled: false,
      isSoldOnce: false,
      isDefault: false,
      propertyIds: [],
      ignoreSalesSequenceForPropertyIds: [],
      ...(currentInventoryClass && {
        ...currentInventoryClass,
        comfortLevel: currentInventoryClass.comfortLevel.id,
        serviceClass: currentInventoryClass.serviceClass.id,
        inventoryType: currentInventoryClass.inventoryType.id,
        inventorySubType: currentInventoryClass.inventorySubType?.id,
        propertyIds: currentInventoryClass.properties.map(({ id }) => id),
        ignoreSalesSequenceForPropertyIds:
          currentInventoryClass.ignoreSalesSequenceForProperties?.map(
            ({ id }) => id
          ),
      }),
    }),
    [currentInventoryClass, state]
  );

  const { form, handleSubmit, dirty, submitting } =
    useForm<InventoryClassPayload>({
      initialValues,
      onSubmit,
      subscription: { dirty: true, submitting: true, values: true },
    });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  const handlePropertiesChange = useCallback<PropertiesProps['onConfirm']>(
    ({ properties }) => {
      form.change(
        'propertyIds',
        properties?.map(({ id }) => id)
      );
    },
    [form]
  );

  const handleIgnoreSalesSequenceForPropertiesChange = useCallback<
    PropertiesProps['onConfirm']
  >(
    ({ properties }) => {
      form.change(
        'ignoreSalesSequenceForPropertyIds',
        properties?.map(({ id }) => id)
      );
    },
    [form]
  );

  return (
    <DrawerForm onClose={closeDrawer} open>
      <FormProvider form={form}>
        <CardHeader
          isLight
          title={
            <Typography variant="subtitle">
              {id ? (
                <TransTitle i18nKey="inventoryClass" />
              ) : (
                <TransTitle i18nKey="newInventoryClass" />
              )}
            </Typography>
          }
          action={<DrawerFormCloseButton onClick={closeDrawer} />}
        />
        <CardContent component="form" onSubmit={handleSubmit}>
          <Grid container columns={1} spacing={2}>
            <Grid item xs={1}>
              <Grid container columns={2} spacing={2}>
                <Grid item xs={1}>
                  <SelectField
                    name="inventoryType"
                    disabled
                    label={<TransLabel i18nKey="inventoryType" />}
                    options={inventoryTypeOptions}
                  />
                </Grid>
                <ConditionField
                  when="inventoryType"
                  is={[InventoryType.BED, InventoryType.COMPARTMENT]}
                >
                  <Grid item xs={1}>
                    <SelectField
                      name="inventorySubType"
                      label={<TransLabel i18nKey="inventorySubType" />}
                      options={bedSubTypeOptions}
                      required
                    />
                  </Grid>
                </ConditionField>
                <Grid item xs={1}>
                  <TextField
                    label={<TransLabel i18nKey="name" />}
                    name="name"
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    label={<TransLabel i18nKey="code" />}
                    name="code"
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    label={<TransLabel i18nKey="description" />}
                    name="description"
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    name="comfortLevel"
                    label={<TransLabel i18nKey="comfortClass" />}
                    options={comfortLevelOptions}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    name="serviceClass"
                    label={<TransLabel i18nKey="serviceLevel" />}
                    options={serviceClassOptions}
                    required
                  />
                </Grid>
                <ConditionField
                  when="inventoryType"
                  is={InventoryType.COMPARTMENT}
                >
                  <>
                    <Grid item xs={1}>
                      <TextField
                        name="minOccupancy"
                        label={<TransLabel i18nKey="minOccupancy" />}
                        type="number"
                        required
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <TextField
                        name="maxOccupancy"
                        label={<TransLabel i18nKey="maxOccupancy" />}
                        type="number"
                        required
                      />
                    </Grid>
                  </>
                </ConditionField>
                <ConditionField
                  when="inventoryType"
                  is={[InventoryType.BED, InventoryType.COMPARTMENT]}
                >
                  <Grid item xs={1}>
                    <RadioGroupField
                      name="isSoldOnce"
                      label={<TransLabel i18nKey="soldOnce" />}
                      options="BOOL_ONLY"
                      inline
                    />
                  </Grid>
                </ConditionField>
                <ConditionField when="inventoryType" is={InventoryType.SEAT}>
                  <Grid item xs={1}>
                    <SelectField
                      name="allowedNumberOfSplits"
                      label={<TransLabel i18nKey="splitsEnabled" />}
                      options={splitsOptions}
                      required
                    />
                  </Grid>
                </ConditionField>
                <Grid item xs={1}>
                  <RadioGroupField
                    name="isActive"
                    label={<TransLabel i18nKey="active" />}
                    options="BOOL_ONLY"
                    inline
                  />
                </Grid>
                <Grid item xs={1}>
                  <RadioGroupField
                    name="isDefault"
                    label={<TransLabel i18nKey="default" />}
                    options="BOOL_ONLY"
                    inline
                  />
                </Grid>
                <Field<InventoryClassPayload['inventoryType']>
                  name="inventoryType"
                  render={({ input: { value } }) => (
                    <Grid item xs={value === InventoryType.SEAT ? 0.5 : 1}>
                      <Field<InventoryClassPayload['propertyIds']>
                        name="propertyIds"
                        render={({ input: { value: propertyIds = [] } }) => (
                          <Properties
                            typeId={value}
                            onConfirm={handlePropertiesChange}
                            propertiesOnly
                            properties={propertyIds.map((id) => ({ id }))}
                            title={<TransTitle i18nKey="defaultProperties" />}
                          />
                        )}
                      />
                    </Grid>
                  )}
                />
                <ConditionField when="inventoryType" is={InventoryType.SEAT}>
                  <Field<InventoryClassPayload['inventoryType']>
                    name="inventoryType"
                    render={({ input: { value } }) => (
                      <Grid item xs={0.5}>
                        <Field<
                          InventoryClassPayload['ignoreSalesSequenceForPropertyIds']
                        >
                          name="ignoreSalesSequenceForPropertyIds"
                          render={({
                            input: {
                              value: ignoreSalesSequenceForPropertyIds = [],
                            },
                          }) => (
                            <Properties
                              typeId={value}
                              onConfirm={
                                handleIgnoreSalesSequenceForPropertiesChange
                              }
                              propertiesOnly
                              properties={ignoreSalesSequenceForPropertyIds.map(
                                (id) => ({ id })
                              )}
                              title={
                                <TransTitle i18nKey="salesSequenceToBeIgnoredForProperties" />
                              }
                            />
                          )}
                        />
                      </Grid>
                    )}
                  />
                </ConditionField>
                <ConditionField when="inventoryType" is={InventoryType.BED}>
                  <Grid item xs={1}>
                    <RadioGroupField
                      name="isAssignmentEnabled"
                      label={<TransLabel i18nKey="placeAssignmentEnabled" />}
                      options="BOOL_ONLY"
                      inline
                    />
                  </Grid>
                </ConditionField>
              </Grid>
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" flexWrap="nowrap">
                <FormControl label="&nbsp;">
                  <Button
                    variant="text"
                    sx={{ whiteSpace: 'nowrap' }}
                    {...(!isEditing && { onClick: closeDrawer })}
                    {...(isEditing && {
                      onClick: handleReset,
                      disabled: !dirty,
                    })}
                  >
                    <TransButton
                      i18nKey={isEditing ? 'resetChanges' : 'cancel'}
                    />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button
                    variant="contained"
                    icon={isEditing ? 'check' : 'plus'}
                    type="submit"
                    disabled={!dirty || submitting}
                  >
                    <TransButton i18nKey={isEditing ? 'save' : 'create'} />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </FormProvider>
    </DrawerForm>
  );
};
