import { FC, useCallback, useContext } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import classNames from 'classnames';
import { Dropdown, Icon, ToggleButtonGroup } from '@fleet/shared/mui';
import { useDispatch } from 'store/utils';
import { setActiveFloorIdx } from 'features/floor/floorActions';
import { TabsContext } from 'components/common/tabs/Tabs';
import { ViewerContext } from '@fleet/widget/components/viewer/Context';
import { getVehicleSnugs } from 'features/vehicle/vehicleSnugActions';
import Box from '@mui/material/Box';
import { TransLabel } from 'i18n/trans/label';
import { TransButton } from 'i18n/trans/button';

type ViewerControlsProps = {
  actions?: {
    rename: (name: string) => void;
    duplicate: () => void;
    delete: () => void;
  };
};
export const ViewerControls: FC<ViewerControlsProps> = ({
  actions,
  children,
}) => {
  const { scale, setScale, vehicle, floors, currentFloor } =
    useContext(ViewerContext);
  const { number = 0 } = currentFloor ?? {};
  const activeFloorIdx = Math.max(0, number - 1);

  const dispatch = useDispatch();
  const { closeCurrentTab } = useContext(TabsContext);
  const scaleLimiter = (val: number) => (val >= 0.5 && val <= 2 ? val : scale);
  const increaseScale = () => setScale(scaleLimiter(scale + 0.1));
  const decreaseScale = () => setScale(scaleLimiter(scale - 0.1));
  const scaleToFit = () => setScale();
  const handleScaleTo = useCallback(
    (val) => () => {
      setScale(val);
    },
    [setScale]
  );

  useHotkeys('=', increaseScale, [scale]);
  useHotkeys('-', decreaseScale, [scale]);
  useHotkeys('shift+1', scaleToFit);
  useHotkeys('shift+0', handleScaleTo(1));

  const setActiveFloor = useCallback(
    (payload: string) => {
      dispatch(setActiveFloorIdx(+payload));
      actions && dispatch(getVehicleSnugs());
    },
    [actions, dispatch]
  );

  return (
    <div className="controls viewer-controls">
      <ToggleButtonGroup
        className={classNames('floor-toggle', { hidden: floors.length < 2 })}
        color="secondary"
        options={floors.map((floor, idx) => ({
          label: (
            <TransLabel i18nKey="floorNumber" values={{ number: idx + 1 }} />
          ),
          value: String(idx),
        }))}
        value={String(activeFloorIdx)}
        onChange={setActiveFloor}
      />
      {actions ? (
        <Dropdown
          key={vehicle?.name} // temp hack to fix BR-39173 without @fleet/shared update
          className="name-controls"
          label={vehicle?.name ?? ''}
          onLabelChange={actions.rename}
          options={[
            {
              label: <TransButton i18nKey="duplicate" />,
              onClick: actions.duplicate,
            },
            {
              label: <TransButton i18nKey="delete" />,
              onClick: async () => {
                await actions.delete();
                closeCurrentTab();
              },
            },
          ]}
        />
      ) : (
        children
      )}

      <Dropdown
        className="zoom-controls"
        label={`${Math.floor(scale * 100)} %`}
        options={[
          {
            label: <TransLabel i18nKey="zoomIn" />,
            onClick: increaseScale,
            content: '+',
          },
          {
            label: <TransLabel i18nKey="zoomOut" />,
            onClick: decreaseScale,
            content: '-',
          },
          {
            label: <TransLabel i18nKey="zoomToFit" />,
            onClick: scaleToFit,
            content: (
              <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <Icon name="shift" width={12} height={14} />1
              </Box>
            ),
          },
          {
            label: (
              <TransLabel i18nKey="zoomToPercent" values={{ percent: 50 }} />
            ),
            onClick: handleScaleTo(0.5),
          },
          {
            label: (
              <TransLabel i18nKey="zoomToPercent" values={{ percent: 100 }} />
            ),
            onClick: handleScaleTo(1),
            content: (
              <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <Icon name="shift" width={12} height={14} />0
              </Box>
            ),
          },
          {
            label: (
              <TransLabel i18nKey="zoomToPercent" values={{ percent: 200 }} />
            ),
            onClick: handleScaleTo(2),
          },
        ]}
      />
    </div>
  );
};
