import type { FC } from 'react';
import { TransLabel } from 'i18n/trans/label';
import { ElementDetailsRow } from 'routes/elements/ElementDetailsRow';
import {
  EditorField,
  FormSpy,
  SelectField,
  TextField,
  useFormContext,
} from '@fleet/shared/form';
import { type SelectOption, Icon } from '@fleet/shared';
import { useFieldArray } from '@fleet/shared/form/hooks/useFieldArray';
import { Fragment, useCallback, useMemo } from 'react';
import { ElementPayload, Localization } from 'dto/element';
import { AvailableLocalization } from 'dto/classification';
import { useSelector } from 'react-redux';
import { classifiersCultureMapSelector } from 'features/classification/classificationSelectors';
import { Button } from '@mui/material';
import { TransButton } from 'i18n/trans/button';

interface ElementDetailTranslationsProps {
  availableLocalizations: Array<AvailableLocalization>;
}

const defaultLocalization: Localization = {
  languageId: '',
  name: '',
  description: '',
};

export const ElementDetailTranslations: FC<ElementDetailTranslationsProps> = ({
  availableLocalizations,
}) => {
  const form = useFormContext<ElementPayload>();
  const { fields } = useFieldArray<
    'localizations',
    Localization,
    ElementPayload
  >('localizations', {
    subscription: { value: true, invalid: true },
    form,
  });

  const localizations: Array<Localization> = useMemo(
    () => fields.value ?? [],
    [fields.value]
  );
  const usedLocalizations = localizations.map(({ languageId }) => languageId);

  const classifiersCultureMap = useSelector(classifiersCultureMapSelector);
  const localizationOptions = useMemo(
    () =>
      localizations.reduce((acc: SelectOption[][], field, index: number) => {
        const value = usedLocalizations[index];
        const currentUsedLocalizations = usedLocalizations.filter(
          (languageId) => languageId !== value
        );
        if (availableLocalizations) {
          acc[index] = availableLocalizations
            .filter((c) => !currentUsedLocalizations.includes(c.languageId))
            .map(({ languageId }) => ({
              value: languageId,
              label: classifiersCultureMap.get(languageId),
            }));
        } else {
          acc[index] = [];
        }
        return acc;
      }, []),
    [
      localizations,
      usedLocalizations,
      availableLocalizations,
      classifiersCultureMap,
    ]
  );

  const handleAddLocalization = useCallback(async () => {
    fields.push(defaultLocalization);
  }, [fields]);

  if (!Boolean(availableLocalizations.length)) return null;
  return (
    <>
      <ElementDetailsRow columns={[<TransLabel i18nKey="translations" />]} />
      {fields.map((_, index) => (
        <Fragment key={index}>
          {index === 0 && <ElementDetailsRow columns={[null]} />}
          <ElementDetailsRow
            columns={[
              <TransLabel i18nKey="language" />,
              <SelectField
                name={`localizations.${index}.languageId`}
                options={localizationOptions[index]}
                required
              />,
            ]}
          />
          <ElementDetailsRow
            columns={[
              <TransLabel i18nKey="name" />,
              <TextField name={`localizations.${index}.name`} required />,
            ]}
          />
          <ElementDetailsRow columns={[<TransLabel i18nKey="description" />]} />
          <ElementDetailsRow
            columns={[
              <EditorField name={`localizations.${index}.description`} />,
            ]}
          />
          <ElementDetailsRow columns={[null]} />
        </Fragment>
      ))}
      {localizations.length < availableLocalizations.length && (
        <ElementDetailsRow
          columns={[
            <FormSpy subscription={{ invalid: true }}>
              {({ invalid }) => (
                <Button
                  variant="text"
                  color="primary"
                  size="large"
                  startIcon={<Icon name="plus" />}
                  onClick={handleAddLocalization}
                  disabled={invalid}
                >
                  <TransButton i18nKey="addNewTranslation" />
                </Button>
              )}
            </FormSpy>,
          ]}
        />
      )}
    </>
  );
};
