import { type FC, useCallback } from 'react';
import { Switch, Route, type RouteComponentProps } from 'react-router-dom';
import { CompositionsTable } from 'routes/composition/CompositionsTable';
import { CompositionConstruct } from 'routes/composition/CompositionConstruct';
import { Tabs } from 'components/common/tabs/Tabs';
import { useSelector } from 'store/utils';
import { compositionConstructSelector } from 'features/composition/compositionSelectors';
import { clearCurrentComposition } from 'features/composition/compositionActions';
import { useDispatch } from 'react-redux';

interface VehicleCompositionsProps extends RouteComponentProps {}

export const Compositions: FC<VehicleCompositionsProps> = ({ match }) => {
  const { path } = match;
  const compositionConstruct = useSelector(compositionConstructSelector)!;
  const dispatch = useDispatch();
  const onCurrentTabClose = useCallback(
    () => dispatch(clearCurrentComposition()),
    [dispatch]
  );

  return (
    <>
      <Tabs
        basePath="/compositions"
        currentEntity={compositionConstruct}
        onCurrentTabClose={onCurrentTabClose}
      />
      <Switch>
        <Route exact path={`${path}/all`} component={CompositionsTable} />
        <Route
          path={`${path}/:compositionId?`}
          component={CompositionConstruct}
        />
      </Switch>
    </>
  );
};
