import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import type { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'store/utils';
import { PalettePanel } from 'components/palettePanel/PalettePanel';
import { DesignerPropertiesPanel } from 'routes/designer/PropertiesPanel';
import {
  createVehicle,
  deleteVehicle,
  duplicateVehicle,
  readVehicle,
  setCurrentVehicle,
  updateVehicle,
} from 'features/vehicle/vehicleActions';
import {
  setActiveFloorIdx,
  updateFloorElements,
} from 'features/floor/floorActions';
import {
  activeFloorSelector,
  floorListSelector,
} from 'features/floor/floorSelectors';
import {
  currentVehicleSelector,
  vehicleSnugsSelector,
} from 'features/vehicle/vehicleSelector';
import { ViewerProvider } from '@fleet/widget/components/viewer/Context';
import { FloorElement } from '@fleet/widget/dto/floor';
import { Vehicle } from '@fleet/widget/dto/vehicle';
import { Loader } from '@fleet/shared/mui';
import { DesignerViewer } from 'routes/designer/Viewer';
import _values from 'lodash/values';
import qs from 'qs';
import { classifiersSelector } from 'features/classification/classificationSelectors';
import { dataLoadingSelector } from 'features/common/commonSelectors';
import {
  SelectionManagerProvider,
  useSelectionManager,
} from 'routes/designer/SelectionManager';
import _isNil from 'lodash/isNil';
import { Layout } from '@fleet/shared';

type DesignerProps = RouteComponentProps<
  { vehicleId: string },
  {},
  { vehicleTypeId?: string }
>;

export const DesignerComponent: FC<DesignerProps> = ({ location, match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const vehicleId = Number(match.params.vehicleId);
  const classifiers = useSelector(classifiersSelector);
  const vehicleTypeIdFromUrl = (qs.parse(location.search.substring(1))
    .vehicleTypeId || '') as string;
  const createVehicleTypeId =
    vehicleTypeIdFromUrl || classifiers.VEHICLE_TYPE[0]?.id;
  const currentVehicle = useSelector(currentVehicleSelector);
  const floors = useSelector(floorListSelector);
  const currentFloor = useSelector(activeFloorSelector);
  const designerLoading = useSelector(dataLoadingSelector);
  const initialLoading = useMemo(
    () => designerLoading && vehicleId !== currentVehicle?.id,
    [designerLoading, vehicleId, currentVehicle]
  );
  const [{ relatedPlaceIds, nodes: selectedNodes, snug }, selectionManager] =
    useSelectionManager();
  const { setRelatedPlaceIds } = selectionManager;

  const snugs = useSelector(vehicleSnugsSelector);
  useEffect(() => {
    if (!selectedNodes.length && snug && !snug.editable)
      setRelatedPlaceIds(snug.placeIds);
    else setRelatedPlaceIds([]);
  }, [selectedNodes.length, setRelatedPlaceIds, snug, snugs]);

  const updateSelection = useCallback(
    async (
      updatePayload: Partial<FloorElement>,
      ignoreSelected = false
    ): Promise<void> => {
      const [selected, ...seats] = selectedNodes;
      const { length } = selectedNodes;

      if (
        length > 1 &&
        (ignoreSelected || selected.category !== 'compartment')
      ) {
        const updatedSelectionMap: Record<string, FloorElement> = (
          ignoreSelected ? seats : selectedNodes
        ).reduce(
          (selectionMap, cur) => ({
            ...selectionMap,
            [cur.id!]: { ...cur, ...updatePayload },
          }),
          {}
        );
        await dispatch(updateFloorElements(_values(updatedSelectionMap)));
        !ignoreSelected &&
          selectionManager.setNodes(
            selectedNodes.map(({ id }) => updatedSelectionMap[id!])
          );
        return;
      }

      if (selected) {
        const updatedSelection = { ...selected, ...updatePayload };
        await dispatch(updateFloorElements([updatedSelection]));

        selectionManager.setNodes([
          ...(selectedNodes[0]?.id === updatedSelection.id
            ? [updatedSelection]
            : selectedNodes),
          ...seats,
        ]);
      }
    },
    [dispatch, selectedNodes, selectionManager]
  );

  const updateVehicleData = useCallback(
    (updates: Partial<Vehicle>): void => {
      dispatch(updateVehicle({ ...currentVehicle, ...updates }));
    },
    [currentVehicle, dispatch]
  );

  const actions = useMemo(
    () => ({
      rename: (name: string) => updateVehicleData({ name }),
      duplicate: async () => {
        const duplicateId = await dispatch(
          duplicateVehicle(vehicleId)
        ).unwrap();
        history.push(`/vehicles/${duplicateId}`);
      },
      delete: async () => {
        await dispatch(deleteVehicle(vehicleId));
        history.push('/vehicles/all');
      },
    }),
    [dispatch, history, updateVehicleData, vehicleId]
  );

  useEffect(() => {
    if (vehicleId) {
      dispatch(setActiveFloorIdx(0));
      dispatch(readVehicle(vehicleId));
    } else if (createVehicleTypeId) {
      (async () => {
        const { payload } = await dispatch(createVehicle(createVehicleTypeId));
        history.replace(`/vehicles/${!_isNil(payload) ? payload : 'all'}`);
      })();
    }

    return () => {
      dispatch(setCurrentVehicle());
    };
  }, [vehicleId, createVehicleTypeId, dispatch, history]);

  if (!designerLoading && !currentVehicle) return null;
  return (
    <>
      <Loader
        active={designerLoading}
        size="fullscreen"
        hideSpinner={!initialLoading}
      />
      <>
        <ViewerProvider
          vehicle={currentVehicle}
          floors={floors}
          currentFloor={currentFloor}
          relatedPlaceIds={relatedPlaceIds}
        >
          <Layout width={[320]} className="designer">
            <PalettePanel />
            <div className="designer-view">
              <DesignerViewer
                updateSelection={updateSelection}
                actions={actions}
              />
            </div>
            <DesignerPropertiesPanel
              initialLoading={initialLoading}
              updateSelection={updateSelection}
              updateVehicleData={updateVehicleData}
            />
          </Layout>
        </ViewerProvider>
      </>
    </>
  );
};

export const Designer: FC<DesignerProps> = (props) => (
  <SelectionManagerProvider>
    <DesignerComponent {...props} />
  </SelectionManagerProvider>
);
