import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { ManageComposition } from 'components/manage/ManageComposition';
import { TransTitle } from 'i18n/trans/title';
import qs from 'qs';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import {
  addVehicleToTripComposition,
  getTrip,
  insetVehicleToTripComposition,
  removeTripCompositionVehicle,
  replaceTripCompositionVehicle,
  setTrip,
  updateTripComposition,
  updateTripCompositionElements,
} from 'features/trip/tripActions';
import { tripCurrentSelector } from 'features/trip/tripSelectors';
import {
  TripSearchTable,
  TripSearchTableProps,
} from 'components/trip/TripSearchTable';
import { VehiclesPanel } from 'components/palettePanel/VehiclesPanel';
import { TripDropArea } from 'components/trip/TripDropArea';
import { CompositionManage } from 'components/compositionManage/CompositionManage';
import {
  clearCurrentComposition,
  getAssignedComposition,
  setCompositionProcessId,
} from 'features/composition/compositionActions';
import { CompositionVehicle } from 'routes/composition/CompositionVehicle';
import { compositionConstructSelector } from 'features/composition/compositionSelectors';
import { setCurrentVehicle } from 'features/vehicle/vehicleActions';
import { CompositionConstructVehicleDto } from '@fleet/widget/dto/composition';
import { TransButton } from 'i18n/trans/button';
import { Modal, usePrevious } from '@fleet/shared';
import { t } from 'i18next';
import { setLoadingKey } from 'features/common/commonActions';
import { COMPOSITION_VEHICLE_LOADING } from 'routes/composition/CompositionViewer';
import { CompositionElements } from 'dto/composition';
import {
  TripEditPayload,
  TripVehicleAddPayload,
  TripVehicleReplacePayload,
} from 'dto/trip';
/**
 * BR-44961 VM: UI freezes for 15 minutes when blocking seats on trip where are transactions as transaction move fails
 * It happens because the new world doesn`t support transaction move (moving passengers to other seats) and then UI freezes
 * so because of that Marek told that we just had to disable this transaction move logic from UI side
 */
// import { ProcessProgress } from 'routes/trip/ProcessProgress';

const useStyles = makeStyles(
  () => ({
    root: {},
    table: {
      flex: 'unset',
    },
  }),
  {
    name: 'TipComposition',
  }
);

interface TipCompositionProps
  extends Pick<TripSearchTableProps, 'controlsAccessor'> {
  refreshComposition?: boolean;
}

export const TripComposition: FC<TipCompositionProps> = ({
  controlsAccessor,
  refreshComposition,
}) => {
  const { tripId: id } = useParams<{ tripId: string }>();
  const { compositionId } = qs.parse<{
    compositionId: string;
  }>(location.search, {
    ignoreQueryPrefix: true,
  });

  const trip = useSelector(tripCurrentSelector);
  const data = useMemo(() => (trip ? [trip] : []), [trip]);

  const compositionConstruct = useSelector(compositionConstructSelector);
  const compositionVehicles = useMemo(
    () => compositionConstruct?.compositionVehicles || [],
    [compositionConstruct?.compositionVehicles]
  );
  const currentVehicleNumber = useMemo(
    () => compositionConstruct?.vehicle?.orderNumber,
    [compositionConstruct?.vehicle?.orderNumber]
  );

  const dispatch = useDispatch();
  const fetchTripComposition = useCallback(
    async (orderNumber?: number) => {
      dispatch(setLoadingKey(COMPOSITION_VEHICLE_LOADING));
      try {
        await Promise.all([
          dispatch(getTrip(id)).unwrap(),
          dispatch(
            getAssignedComposition({
              compositionId,
              type: 'trip',
              orderNumber,
            })
          ).unwrap(),
        ]);
      } finally {
        dispatch(setLoadingKey(null));
      }
    },
    [compositionId, dispatch, id]
  );

  useEffect(() => {
    fetchTripComposition().then();
  }, [fetchTripComposition]);
  useEffect(
    () => () => {
      dispatch(setTrip());
      dispatch(clearCurrentComposition());
      dispatch(setCurrentVehicle());
    },
    [dispatch]
  );
  const prevCompositionId = usePrevious(compositionId);
  useEffect(() => {
    /***
      Since component may remount, re-fetch data only for same compositionId
      TripEditModal.tsx —> history.replace({ search: `compositionId=${newCompositionId}` })
    */
    refreshComposition &&
      prevCompositionId !== compositionId &&
      fetchTripComposition().then();
  }, [
    compositionId,
    fetchTripComposition,
    prevCompositionId,
    refreshComposition,
  ]);

  const addVehicle = useCallback(
    async ({ vehicleId, orderNumber, number }: TripVehicleAddPayload) => {
      dispatch(setLoadingKey(COMPOSITION_VEHICLE_LOADING));
      try {
        if (
          orderNumber > compositionConstruct!.compositionVehiclesData!.length
        ) {
          await dispatch(
            addVehicleToTripComposition({
              tripRelationId: +compositionId,
              vehicleId,
            })
          );
          await fetchTripComposition(currentVehicleNumber);
        } else {
          const { processId } = await dispatch(
            insetVehicleToTripComposition({
              tripRelationId: +compositionId,
              vehicleId,
              orderNumber,
              number,
            })
          ).unwrap();
          await fetchTripComposition(currentVehicleNumber); // probably we should remove once BR-44961 resolves
          dispatch(setCompositionProcessId(processId));
        }
      } catch (e) {
        return Promise.reject(e);
      } finally {
        dispatch(setLoadingKey(null));
      }
    },
    [
      compositionConstruct,
      compositionId,
      currentVehicleNumber,
      dispatch,
      fetchTripComposition,
    ]
  );

  const replaceVehicle = useCallback(
    async (payload: TripVehicleReplacePayload) => {
      dispatch(setLoadingKey(COMPOSITION_VEHICLE_LOADING));
      try {
        const { processId } = await dispatch(
          replaceTripCompositionVehicle({
            tripRelationId: +compositionId,
            ...payload,
          })
        ).unwrap();
        await fetchTripComposition(currentVehicleNumber); // probably we should remove once BR-44961 resolves
        dispatch(setCompositionProcessId(processId));
      } catch (e) {
        return Promise.reject(e);
      } finally {
        dispatch(setLoadingKey(null));
      }
    },
    [compositionId, currentVehicleNumber, dispatch, fetchTripComposition]
  );

  const [vehicleToDelete, setDeleteVehicle] =
    useState<CompositionConstructVehicleDto | null>(null);
  const deleteVehicle = useCallback(async () => {
    const { id } = vehicleToDelete!;
    const { processId } = await dispatch(
      removeTripCompositionVehicle({
        compositionId: +compositionId,
        compositionVehicleId: id,
      })
    ).unwrap();
    dispatch(setCompositionProcessId(processId));
    await fetchTripComposition(currentVehicleNumber);
  }, [
    compositionId,
    currentVehicleNumber,
    dispatch,
    fetchTripComposition,
    vehicleToDelete,
  ]);

  const tripCompositions = useMemo(() => {
    return trip
      ? trip.vehicleCompositions.map(({ tripVehicleCompositionId, name }) => ({
          id: tripVehicleCompositionId,
          name,
        }))
      : [];
  }, [trip]);

  const onVehicleChange = useCallback(
    (orderNumber: number) => {
      dispatch(
        setCurrentVehicle(
          compositionConstruct!.compositionVehiclesData![orderNumber - 1]!
        )
      );
    },
    [compositionConstruct, dispatch]
  );
  const updateTripCompositionData = useCallback(
    async (composition: TripEditPayload) => {
      await dispatch(updateTripComposition(composition));
      await fetchTripComposition(currentVehicleNumber);
    },
    [currentVehicleNumber, dispatch, fetchTripComposition]
  );

  const vehicleTabsActions = useMemo(() => {
    return {
      onVehicleChange: onVehicleChange,
      updateComposition: updateTripCompositionData,
      deleteVehicle: (vehicleId: number) => {
        setDeleteVehicle(
          compositionVehicles!.find(({ id }) => id === vehicleId)!
        );
      },
    };
  }, [compositionVehicles, onVehicleChange, updateTripCompositionData]);

  const vehicleTabsRender = useCallback(
    (props) => (
      <CompositionVehicle
        key={props.vehicle.id}
        {...props}
        actions={vehicleTabsActions}
        transportationTypeId={compositionConstruct?.transportationTypeId}
      />
    ),
    [compositionConstruct?.transportationTypeId, vehicleTabsActions]
  );

  const updateVehicleElements = useCallback(
    async (elementPayload: CompositionElements) => {
      try {
        const { processId } = await dispatch(
          updateTripCompositionElements(elementPayload)
        ).unwrap();
        dispatch(setCompositionProcessId(processId));
        await fetchTripComposition(currentVehicleNumber);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    [currentVehicleNumber, dispatch, fetchTripComposition]
  );

  const classes = useStyles();
  return (
    <ManageComposition
      className={classes.root}
      title={<TransTitle i18nKey="manageCompositionForTrip" />}
      table={
        <TripSearchTable
          className={classes.table}
          data={data}
          {...(compositionConstruct?.isActive && { controlsAccessor })}
        />
      }
    >
      {/*<ProcessProgress onComplete={fetchTripComposition} />*/}
      <CompositionManage
        palettePanel={<VehiclesPanel vehicleTypeId="VEHICLE_TYPE.CARRIAGE" />}
        dropArea={
          <TripDropArea
            onAddVehicle={addVehicle}
            onReplaceVehicle={replaceVehicle}
          />
        }
        compositionsList={tripCompositions}
        vehicleTabsRenderFn={vehicleTabsRender}
        updateTripComposition={updateTripCompositionData}
        updateVehicleElements={updateVehicleElements}
      />
      {vehicleToDelete && (
        <Modal
          open
          title={<TransTitle i18nKey="deleteConfirmation" />}
          message={
            t('message.areYouSureYouWantToRemoveEntity', {
              defaultValue: 'Are you sure you want to remove the {{entity}}?',
              entity: t('label.vehicle', 'Vehicle').toLowerCase(),
            })!
          }
          actionButton={{
            className: 'delete',
            label: <TransButton i18nKey="delete" />,
            onClick: deleteVehicle,
          }}
          onClose={setDeleteVehicle.bind(null, null)}
        />
      )}
    </ManageComposition>
  );
};
