import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { useHistory } from 'react-router-dom';
import { Table } from 'components/common/table/Table';
import { ControlTooltip } from 'components/common/table/ControlTooltip';
import { Button, Icon, Modal } from '@fleet/shared/mui';
import {
  deleteVehicle,
  duplicateVehicle,
  getVehicles,
  setSortBy,
} from 'features/vehicle/vehicleActions';
import { SearchTable, SortBy } from 'components/search/SearchTable';
import {
  vehicleListSelector,
  vehiclesSortBySelector,
} from 'features/vehicle/vehicleSelector';
import { formatDate } from '@fleet/shared/utils/date';
import { Column, Row } from 'react-table';
import { VehicleListItem } from 'dto/vehicle';
import { TabsContext } from 'components/common/tabs/Tabs';
import { classifiersSelector } from 'features/classification/classificationSelectors';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { TransLabel } from 'i18n/trans/label';
import { useTranslation } from 'react-i18next';
import { TransTitle } from 'i18n/trans/title';
import { TransMessage } from 'i18n/trans/message';
import { Classifier } from '@fleet/shared/dto/classifier';
import { TransButton } from 'i18n/trans/button';
import { Link } from 'react-router-dom';
import {
  Layout,
  Loadable,
  SearchResult,
  tableRowClickEventWrapper,
} from '@fleet/shared';
import { vehiclesLoadingSelector } from 'features/loading/loadingSelectors';

const VehicleProfiles = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const list = useSelector(vehicleListSelector);
  const sortBy = useSelector(vehiclesSortBySelector);
  const loading = useSelector(vehiclesLoadingSelector);
  const businessEntityId = useSelector(currentBusinessEntityIdSelector);
  const classifiers = useSelector(classifiersSelector);
  const dispatch = useDispatch();
  const dateFormatter = useCallback(({ value }) => formatDate(value), []);
  const { tabs, updateTabs } = useContext(TabsContext);

  useEffect(() => {
    dispatch(getVehicles());
  }, [dispatch, businessEntityId]);

  const setVehiclesSortBy = useCallback(
    (payload: SortBy) => dispatch(setSortBy(payload)),
    [dispatch]
  );
  const searchVehicles = useCallback(
    (e) => dispatch(getVehicles({ searchString: e.target.value })),
    [dispatch]
  );

  const [vehicleToDelete, setVehicleToDelete] =
    useState<Classifier<number> | null>(null);

  const deleteVehicleHandler = useCallback(() => {
    const vehicleId = vehicleToDelete!.id;
    dispatch(deleteVehicle(vehicleId));
    updateTabs(tabs.filter(({ id }) => id !== vehicleId));
  }, [dispatch, tabs, updateTabs, vehicleToDelete]);

  const link = useCallback(
    (row: Row<VehicleListItem>) => `/vehicles/${row.original.id}`,
    []
  );

  const columns: Array<Column<VehicleListItem>> = useMemo(() => {
    return [
      {
        accessor: 'name',
        Header: <TransLabel i18nKey="name" />,
        Cell: ({ row, value }) => (
          <Link to={link(row)} onClick={tableRowClickEventWrapper}>
            {value}
          </Link>
        ),
      },
      { Header: <TransLabel i18nKey="code" />, accessor: 'code' },
      { Header: <TransLabel i18nKey="type" />, accessor: 'type' },
      { Header: <TransLabel i18nKey="owner" />, accessor: 'owner' },
      {
        Header: <TransLabel i18nKey="lastModified" />,
        accessor: 'lastModificationDateTime',
        id: 'modified',
        Cell: dateFormatter,
      },
      {
        Header: <TransLabel i18nKey="created" />,
        accessor: 'createdOnDateTime',
        id: 'created',
        Cell: dateFormatter,
      },
      {
        id: 'delete',
        accessor: (vehicle) => (
          <ControlTooltip>
            <Button
              variant="text"
              startIcon={<Icon name="clone" />}
              onClick={(event) =>
                tableRowClickEventWrapper(event, async () => {
                  const { payload } = await dispatch(
                    duplicateVehicle(vehicle.id)
                  );

                  history.push(`/vehicles/${payload}`);
                })
              }
              label={<TransButton i18nKey="duplicate" />}
            />
            <Button
              variant="text"
              startIcon={<Icon name="delete" />}
              onClick={(event) =>
                tableRowClickEventWrapper(event, () =>
                  setVehicleToDelete(vehicle)
                )
              }
              label={<TransButton i18nKey="delete" />}
            />
          </ControlTooltip>
        ),
      },
    ];
  }, [dateFormatter, dispatch, history, link]);

  return (
    <Layout>
      <>
        {vehicleToDelete && (
          <Modal
            open
            title={<TransTitle i18nKey="deleteConfirmation" />}
            message={
              t('message.deleteContent', {
                defaultValue: "Delete {{name}}? You can't undo this.",
                name: vehicleToDelete.name,
              })!
            }
            actionButton={{
              className: 'delete',
              label: <TransButton i18nKey="delete" />,
              onClick: deleteVehicleHandler,
            }}
            onClose={() => setVehicleToDelete(null)}
          />
        )}
        <SearchTable
          title={<TransTitle i18nKey="designVehicle" />}
          sortBy={sortBy}
          setSortBy={setVehiclesSortBy}
          action={classifiers.VEHICLE_TYPE.map(({ id, name }) => (
            <Button
              key={id}
              color="yellow"
              variant="text"
              label={
                <TransButton
                  i18nKey="newVehicleName"
                  values={{ vehicleName: name }}
                />
              }
              onClick={() => history.push(`/vehicles?vehicleTypeId=${id}`)}
              startIcon={<Icon name="plus" />}
            />
          ))}
          search={
            Boolean(list.length) && {
              value: '',
              onBlur: searchVehicles,
            }
          }
        >
          <Loadable loading={loading}>
            <SearchResult
              loaded
              loading={loading}
              results={Boolean(list.length)}
              message={<TransMessage i18nKey="vehiclesEmpty" />}
            >
              <Table<VehicleListItem>
                sortBy={[sortBy]}
                columns={columns}
                data={list}
                onRowClick={(row) => link(row)}
              />
            </SearchResult>
          </Loadable>
        </SearchTable>
      </>
    </Layout>
  );
};

export default VehicleProfiles;
