import React, { FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'store/utils';
import {
  clearCurrentComposition,
  createComposition,
  getComposition,
} from 'features/composition/compositionActions';
import { CompositionProperties } from 'routes/composition/CompositionProperties';
import { CompositionViewer } from 'routes/composition/CompositionViewer';
import {
  readVehicle,
  setCurrentVehicle,
} from 'features/vehicle/vehicleActions';
import { Loader } from '@fleet/shared/mui';
import { VehiclesPanel } from 'components/palettePanel/VehiclesPanel';
import { compositionConstructSelector } from 'features/composition/compositionSelectors';
import { Layout } from '@fleet/shared';

interface VehicleCompositionsViewProps
  extends RouteComponentProps<{ compositionId: string }> {}

export const CompositionConstruct: FC<VehicleCompositionsViewProps> = ({
  match,
  history,
}) => {
  const { compositionId } = match.params;
  const [loading, setLoading] = useState<boolean>(true);
  const compositionConstruct = useSelector(compositionConstructSelector);
  const isTrainComposition = useMemo(
    () =>
      compositionConstruct?.transportationTypeId ===
      'LINE_TRANSPORTATION_TYPE.TRAIN',
    [compositionConstruct?.transportationTypeId]
  );
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      try {
        if (compositionId) {
          setLoading(true);
          const { vehicle } = await dispatch(
            getComposition(compositionId)
          ).unwrap();
          if (vehicle) {
            await dispatch(readVehicle(vehicle.vehicleId));
          }
          setLoading(false);
        } else {
          const id = await dispatch(createComposition()).unwrap();
          history.replace(`/compositions/${id}`);
        }
      } catch (e) {
        setLoading(false);
      }
    })();

    return () => {
      dispatch(clearCurrentComposition());
      dispatch(setCurrentVehicle());
    };
  }, [dispatch, compositionId, history]);

  if (!loading && !compositionConstruct) return null;

  return (
    <>
      {loading ? (
        <Loader active size="container" />
      ) : (
        <Layout width={[isTrainComposition ? 320 : 0, 0, 320]}>
          {isTrainComposition ? (
            <VehiclesPanel vehicleTypeId="VEHICLE_TYPE.CARRIAGE" />
          ) : (
            <span />
          )}
          <CompositionViewer />
          <CompositionProperties />
        </Layout>
      )}
    </>
  );
};
