import { useCallback, useEffect, useMemo, useState, FocusEvent } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import {
  deleteComposition,
  duplicateComposition,
  getCompositions,
  setCompositionsTransportType,
} from 'features/composition/compositionActions';
import { SearchTable, SortBy } from 'components/search/SearchTable';
import { Column, Row } from 'react-table';
import { Table } from 'components/common/table/Table';
import { ControlTooltip } from 'components/common/table/ControlTooltip';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Modal, Select } from '@fleet/shared/mui';
import { Link } from 'react-router-dom';
import {
  compositionsTypeFilterSelector,
  compositionsSelector,
} from 'features/composition/compositionSelectors';
import { formatDate } from '@fleet/shared/utils/date';
import { CompositionRowDto } from 'dto/composition';
import { TransportationTypeId } from '@fleet/widget/dto/transportation';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { makeStyles } from '@mui/styles';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import { TransMessage } from 'i18n/trans/message';
import { TransButton } from 'i18n/trans/button';
import {
  Layout,
  Loadable,
  SearchResult,
  tableRowClickEventWrapper,
} from '@fleet/shared';
import { compositionsLoadingSelector } from 'features/loading/loadingSelectors';

const useStyles = makeStyles(() => ({
  vehicleTypeFilter: {
    width: 115,
    marginRight: 'auto',
  },
}));

export const CompositionsTable = () => {
  const classes = useStyles();
  const dateFormatter = useCallback(({ value }) => formatDate(value), []);
  const [sortBy, setSortBy] = useState<SortBy>({
    id: 'modified',
    desc: true,
  });
  const [compositionToDelete, setCompositionToDelete] =
    useState<CompositionRowDto>();
  const businessEntityId = useSelector(currentBusinessEntityIdSelector);
  const loading = useSelector(compositionsLoadingSelector);
  const dispatch = useDispatch();
  const currentTypeFilter = useSelector(compositionsTypeFilterSelector);
  const typeFilterOptions = useMemo(
    () =>
      ['', 'Ferry', 'Train', 'Bus'].map((name) => ({
        label: name || 'All',
        value: name && `LINE_TRANSPORTATION_TYPE.${name.toUpperCase()}`,
      })),
    []
  );
  const queryCompositions = useCallback(
    (e: FocusEvent<HTMLInputElement>) =>
      dispatch(
        getCompositions({
          searchString: e ? e.target.value : '',
          transportationTypeId: currentTypeFilter,
        })
      ),
    [dispatch, currentTypeFilter]
  );

  useEffect(() => {
    queryCompositions(undefined as unknown as FocusEvent<HTMLInputElement>);
  }, [dispatch, businessEntityId, currentTypeFilter, queryCompositions]);

  const history = useHistory();
  const vehicleCompositions = useSelector(compositionsSelector);
  const { t } = useTranslation();

  const link = useCallback(
    (row: Row<CompositionRowDto>) => `/compositions/${row.original.id}`,
    []
  );

  const columns: Array<Column<CompositionRowDto>> = useMemo(
    () => [
      {
        accessor: 'name',
        Header: <TransLabel i18nKey="name" />,
        id: 'name',
        Cell: ({ value, row }) => (
          <Link to={link(row)} onClick={tableRowClickEventWrapper}>
            {value}
          </Link>
        ),
      },
      { Header: <TransLabel i18nKey="code" />, accessor: 'code' },
      { Header: <TransLabel i18nKey="type" />, accessor: 'vehicleTypeName' },
      { Header: <TransLabel i18nKey="owner" />, accessor: 'owner' },
      {
        Header: <TransLabel i18nKey="modified" />,
        accessor: 'lastModificationDateTime',
        id: 'modified',
        Cell: dateFormatter,
      },
      {
        Header: <TransLabel i18nKey="created" />,
        accessor: 'createdOnDateTime',
        id: 'created',
        Cell: dateFormatter,
      },
      {
        id: 'actions',
        accessor: (composition) => (
          <ControlTooltip>
            <Button
              variant="text"
              startIcon={<Icon name="clone" />}
              onClick={(event) =>
                tableRowClickEventWrapper(event, () =>
                  dispatch(duplicateComposition(composition.id))
                )
              }
              label={<TransButton i18nKey="duplicate" />}
            />
            {!composition.isRelatedToDepartures && (
              <Button
                variant="text"
                startIcon={<Icon name="delete" />}
                onClick={(event) =>
                  tableRowClickEventWrapper(event, () =>
                    setCompositionToDelete(composition)
                  )
                }
                label={<TransButton i18nKey="delete" />}
              />
            )}
            <Button
              variant="text"
              startIcon={<Icon name="link" />}
              label={
                <Link
                  to={`/assign/line-template/${composition.id}`}
                  onClick={tableRowClickEventWrapper}
                >
                  <TransButton i18nKey="lineTemplateAssignment" />
                </Link>
              }
            />
            <Button
              variant="text"
              startIcon={<Icon name="link" />}
              label={
                <Link
                  to={`/assign/trip/${composition.id}`}
                  onClick={tableRowClickEventWrapper}
                >
                  <TransButton i18nKey="tripAssignment" />
                </Link>
              }
            />
          </ControlTooltip>
        ),
      },
    ],
    [dateFormatter, dispatch, link]
  );

  return (
    <Layout>
      <>
        {compositionToDelete && (
          <Modal
            open
            title={<TransTitle i18nKey="deleteConfirmation" />}
            message={
              t('message.deleteContent', {
                defaultValue: "Delete {{name}}? You can't undo this.",
                name: compositionToDelete.name,
              })!
            }
            actionButton={{
              className: 'delete',
              label: <TransButton i18nKey="delete" />,
              onClick: () => {
                dispatch(deleteComposition(compositionToDelete.id));
              },
            }}
            onClose={() => setCompositionToDelete(undefined)}
          />
        )}
        <SearchTable
          title="Vehicle Compositions"
          sortBy={sortBy}
          setSortBy={setSortBy}
          action={
            <Button
              color="yellow"
              variant="text"
              label={<TransButton i18nKey="newTrainConstruction" />}
              onClick={() => history.push('/compositions')}
              startIcon={<Icon name="plus" />}
            />
          }
          filter={
            <Select
              className={classes.vehicleTypeFilter}
              value={currentTypeFilter}
              onChange={(value) =>
                dispatch(
                  setCompositionsTransportType(value as TransportationTypeId)
                )
              }
              options={typeFilterOptions}
            />
          }
          search={{
            value: '',
            onBlur: queryCompositions,
          }}
        >
          <Loadable loading={loading}>
            <SearchResult
              loaded
              loading={loading}
              results={Boolean(vehicleCompositions.length)}
              message={<TransMessage i18nKey="noCompositionsCreated" />}
            >
              <Table<CompositionRowDto>
                sortBy={[sortBy]}
                columns={columns}
                data={vehicleCompositions}
                onRowClick={(row) => history.push(link(row))}
              />
            </SearchResult>
          </Loadable>
        </SearchTable>
      </>
    </Layout>
  );
};
