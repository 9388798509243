import { useCallback, useEffect, useMemo } from 'react';
import { ElementDetailsRow } from 'routes/elements/ElementDetailsRow';
import {
  ContentField,
  Field,
  TextField,
  useFormContext,
} from '@fleet/shared/form';
import { validate, formatStringToNumber } from '@fleet/shared/form/validate';
import { useDropzone } from 'react-dropzone';
import { ElementIcon } from 'routes/elements/ElementIcon';
import { Button, FormHelperText } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { useDispatch, useSelector } from 'store/utils';
import { classifierSubTypeMapSelector } from 'features/classification/classificationSelectors';
import { getSignLocalizations } from 'features/element/elementActions';
import { useRouteMatch } from 'react-router-dom';
import type { ElementTab, ElementPayload } from 'dto/element';
import { TransLabel } from 'i18n/trans/label';
import { useTranslation } from 'react-i18next';
import { TransButton } from 'i18n/trans/button';
import { ElementDetailTranslations } from 'routes/elements/ElementDetailTranslations';

const formatDimension = <D extends number>(val: D) =>
  Math.round(+formatStringToNumber(`${val}`) / 8) * 8;

const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const maxIconSize = 51200;

export const ElementDetailFields = () => {
  const { batch, change, focus, blur } = useFormContext<ElementPayload>();
  const dispatch = useDispatch();
  const {
    params: { category, id },
  } = useRouteMatch<{ category: ElementTab; id: string }>();

  const isSign = useMemo(() => category === 'sign', [category]);
  useEffect(() => {
    if (id && isSign)
      dispatch(getSignLocalizations(id))
        .unwrap()
        .then(({ localizations }) => change('localizations', localizations));
  }, [isSign, dispatch, id, change]);

  const { t } = useTranslation();
  const handleDropIcon = useCallback(
    async ([file]: Array<File>) => {
      if (!file) return;
      focus('icon');
      batch(async () => {
        change('icon', {
          name: file.name,
          size: file.size,
          contentType: file.type,
          contentLength: file.size,
          content: await toBase64(file),
        });
        blur('icon');
      });
    },
    [batch, blur, change, focus]
  );
  const handleDeleteIcon = useCallback(() => {
    focus('icon');
    batch(() => {
      change('icon', null);
      blur('icon');
    });
  }, [batch, blur, change, focus]);

  const handleValidateIcon = useCallback(
    (value: ElementPayload['icon']) => {
      if (value && value.size && value.size > maxIconSize) {
        return t('message.fileSizeMax50Kb', 'File can not exceed 50 kB');
      }
      return !Boolean(value === null || value?.content);
    },
    [t]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleDropIcon,
    accept: 'image/svg+xml',
    multiple: false,
  });

  const signSubTypeMap = useSelector(classifierSubTypeMapSelector);
  const signSubTypeOptions = useMemo(
    () =>
      Array.from(signSubTypeMap, ([id, { name }]) => ({
        value: id,
        label: name,
      })),
    [signSubTypeMap]
  );

  return (
    <>
      <ElementDetailsRow
        columns={[<TransLabel i18nKey="name" />, <ContentField name="name" />]}
      />
      <ElementDetailsRow
        columns={[<TransLabel i18nKey="type" />, <ContentField name="type" />]}
      />
      <ElementDetailsRow
        columns={[
          <TransLabel i18nKey="subType" />,
          <ContentField name="subTypeId" options={signSubTypeOptions} />,
        ]}
      />
      <ElementDetailsRow
        columns={[
          <TransLabel i18nKey="code" />,
          <ContentField name="iconCode" />,
        ]}
      />
      <ElementDetailsRow
        columns={[
          <TransLabel i18nKey="width" />,
          <TextField<number>
            name="width"
            type="number"
            format={formatDimension}
            validate={validate.positive(true)}
          />,
        ]}
      />
      <ElementDetailsRow
        columns={[
          <TransLabel i18nKey="height" />,
          <TextField<number>
            name="height"
            type="number"
            format={formatDimension}
            validate={validate.positive(true)}
          />,
        ]}
      />
      <ElementDetailsRow columns={[<TransLabel i18nKey="fileUpload" />]} />
      <>
        <Field
          name="icon"
          render={({ input: { value }, meta: { touched, error } }) => (
            <>
              <ElementDetailsRow
                columns={[
                  <div {...getRootProps()}>
                    <ElementIcon
                      icon={value?.content ?? ''}
                      sx={{ width: 80 }}
                      error={Boolean(touched && error)}
                    />
                    <input {...getInputProps()} />
                  </div>,
                  <>
                    <div>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={open}
                        startIcon={<Icon name="upload" />}
                      >
                        <TransButton i18nKey="uploadNewFile" />
                      </Button>
                    </div>
                    {value && value?.content && (
                      <div>
                        <Button
                          variant="text"
                          color="error"
                          startIcon={<Icon name="delete" />}
                          onClick={handleDeleteIcon}
                        >
                          <TransButton i18nKey="deleteFile" />
                        </Button>
                      </div>
                    )}
                  </>,
                ]}
              />
              <ElementDetailsRow
                columns={[
                  <>
                    {touched && error && (
                      <FormHelperText error>{error}</FormHelperText>
                    )}
                  </>,
                ]}
              />
            </>
          )}
          validate={handleValidateIcon}
        />
      </>

      {isSign && (
        <Field
          name="subTypeId"
          render={({ input: { value: subTypeId } }) => (
            <ElementDetailTranslations
              availableLocalizations={
                signSubTypeMap.get(subTypeId)?.localizations ?? []
              }
            />
          )}
        />
      )}
    </>
  );
};
