import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { ElementCategory } from '@fleet/widget/dto/element';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ViewerContext } from '@fleet/widget/components/viewer/Context';
import getScrollbarSize from '@fleet/shared/utils/getScrollbarSize';
import { Tooltip } from '@fleet/shared/mui';
import classNames from 'classnames';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: theme.palette.common.white,
      display: 'flex',
      border: `1px solid ${theme.palette.divider}`,
      '& img': {
        margin: 'auto',
        padding: theme.spacing(),
        display: 'inline-block',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
      },
    },
    image: {
      boxSizing: 'content-box',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    draggable: {
      position: 'absolute',
      top: '-999px',
      border: '1px solid var(--border)',
      opacity: '.9',
    },
  }),
  {
    name: 'PalettePanelElement',
  }
);

interface PalettePanelElementProps {
  className?: string;
  scale: number;
  width: number;
  height: number;
  icon: string;
  name: string;
  elementId: string;
  category: ElementCategory;
}

export const PalettePanelElement: FC<PalettePanelElementProps> = (props) => {
  const { className, scale, width, height, icon, name, elementId, category } =
    props;
  const classes = useStyles();

  const { selectBoxRef } = useContext(ViewerContext);
  const [isDragged, setDragged] = useState(false);
  useEffect(() => {
    const htmlNode = document.documentElement;

    htmlNode.style.paddingRight = `${isDragged ? getScrollbarSize() : 0}px`;
    htmlNode.style.overflow = isDragged ? 'hidden' : '';
    htmlNode.style.userSelect = isDragged ? 'none' : '';
  }, [isDragged]);

  const handleDragStart = useCallback(
    (event) => {
      setDragged(true);
      const {
        firstElementChild: image,
        dataset: { elementId, category },
      } = event.currentTarget;
      const { width, height } = image.getBoundingClientRect();
      image.style.width = `${width * scale}px`;
      image.style.height = `${height * scale}px`;

      selectBoxRef.current?.setAttrs({ width, height });
      event.dataTransfer.setDragImage(image, 0, 0);
      event.dataTransfer.setData('elementId', elementId);
      event.dataTransfer.setData('category', category);
    },
    [scale, selectBoxRef]
  );

  const handleDragEnd = useCallback((event) => {
    setDragged(false);
    const { firstElementChild: image } = event.currentTarget;
    image.style.width = `auto`;
    image.style.height = `auto`;
    event.dataTransfer.clearData();
  }, []);

  const imageProps = useMemo(
    () => ({
      alt: name,
      src: icon,
    }),
    [icon, name]
  );

  return (
    <Tooltip content={name} placement="right" disableInteractive>
      <div
        className={classNames(classes.root, className, {
          ...(category === ElementCategory.sign && {
            [`${className}2x`]: width > 24,
          }),
        })}
        draggable={true}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        data-element-id={elementId}
        data-category={category}
      >
        <img className={classes.draggable} {...imageProps} />
        <img
          className={classes.image}
          {...imageProps}
          width={width}
          height={height}
        />
      </div>
    </Tooltip>
  );
};
