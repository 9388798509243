import { useCallback, useMemo, useState, MouseEvent } from 'react';
import {
  LineTemplateDto,
  LineTemplateVehicleComposition,
} from 'dto/lineTemplate';
import { TripDto, TripVehicleComposition } from 'dto/trip';
import { CompositionConstructDto } from 'dto/composition';
import { ModalProps } from '@fleet/shared/mui/Modal';

export type CompositionManageAction =
  | 'add'
  | 'replace'
  | 'delete'
  | 'addComposition'
  | 'copyComposition';
interface InitialState<D, C> {
  data: Array<D>;
  vehicleComposition?: C | null;
  action?: CompositionManageAction;
  refreshSearch?: boolean;
  autoResetExpanded?: boolean;
}

type lineTemplateModalPayload = InitialState<
  LineTemplateDto,
  LineTemplateVehicleComposition | CompositionConstructDto | {}
> & {};

type tripModalPayload = InitialState<
  TripDto,
  Partial<TripVehicleComposition>
> & {};

export function useCompositionManage<D, C>() {
  const initialState = useMemo<InitialState<D, C>>(
    () => ({
      data: [],
      vehicleComposition: null,
      refreshSearch: false,
      autoResetExpanded: false,
    }),
    []
  );
  const [state, _setState] = useState(initialState);
  const setState = useCallback(
    (params) => _setState((old) => ({ ...old, ...params })),
    []
  );

  const getModalHandler = useCallback(
    (payload: lineTemplateModalPayload | tripModalPayload) =>
      (e?: MouseEvent): void => {
        e?.stopPropagation();
        setState(payload as tripModalPayload);
      },
    [setState]
  );
  const onModalClose = useCallback<Required<ModalProps>['onClose']>(
    (event, reason) => {
      const isCancelClose = reason !== 'action';
      const { action } = state;
      setState({
        ...initialState,
        ...(!isCancelClose && {
          refreshSearch: true,
          autoResetExpanded: action === 'delete',
        }),
      });

      if (!isCancelClose) {
        setTimeout(() =>
          setState({
            refreshSearch: false,
            autoResetExpanded: false,
          })
        );
      }
    },
    [initialState, setState, state]
  );

  return {
    state,
    getModalHandler,
    onModalClose,
  };
}
