import { createAsyncThunk } from 'store/utils';
import type {
  Snugs as VehicleSnugs,
  Snug as VehicleSnug,
  Snug,
} from '@fleet/widget/dto/floor';
import { getArguments } from 'features/floor/floorActions';
import { readVehicle } from 'features/vehicle/vehicleActions';
import { api } from '@fleet/shared';

export const getVehicleSnugs = createAsyncThunk<VehicleSnugs>(
  'vehicle/getSnugs',
  async (_, { getState }) => {
    const { pathPrefix, floorId } = getArguments(getState());
    return (await api.get(`${pathPrefix}/floors/${floorId}/snugs`)).data.snugs;
  }
);

export const createVehicleSnug = createAsyncThunk<
  void,
  Omit<VehicleSnug, 'id'>
>('vehicle/createSnug', async (snug, { getState, dispatch }) => {
  const { pathPrefix, vehicleId, floorId } = getArguments(getState());
  await api.post(`${pathPrefix}/floors/${floorId}/snugs`, snug);
  await dispatch(readVehicle(vehicleId));
});

export const updateVehicleSnug = createAsyncThunk<
  void,
  Pick<Snug, 'id' | 'placeIds'>
>('vehicle/updateSnug', async ({ id, placeIds }, { getState, dispatch }) => {
  const { pathPrefix, vehicleId, floorId } = getArguments(getState());
  await api.put(`${pathPrefix}/floors/${floorId}/snugs/${id}`, {
    placeIds,
  });
  await dispatch(readVehicle(vehicleId));
});

export const deleteVehicleSnug = createAsyncThunk<
  void,
  Required<VehicleSnug>['id']
>('vehicle/deleteSnug', async (snugId, { getState, dispatch }) => {
  const { pathPrefix, vehicleId, floorId } = getArguments(getState());
  await api.delete(`${pathPrefix}/floors/${floorId}/snugs/${snugId}`);
  await dispatch(readVehicle(vehicleId));
});
