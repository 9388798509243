import { FC, useEffect, useMemo, useState } from 'react';
import {
  LineTemplateDto,
  LineTemplatePayload,
  LineTemplateVehicleComposition,
} from 'dto/lineTemplate';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import {
  Checkbox,
  DatePicker,
  Modal,
  ReadOnlyField as Field,
  Select,
  ToggleButtonGroup,
} from '@fleet/shared/mui';
import { useTranslation } from 'react-i18next';
import { Timetable } from 'components/lineTemplate/Timetable';
import { useDispatch, useSelector } from 'store/utils';
import { assignEntityFilterOptionsSelector } from 'features/classification/classificationSelectors';
import { formatDate, isoDateTimeFormat } from '@fleet/shared/utils/date';

import { makeStyles } from '@mui/styles';
import { ModalProps } from '@fleet/shared/mui/Modal';
import { DatePickerValue } from '@fleet/shared/mui/DatePicker';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { CompositionManageAction } from 'hooks/useCompositionManage';
import {
  assignLineTemplate,
  removeLineTemplateRelation,
  updateLineTemplate,
} from 'features/lineTemplate/lineTemplateActions';

const useStyles = makeStyles(
  () => ({
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '10px',
      marginBottom: '8px',
    },
    compositionSelect: {
      '&.MuiFormControl-root': {
        width: 244,
      },

      '& .MuiTypography-root': {
        color: 'var(--mid-gray)',
      },
    },
  }),
  {
    name: 'LineTemplateEditModal',
  }
);

interface LineTemplateDisplayDto {
  name: string;
  lineNumber: string;
  serviceCodes: string;
  startDate: string;
  endDate: string;
  departureDays: string;
}

export const LineTemplateEditModal: FC<{
  title?: string;
  lineTemplates: LineTemplateDto[];
  vehicleComposition: LineTemplateVehicleComposition;
  action?: CompositionManageAction;
  onClose: Required<ModalProps>['onClose'];
}> = ({ lineTemplates, vehicleComposition, action, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    vehicleCompositions: vehicleCompositionsOptions,
    vehicleCompositionDirection: vehicleCompositionDirectionOptions,
  } = useSelector(assignEntityFilterOptionsSelector);
  const { id, departureTimes } = lineTemplates[0];
  const [params, setParams] = useState<LineTemplatePayload>({
    lineTemplateIds: lineTemplates.map(({ id }) => id),
    lineTemplateId:
      action === 'replace'
        ? vehicleComposition.lineTemplateVehicleCompositionId
        : id,
    vehicleCompositionId: vehicleComposition.id,
    vehicleCompositionDirectionId:
      vehicleComposition.vehicleCompositionDirectionId ||
      'VEHICLE_COMPOSITION_DIRECTION.FORWARD',
    startDate: action === 'add' ? undefined : vehicleComposition.startDate,
    endDate: action === 'add' ? undefined : vehicleComposition.endDate,
    departureTimesFromTemplate: vehicleComposition.departureTimesFromTemplate,
  });
  const [isoDateFormat] = isoDateTimeFormat.split("'T'");

  const setParam = (param: Partial<LineTemplatePayload>) =>
    setParams((prevParams) => ({ ...prevParams, ...param }));

  useEffect(() => {
    if (params.departureTimesFromTemplate) {
      setParam({ departureTimes });
    } else {
      setParam({
        departureTimes: _get(vehicleComposition, 'departureTimes', []),
      });
    }
  }, [departureTimes, params.departureTimesFromTemplate, vehicleComposition]);

  const lineTemplateInfo = useMemo<Record<string, string> | null>(() => {
    if (lineTemplates.length > 1) return null;
    const { name, lineNumber, serviceCodes, startDate, endDate } =
      lineTemplates[0];
    return {
      name,
      lineNumber,
      serviceCodes: serviceCodes.join(', '),
      startDate: formatDate(
        action!.indexOf('add') > -1 ? startDate : vehicleComposition.startDate
      ),
      endDate: formatDate(
        action!.indexOf('add') > -1 ? endDate : vehicleComposition.endDate
      ),
      departureDays: (action === 'replace' &&
      !vehicleComposition.departureTimesFromTemplate
        ? vehicleComposition.departureTimes
        : departureTimes
      )
        .map(({ departureDay }) => departureDay)
        .join(', '),
    };
  }, [action, departureTimes, lineTemplates, vehicleComposition]);
  const actionButton = useMemo(() => {
    const undefinedDepartureTimes =
      lineTemplates.length === 1 &&
      !params.departureTimesFromTemplate &&
      !params.departureTimes?.some(
        ({ departureTimes }) => departureTimes.length
      );
    if (['add', 'addComposition'].includes(action!)) {
      const fields = [
        'vehicleCompositionId',
        'vehicleCompositionDirectionId',
        'startDate',
        'endDate',
        ...(lineTemplates.length === 1
          ? ['lineTemplateId', 'departureTimesFromTemplate', 'departureTimes']
          : ['lineTemplateIds']),
      ];
      return {
        label: <TransButton i18nKey="assign" />,
        disabled:
          !params.startDate || !params.endDate || undefinedDepartureTimes,
        onClick: async () => {
          await dispatch(
            assignLineTemplate(_pick(params, fields) as LineTemplatePayload)
          );
        },
      };
    }
    if (action === 'delete') {
      return {
        label: <TransButton i18nKey="delete" />,
        className: 'delete',
        onClick: async () => {
          const { lineTemplateVehicleCompositionId } = vehicleComposition;
          await dispatch(
            removeLineTemplateRelation(lineTemplateVehicleCompositionId)
          );
        },
      };
    }

    return {
      label: <TransButton i18nKey="update" />,
      disabled: !params.startDate || !params.endDate || undefinedDepartureTimes,
      onClick: async () => {
        await dispatch(updateLineTemplate(params));
      },
    };
  }, [action, lineTemplates, params, dispatch, vehicleComposition]);

  const minEndDate = useMemo(
    () => (params.startDate ? new Date(params.startDate) : undefined),
    [params.startDate]
  );

  return (
    <Modal
      open
      maxWidth="md"
      fullWidth
      title={action && <TransTitle i18nKey={`trip.${action}`} />}
      message={
        action === 'delete'
          ? t('message.deleteVehicleCompositionFromTrip', {
              defaultValue:
                'Are you sure you want to remove vehicle composition {{compositionName}} from trip {{tripName}}?',
              tripName: lineTemplates[0].name,
              compositionName: vehicleComposition!.name,
            })!
          : undefined
      }
      actionButton={actionButton}
      onClose={onClose}
    >
      {action !== 'delete' && (
        <>
          {lineTemplateInfo && (
            <>
              <h3>
                <TransTitle i18nKey="lineTemplate" />
              </h3>
              <div className={classes.row}>
                {Object.entries(lineTemplateInfo).map(([field, value]) => (
                  <Field
                    key={field}
                    label={
                      <TransLabel
                        i18nKey={field as keyof LineTemplateDisplayDto}
                      />
                    }
                    value={value}
                  />
                ))}
              </div>
            </>
          )}
          {action === 'add' && (
            <>
              <div className={classes.row}>
                <Field
                  label={<TransLabel i18nKey="vehicle" />}
                  value={vehicleComposition.name}
                />
                <Field label={<TransLabel i18nKey="direction" />}>
                  <ToggleButtonGroup
                    value={params.vehicleCompositionDirectionId}
                    onChange={(directionId) =>
                      setParam({ vehicleCompositionDirectionId: directionId })
                    }
                    color="secondary"
                    options={vehicleCompositionDirectionOptions}
                  />
                </Field>
              </div>
              <div className={classes.row}>
                <DatePicker
                  label={<TransLabel i18nKey="connectionStartDate" />}
                  value={params.startDate as DatePickerValue<false>}
                  onChange={(val) =>
                    setParam({
                      startDate: formatDate(val as Date, isoDateFormat),
                    })
                  }
                  isClearable
                />
                <DatePicker
                  label={<TransLabel i18nKey="connectionEndDate" />}
                  value={params.endDate as DatePickerValue<false>}
                  minDate={minEndDate}
                  onChange={(val) =>
                    setParam({
                      endDate: formatDate(val as Date, isoDateFormat),
                    })
                  }
                  isClearable
                />
              </div>
            </>
          )}
          {['addComposition', 'replace'].includes(action!) && (
            <>
              <div className={classes.row}>
                <Select
                  className={classes.compositionSelect}
                  label={<TransLabel i18nKey="vehicle" />}
                  options={vehicleCompositionsOptions}
                  value={params.vehicleCompositionId ?? null}
                  onChange={(value) =>
                    setParam({ vehicleCompositionId: +value! })
                  }
                />
                <Field label={<TransLabel i18nKey="direction" />}>
                  <ToggleButtonGroup
                    value={params.vehicleCompositionDirectionId}
                    onChange={(directionId) =>
                      setParam({ vehicleCompositionDirectionId: directionId })
                    }
                    color="secondary"
                    options={vehicleCompositionDirectionOptions}
                  />
                </Field>
              </div>
              <div className={classes.row}>
                <DatePicker
                  label={<TransLabel i18nKey="connectionStartDate" />}
                  value={params.startDate as DatePickerValue<false>}
                  onChange={(val) =>
                    setParam({
                      startDate: formatDate(val as Date, isoDateFormat),
                    })
                  }
                  isClearable
                />
                <DatePicker
                  label={<TransLabel i18nKey="connectionEndDate" />}
                  value={params.endDate as DatePickerValue<false>}
                  minDate={
                    params.startDate ? new Date(params.startDate) : undefined
                  }
                  onChange={(val) =>
                    setParam({
                      endDate: formatDate(val as Date, isoDateFormat),
                    })
                  }
                  isClearable
                />
              </div>
            </>
          )}

          {lineTemplates.length === 1 && (
            <div className={classes.row}>
              <Checkbox
                name="departureTimesFromTemplate"
                checked={params.departureTimesFromTemplate}
                label={<TransLabel i18nKey="useDepartureFromLineTemplate" />}
                onChange={({ target: { name, checked } }) =>
                  setParam({ [name]: checked })
                }
              />
            </div>
          )}
          {!params.departureTimesFromTemplate && lineTemplates.length === 1 && (
            <Timetable
              departureTimes={departureTimes}
              initialValue={params.departureTimes}
              onChange={(departureTimes) => setParam({ departureTimes })}
            />
          )}
        </>
      )}
    </Modal>
  );
};
