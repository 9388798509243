import type { MouseEvent, PropsWithChildren } from 'react';
import { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { DrawerFormCardActions, useDrawerForm } from '@fleet/shared';
import { Button, CardContent, Table, TableBody } from '@mui/material';
import { FormProvider, useForm } from '@fleet/shared';
import { type Config as FinalFormConfig } from 'final-form';
import { TransButton } from 'i18n/trans/button';
import { ElementDetailFields } from 'routes/elements/ElementDetailFields';

const useStyles = makeStyles(
  () => ({
    card: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    cardContent: {
      flex: 1,
    },
    cardActions: {
      position: 'relative',
      zIndex: 1,
      padding: 16,
      justifyContent: 'flex-end',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    },
    tableCell: {
      borderBottom: 0,
    },
  }),
  {
    name: 'ElementDetails',
  }
);

interface ElementDetailsProps<D extends object> extends FinalFormConfig<D> {}

export const ElementDetails = <TValues extends object>({
  initialValues,
  onSubmit,
}: PropsWithChildren<ElementDetailsProps<TValues>>) => {
  const { form, handleSubmit } = useForm<TValues>({
    initialValues,
    onSubmit,
  });
  const { handleClose: onClose } = useDrawerForm();
  const handleClose = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onClose(event, 'escapeKeyDown');
    },
    [onClose]
  );
  const classes = useStyles();
  return (
    <FormProvider form={form}>
      <form className={classes.card} onSubmit={handleSubmit}>
        <CardContent className={classes.cardContent} sx={{ p: 0 }}>
          <Table size="small">
            <TableBody>
              <ElementDetailFields />
            </TableBody>
          </Table>
        </CardContent>
        <DrawerFormCardActions>
          <Button variant="text" color="primary" onClick={handleClose}>
            <TransButton i18nKey="cancel" />
          </Button>
          <Button type="submit" variant="contained" color="primary">
            <TransButton i18nKey="save" />
          </Button>
        </DrawerFormCardActions>
      </form>
    </FormProvider>
  );
};
