import type { FC } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, CardHeader, Icon, Layout, Loadable } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { useHistory, useParams } from 'react-router-dom';
import { SiAllocationRulesetDetailsForm } from 'routes/siAllocationRuleset/SiAllocationRulesetDetailsForm';
import { useDispatch, useSelector } from 'store/utils';
import {
  getSiAllocationRuleset,
  setSiAllocationRuleset,
} from 'features/siAllocationRuleset/siAllocationRulesetActions';
import { TransButton } from 'i18n/trans/button';
import { SiAllocationRulesetRules } from 'routes/siAllocationRuleset/SiAllocationRulesetRules';
import Card from '@mui/material/Card';
import { siAllocationRulesetLoadingSelector } from 'features/loading/loadingSelectors';
import { currentSiAllocationRulesetSelector } from 'features/siAllocationRuleset/siAllocationRulesetSelectors';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 'unset',
      paddingBottom: theme.spacing(3),
    },
    rules: {
      flex: 1,
      display: 'flex',
      minHeight: 0,
      margin: theme.spacing(3),
      marginTop: 0,
    },
  }),
  {
    name: 'SiAllocationRulesetDetails',
  }
);

interface SiAllocationRulesetDetailsProps {}

export const SiAllocationRulesetDetails: FC<
  SiAllocationRulesetDetailsProps
> = () => {
  const { action, id } = useParams<{
    action: 'create' | 'edit';
    id?: string;
  }>();

  const loading = useSelector(siAllocationRulesetLoadingSelector);
  const siAllocationRuleset = useSelector(currentSiAllocationRulesetSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (id && action === 'edit') dispatch(getSiAllocationRuleset(id));
    return () => {
      dispatch(setSiAllocationRuleset());
    };
  }, [action, dispatch, id]);

  const history = useHistory();
  const classes = useStyles();

  return (
    <Loadable loading={loading}>
      <Layout
        componentClasses={{ root: classes.root }}
        header={
          <CardHeader
            title={
              <>
                {loading ? (
                  <TransTitle i18nKey="siAllocationRuleset" />
                ) : siAllocationRuleset?.id ? (
                  <>
                    <TransTitle i18nKey="siAllocationRuleset" />
                    :&nbsp;
                    {siAllocationRuleset.name}
                  </>
                ) : (
                  <TransTitle i18nKey="newSiAllocationRuleset" />
                )}
              </>
            }
          >
            <Button
              variant="text"
              startIcon={<Icon name="arrow-left-circle" />}
              onClick={() => history.replace(`/allocation-rulesets`)}
              color="yellow"
            >
              <TransButton i18nKey="back" />
            </Button>
          </CardHeader>
        }
      >
        <SiAllocationRulesetDetailsForm />
      </Layout>
      {Boolean(siAllocationRuleset) && (
        <Card className={classes.rules}>
          <SiAllocationRulesetRules />
        </Card>
      )}
    </Loadable>
  );
};
