import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { Application } from 'Application';
import { ScrollToTop } from 'components/common/ScrollToTop';
import { Components } from 'routes/Components';
import { AppVersion, EnvironmentBanner } from '@fleet/shared';

const ALLOW_COMPONENTS_ROUTE =
  process.env.NODE_ENV === 'development' ||
  location.origin === 'https://vm.development.turnit.tech';

export default function App() {
  return (
    <>
      <Router>
        <EnvironmentBanner envName={process.env.REACT_APP_ENVIRONMENT!} />
        <ScrollToTop />
        <Switch>
          {ALLOW_COMPONENTS_ROUTE && (
            <Route path="/components" component={Components} />
          )}
          <Route path="/" component={Application} />
        </Switch>
      </Router>
      <AppVersion version={process.env.REACT_APP_VERSION!} />
    </>
  );
}
