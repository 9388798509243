import type { FC } from 'react';
import { useMemo } from 'react';
import { LineTemplateDto } from 'dto/lineTemplate';
import { ExternalLink } from '@fleet/shared/mui';
import { TransLabel } from 'i18n/trans/label';
import { formatDate } from '@fleet/shared/utils/date';
import _partition from 'lodash/partition';
import { sortWeekDays } from 'features/utils';
import type { TableColumns } from '@fleet/shared/components/Table';
import {
  ManageTable,
  ManageTableBaseProps,
} from 'components/manage/ManageTable';

export interface LineTemplateSearchTableProps<
  D extends LineTemplateDto = LineTemplateDto
> extends ManageTableBaseProps<D> {}

export const LineTemplateSearchTable: FC<LineTemplateSearchTableProps> = ({
  data,
  vehicleConstructId,
  controlsAccessor,
  ...tableProps
}) => {
  const columns = useMemo<TableColumns<LineTemplateDto>>(
    () => [
      {
        id: 'name',
        Header: <TransLabel i18nKey="name" />,
        accessor: ({ name, id }) => (
          <ExternalLink
            path={`/BusLines/BusLineTemplate/Edit/${id}`}
            underline="none"
            content={name}
          />
        ),
      },
      {
        id: 'serviceCodes',
        Header: <TransLabel i18nKey="serviceCode" />,
        accessor: ({ serviceCodes }) => serviceCodes.join(', '),
        width: 100,
      },
      {
        accessor: 'lineNumber',
        Header: <TransLabel i18nKey="lineNumber" />,
        width: 80,
      },
      {
        id: 'subContractor',
        Header: <TransLabel i18nKey="subContractor" />,
        accessor: ({ subContractor }) =>
          subContractor || <TransLabel i18nKey="noSubContractor" />,
        width: 100,
      },
      {
        id: 'startDate',
        Header: (
          <TransLabel
            i18nKey={
              vehicleConstructId ? 'connectionStartDate' : 'templateStartDate'
            }
          />
        ),
        accessor: ({ startDate, vehicleCompositions }) =>
          formatDate(
            vehicleConstructId
              ? vehicleCompositions.find(({ id }) => id === vehicleConstructId)!
                  .startDate
              : startDate
          ),
        width: 120,
      },
      {
        id: 'endDate',
        Header: (
          <TransLabel
            i18nKey={
              vehicleConstructId ? 'connectionEndDate' : 'templateEndDate'
            }
          />
        ),
        accessor: ({ endDate, vehicleCompositions }) =>
          formatDate(
            vehicleConstructId
              ? vehicleCompositions.find(({ id }) => id === vehicleConstructId)!
                  .endDate
              : endDate
          ),
        width: 120,
      },
      {
        id: 'departureDays',
        Header: <TransLabel i18nKey="departureDays" />,
        accessor: ({ departureTimes, vehicleCompositions }) => {
          const currentVehicleCompositions = vehicleConstructId
            ? vehicleCompositions.filter(({ id }) => id === vehicleConstructId)
            : vehicleCompositions;
          const templateDepartureTimes = departureTimes
            .map(({ departureDay }) => departureDay)
            .join(', ');
          const [timesFromTemplate, customTimes] = _partition(
            currentVehicleCompositions,
            ({ departureTimesFromTemplate }) => departureTimesFromTemplate
          );
          const showTemplateTimes = Boolean(
            !vehicleConstructId ||
              timesFromTemplate.length ||
              !vehicleCompositions.length
          );
          if (showTemplateTimes) {
            return templateDepartureTimes;
          } else {
            const compositionsDays = customTimes.reduce(
              (acc, { departureTimes }) => [
                ...acc,
                ...departureTimes.map(({ departureDay }) => departureDay),
              ],
              [] as string[]
            );
            return sortWeekDays(compositionsDays).join(', ');
          }
        },
        width: 120,
      },
    ],
    [vehicleConstructId]
  );

  return (
    <ManageTable
      data={data}
      columns={columns}
      vehicleConstructId={vehicleConstructId}
      controlsAccessor={controlsAccessor}
      {...tableProps}
    />
  );
};
