import type { FC, ReactNode } from 'react';
import type { FloorElement } from '@fleet/widget/dto/floor';
import { FocusEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'store/utils';
import { activeFloorSelector } from 'features/floor/floorSelectors';
import {
  classifiersSelector,
  inventoryClassesOptionsSelector,
} from 'features/classification/classificationSelectors';
import { TransLabel } from 'i18n/trans/label';
import _isEmpty from 'lodash/isEmpty';
import { CheckboxGroup } from '@fleet/shared/mui/Checkbox/CheckboxGroup';
import { Stack, Typography } from '@mui/material';
import { TransTitle } from 'i18n/trans/title';
import { Button, Checkbox, Input } from '@fleet/shared/mui';
import classNames from 'classnames';
import { isPlace } from '@fleet/widget/helpers/element';
import _values from 'lodash/values';
import { TransButton } from 'i18n/trans/button';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginBottom: theme.spacing(2),
    },
    controls: {
      position: 'sticky',
      top: 0,
      height: 0,
      textAlign: 'right',
      zIndex: 1,
    },
    filters: {
      display: 'none',
      '&.visible': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  }),
  {
    name: 'SeatsFilter',
  }
);

const numberTypesValues = ['even', 'odd'] as const;

interface FilterParams {
  inventoryClassIds: Array<string>;
  numberTypes: Array<(typeof numberTypesValues)[number]>;
  range: {
    min?: number;
    max?: number;
  };
  isBlocked: boolean;
  seatProps: Array<string>;
}

export const initialFilterState: FilterParams = {
  inventoryClassIds: [],
  numberTypes: [],
  range: {
    min: 0,
    max: 0,
  },
  isBlocked: false,
  seatProps: [],
};

interface UseSeatFilterProps<
  T extends Array<FloorElement> = Array<FloorElement>
> {
  setFilteredPlaces: (elements: T) => void;
}

const isEvenNum = (n: number) => n % 2 === 0;

export const useSeatsFilter = ({ setFilteredPlaces }: UseSeatFilterProps) => {
  const currentFloor = useSelector(activeFloorSelector);
  const [isActive, setSeatsFilterActive] = useState(false);
  const toggleActive = useCallback(() => {
    setSeatsFilterActive((active) => !active);
  }, []);
  const [params, setParams] = useState<FilterParams>(initialFilterState);

  const seatsFilterHandler = useCallback((params: Partial<FilterParams>) => {
    setParams((old) => ({ ...old, ...params }));
  }, []);

  useEffect(() => {
    const { inventoryClassIds, seatProps, range, numberTypes, isBlocked } =
      params;
    const filtersFilled = [
      inventoryClassIds,
      seatProps,
      range,
      numberTypes,
      { isBlocked },
    ].some((filter) => _values(filter).some((v) => v));
    if (!isActive || !filtersFilled) return setFilteredPlaces([]);
    const { min, max } = range;
    const [isEven, isOdd] = numberTypesValues.map((type) =>
      numberTypes.includes(type)
    );

    setFilteredPlaces(
      (currentFloor?.elements ?? []).filter((el) => {
        if (!isPlace(el)) return false;

        const { inventoryClass, properties, number } = el;
        const allowedInventoryClass =
          _isEmpty(inventoryClassIds) ||
          inventoryClassIds.includes(inventoryClass?.id || '');
        const propertyIds = properties.map(({ id }) => id);
        const allowedSeatProps =
          _isEmpty(seatProps) ||
          seatProps.every((prop) => propertyIds.includes(prop));
        const seatNumber = number && parseInt(number);
        const isEvenSeatNum = seatNumber && isEvenNum(seatNumber);
        const allowedSeatNumberMultiplicity =
          isEven || isOdd
            ? seatNumber &&
              ((isEven && isEvenSeatNum) || (isOdd && !isEvenSeatNum))
            : true;
        const allowedSeatNumber =
          !(min && max) ||
          (seatNumber && seatNumber >= min && seatNumber <= max);

        return (
          allowedInventoryClass &&
          allowedSeatProps &&
          allowedSeatNumberMultiplicity &&
          allowedSeatNumber &&
          (isBlocked ? el.isBlocked : true)
        );
      })
    );
  }, [currentFloor?.elements, isActive, params, setFilteredPlaces]);

  return {
    isActive,
    params,
    toggleActive,
    seatsFilterHandler,
    setFilteredPlaces,
  };
};

export interface SeatFilterProps<
  T extends Array<FloorElement> = Array<FloorElement>
> {
  filteredPlaces?: T;
  toggleLabel: (active: boolean) => ReactNode;
  filter: ReturnType<typeof useSeatsFilter>;
}

export const SeatsFilter: FC<SeatFilterProps> = ({
  filter,
  toggleLabel,
  filteredPlaces,
}) => {
  const inventoryClassOptions = useSelector(inventoryClassesOptionsSelector);
  const numberTypeOptions = useMemo(
    () =>
      numberTypesValues.map((value) => ({
        value,
        label: <TransLabel i18nKey={value} />,
      })),
    []
  );
  const { SEAT_PROPERTY } = useSelector(classifiersSelector);
  const seatFilterPropertyOptions = useMemo(
    () => SEAT_PROPERTY.map(({ id: value, name: label }) => ({ value, label })),
    [SEAT_PROPERTY]
  );

  const {
    isActive: seatsFilterActive,
    toggleActive: toggleSeatFilterActive,
    params: seatsFilterParams,
    seatsFilterHandler,
    setFilteredPlaces,
  } = filter;
  const numberRangeInputValue = useMemo(() => {
    const { range } = seatsFilterParams;
    const { min, max } = range;

    return min && max ? `${min}-${max}` : '';
  }, [seatsFilterParams]);
  const numberRangeFilterHandler = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const [min, max] = value.split('-').map((str) => +str.trim());
      const rangeIsValid = min && max && min <= max;
      const rangePayload = rangeIsValid ? { min, max } : { min: 0, max: 0 };

      seatsFilterHandler({ range: rangePayload });
    },
    [seatsFilterHandler]
  );

  const resetFilterParams = useCallback(() => {
    seatsFilterHandler(initialFilterState);
    setFilteredPlaces([]);
  }, [seatsFilterHandler, setFilteredPlaces]);

  const classes = useStyles();
  return (
    <>
      <Stack
        className={classes.root}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h2">
          <TransTitle i18nKey="properties" />
        </Typography>
        <Button
          onClick={toggleSeatFilterActive}
          variant="text"
          size="small"
          icon={`direction-${seatsFilterActive ? 'right' : 'left'}`}
        >
          {toggleLabel(seatsFilterActive)}
        </Button>
      </Stack>

      <div
        className={classNames(classes.filters, 'seat-filter', {
          visible: seatsFilterActive,
        })}
      >
        <Typography>
          <TransLabel
            i18nKey="selectedNumber"
            values={{ num: filteredPlaces?.length ?? 0 }}
          />
        </Typography>
        <div className={classes.controls}>
          <Button
            label={<TransButton i18nKey="reset" />}
            onClick={resetFilterParams}
          />
        </div>
        <CheckboxGroup<FilterParams['inventoryClassIds']>
          label={
            <Typography variant="subtitle">
              <TransTitle i18nKey="seatClass" />
            </Typography>
          }
          name="inventoryClassIds"
          value={seatsFilterParams.inventoryClassIds}
          options={inventoryClassOptions}
          onChange={(_, inventoryClassIds) => {
            seatsFilterHandler({ inventoryClassIds });
          }}
          margin="normal"
        />

        <CheckboxGroup<FilterParams['numberTypes']>
          label={
            <Typography variant="subtitle">
              <TransTitle i18nKey="seatsNumber" />
            </Typography>
          }
          name="numberTypes"
          value={seatsFilterParams.numberTypes}
          options={numberTypeOptions}
          onChange={(_, numberTypes) => {
            seatsFilterHandler({ numberTypes });
          }}
          margin="normal"
        />

        <Input
          label={<TransLabel i18nKey="range" />}
          placeholder="1 - 21"
          allowedChars={/^(\d+)?(\s*-\s*)?(\d+)*$/}
          value={numberRangeInputValue}
          onBlur={numberRangeFilterHandler}
        />
        <h3>
          <TransTitle i18nKey="seatProperties" />
        </h3>
        <Checkbox
          name="isBlocked"
          label={<TransLabel i18nKey="property.isBlocked" />}
          checked={seatsFilterParams.isBlocked}
          onChange={(e) => {
            seatsFilterHandler({ isBlocked: e.target.checked });
          }}
        />
        <CheckboxGroup<FilterParams['seatProps']>
          name="properties"
          options={seatFilterPropertyOptions}
          value={seatsFilterParams.seatProps}
          onChange={(_, seatProps) => {
            seatsFilterHandler({ seatProps });
          }}
        />
      </div>
    </>
  );
};
