import { createSlice } from '@reduxjs/toolkit';
import type { Pagination } from '@fleet/shared/dto/pagination';
import type { TripDto, TripFilterDto } from 'dto/trip';
import { setTrip, setTripFilter, setTrips } from 'features/trip/tripActions';
import _findIndex from 'lodash/findIndex';

export interface LineTemplateState {
  list?: Pagination<TripDto>;
  current?: TripDto;
  filter: Partial<TripFilterDto>;
}

const initialState: LineTemplateState = { filter: {} };

export const { reducer: tripReducer } = createSlice({
  name: 'trip',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setTripFilter, (state, action) => {
        state.filter = action.payload;
      })
      .addCase(setTrips, (state, action) => {
        state.list = action.payload;
      })
      .addCase(setTrip, (state, { payload }) => {
        state.current = payload;
        const list = state.list?.items;
        if (!list || !payload) return;
        list.splice(_findIndex(list, { id: payload.id }), 1, payload);
      });
  },
});
