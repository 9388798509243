import { createSelector, selector } from 'store/utils';

export const selectAllocationScoreTemplates = selector(
  (state) => state.allocationScoreTemplate.list
);
export const allocationScoreTemplatesSelector = createSelector(
  selectAllocationScoreTemplates
);

export const selectCurrentAllocationScoreTemplate = selector(
  (state) => state.allocationScoreTemplate.current
);
export const currentAllocationScoreTemplateSelector = createSelector(
  selectCurrentAllocationScoreTemplate
);
