import { createAsyncThunk } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { getFloorElementsList } from 'features/classification/classificationActions';
import { ElementPayload } from 'dto/element';
import { api } from '@fleet/shared';

export const updateFloorElementIcon = createAsyncThunk<
  void,
  Pick<ElementPayload, 'id' | 'category' | 'icon'>
>('element/updateIcon', async ({ id, category, icon }, { dispatch }) => {
  await api.patch(`/vehicles/floor-elements/${category}/${id}`, {
    icon,
  });
  await dispatch(getFloorElementsList());
});

export const getSignLocalizations = createAsyncThunk<
  Pick<ElementPayload, 'localizations'>,
  string
>(
  'element/getSignLocalizations',
  async (id, { getState }) =>
    (
      await api.get(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/vehicle-floor-elements/signs/${id}/localizations`
      )
    ).data
);

export const updateSignLocalizations = createAsyncThunk<
  void,
  Pick<ElementPayload, 'id' | 'localizations'>
>(
  'element/updateSignLocalizations',
  async ({ id, localizations }, { getState }) => {
    await api.put(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/vehicle-floor-elements/signs/${id}/localizations`,
      { localizations }
    );
  }
);

export const updateSignDimensions = createAsyncThunk<
  void,
  Pick<ElementPayload, 'id' | 'icon' | 'width' | 'height'>
>(
  'element/updateSignDimensions',
  async ({ id, icon, width, height }, { getState, dispatch }) => {
    await api.put(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/vehicle-floor-elements/signs/${id}`,
      icon?.content ? { width, height } : { width: null, height: null }
    );
    await dispatch(getFloorElementsList());
  }
);
