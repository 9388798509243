import {
  Header as FleetHeader,
  HeaderProps as FleetHeaderProps,
  api,
  useAuth,
} from '@fleet/shared';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModuleItem } from '@fleet/shared/components/Header';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { setCurrentBusinessEntity } from 'features/common/commonActions';
import { TransNav } from 'i18n/trans/nav';
import { clearCurrentComposition } from 'features/composition/compositionActions';
import { setCurrentVehicle } from 'features/vehicle/vehicleActions';
import { ALLOWED_BUSINESS_ENTITY_ROLES } from 'dto/classification';

interface HeaderProps {}

export const Header: FC<HeaderProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [moduleItems, setModuleItems] = useState<Array<ModuleItem>>([]);
  const auth = useAuth();
  const businessEntities = useSelector(businessEntitiesSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);

  const backLinks: FleetHeaderProps['backLinks'] = [
    {
      regex: /^\/assign\/line-template\/\d+/,
      label: t('label.lineTemplateAssignment', 'Line template assignment'),
      path: '/compositions/all',
    },
    {
      regex: /^\/assign\/trip\/\d+/,
      label: t('label.tripAssignment', 'Trip assignment'),
      path: '/compositions/all',
    },
    {
      regex: /^\/manage\/line-template\/\d+/,
      label: t('label.lineTemplateManagement', 'Line template management'),
      path: '/manage/line-template',
    },
    {
      regex: /^\/manage\/trip\/\d+/,
      label: t('label.tripManagement', 'Trip management'),
      path: '/manage/trip',
    },
  ];
  const steps: FleetHeaderProps['steps'] = [
    {
      label: <TransNav i18nKey="designVehicle" />,
      path: '/vehicles/all',
      basePath: '/vehicles',
    },
    {
      label: <TransNav i18nKey="vehicleCompositions" />,
      path: '/compositions/all',
      basePath: '/compositions',
    },
    {
      label: <TransNav i18nKey="manage" />,
      basePath: '/manage',
      children: [
        {
          label: <TransNav i18nKey="lineTemplates" />,
          path: '/manage/line-template',
        },
        {
          label: <TransNav i18nKey="trips" />,
          path: '/manage/trip',
        },
      ],
    },
    {
      label: <TransNav i18nKey="elements" />,
      path: '/elements',
      basePath: '/elements',
    },
    {
      label: <TransNav i18nKey="inventoryClass" />,
      path: '/inventory-class',
      basePath: '/inventory-class',
    },
    {
      label: <TransNav i18nKey="sleeperInventory" />,
      basePath: '/',
      children: [
        {
          label: <TransNav i18nKey="allocationRuleset" />,
          path: '/allocation-rulesets',
        },
        {
          label: <TransNav i18nKey="siServiceAllocationRuleset" />,
          path: '/service-allocation-rulesets',
        },
      ],
    },
    {
      label: <TransNav i18nKey="allocationScoreTemplate" />,
      path: '/allocation-score-templates',
      basePath: '/allocation-score-templates',
    },
  ];

  const onBusinessEntitySelect = useCallback(
    (opt) => {
      dispatch(setCurrentBusinessEntity(opt));
      dispatch(clearCurrentComposition());
      dispatch(setCurrentVehicle());
    },
    [dispatch]
  );

  useEffect(() => {
    api
      .get<Array<ModuleItem>>('/api/applications', {
        baseURL: process.env.REACT_APP_AUTH_URL,
      })
      .then(({ data }) => setModuleItems(data));
  }, []);

  return (
    <FleetHeader
      steps={steps}
      backLinks={backLinks}
      pathname={pathname}
      businessEntities={businessEntities}
      allowedRoles={ALLOWED_BUSINESS_ENTITY_ROLES}
      currentBusinessEntityId={currentBusinessEntityId}
      onBusinessEntityChange={onBusinessEntitySelect}
      onLogout={auth.signoutRedirect}
      helpPath="https://turnit.atlassian.net/wiki/spaces/HM/pages/193167361/Vehicle+Management"
      onMenuClick={history.push}
      moduleItems={moduleItems}
    />
  );
};
