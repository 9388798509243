import { createSlice } from '@reduxjs/toolkit';
import { type Pagination } from '@fleet/shared/dto/pagination';
import type { InventoryClass, InventoryClassFilter } from 'dto/inventoryClass';
import {
  clearInventoryClasses,
  createOrUpdateInventoryClass,
  getInventoryClass,
  getInventoryClasses,
  setInventoryClass,
  setInventoryClassFilter,
} from 'features/inventoryClass/inventoryClassActions';

export interface InventoryClassState {
  list?: Pagination<InventoryClass>;
  current?: InventoryClass;
  filter: Partial<InventoryClassFilter>;
}

const initialState: InventoryClassState = { filter: {} };

export const { reducer: inventoryClassReducer } = createSlice({
  name: 'inventoryClass',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInventoryClasses.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(clearInventoryClasses, (state) => {
        state.list = undefined;
      })
      .addCase(getInventoryClass.fulfilled, (state, action) => {
        state.current = action.payload;
      })
      .addCase(createOrUpdateInventoryClass.fulfilled, (state, action) => {
        state.current = action.payload;
      })
      .addCase(setInventoryClass, (state, { payload }) => {
        state.current = payload;
      })
      .addCase(setInventoryClassFilter, (state, action) => {
        state.filter = action.payload;
      });
  },
});
