import { createSlice } from '@reduxjs/toolkit';
import {
  setCurrentBusinessEntity,
  setIsDraggingFromPalette,
  setLoadingKey,
} from 'features/common/commonActions';

export interface CommonState {
  currentBusinessEntityId: number;
  loadingKey: string | null;
  loading: boolean;
  isDraggingFromPalette: boolean;
}

const initialState: CommonState = {
  currentBusinessEntityId: Number(localStorage.getItem('businessEntityId')),
  loadingKey: null,
  loading: false,
  isDraggingFromPalette: false,
};

export const { reducer: commonReducer } = createSlice({
  name: 'common',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setLoadingKey, (state, action) => {
        state.loadingKey = action.payload;
      })
      .addCase(setCurrentBusinessEntity, (state, action) => {
        localStorage.setItem('businessEntityId', String(action.payload));
        state.currentBusinessEntityId = action.payload;
      })
      .addCase(setIsDraggingFromPalette, (state, action) => {
        state.isDraggingFromPalette = action.payload;
      })
      .addMatcher(
        (action) => action.type.endsWith('/pending'),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) =>
          ['/fulfilled', '/rejected'].some((str) => action.type.endsWith(str)),
        (state) => {
          state.loading = false;
        }
      );
  },
});
