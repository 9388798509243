import { useState } from 'react';
import { noop } from '@fleet/shared/utils/noop';
import {
  AddButton,
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  ExternalLink,
  Input,
  Loader,
  Modal,
  Pagination,
  RadioGroup,
  Select,
  Switch,
  ToggleButtonGroup,
  ToggleList,
  Tooltip,
} from '@fleet/shared/mui';
import { BasePlacement } from '@popperjs/core/lib/enums';
import { Box, Grid } from '@mui/material';
import _capitalize from 'lodash/capitalize';

const OPTIONS = [
  { label: 'First option', value: 'first' },
  { label: 'Second option', value: 'second' },
  { label: 'Third option', value: 'third' },
];

const SELECT_PROPS = {
  labelPosition: 'top' as const,
  options: OPTIONS,
  onChange: noop,
};

const ButtonComponents = () => {
  const buttonProps = {
    colorString: 'primary.main' as const,
    label: 'Text label',
    variant: 'contained' as const,
    size: 'medium' as const,
  };
  const toggleButtonProps = {
    color: 'primary' as const,
    value: OPTIONS[0].value,
    options: OPTIONS,
    onChange: noop,
  };
  const buttonVariants = [
    {
      label: 'Primary',
    },
    {
      colorString: 'secondary.main' as const,
      label: 'Secondary',
    },
    {
      icon: 'pic',
      label: 'With icon',
    },
    {
      variant: 'outlined' as const,
      label: 'Outline',
    },
    {
      variant: 'text' as const,
      label: 'Text',
    },
  ].map((props) => ({ ...buttonProps, ...props }));
  return (
    <>
      <h2>{'Buttons'}</h2>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={12}>
          <h3>{'Sizes'}</h3>
          <Button {...buttonProps} size="small" />
          <Button {...buttonProps} size="medium" />
          <Button {...buttonProps} size="large" />
        </Grid>
      </Grid>
      <h3>{'Variants'}</h3>
      <Grid container spacing={1} rowSpacing={1}>
        {buttonVariants.map((props, idx) => (
          <Grid key={idx} item xs={12}>
            <Button {...props} />
            <Button {...props} loading />
            <Button {...props} disabled />
          </Grid>
        ))}
        <Grid item xs={12}>
          <ToggleButtonGroup {...toggleButtonProps} />
        </Grid>
        <Grid item xs={12}>
          <ToggleButtonGroup {...toggleButtonProps} color="secondary" />
        </Grid>
        <Grid item xs={12}>
          <ToggleButtonGroup {...toggleButtonProps} disabled />
        </Grid>
      </Grid>
      <h3>Add button</h3>
      <Grid item xs={12}>
        <AddButton {...buttonProps} />
      </Grid>
      <h2>{'Link'}</h2>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={12}>
          <ExternalLink path="/" content="Link component" />
        </Grid>
      </Grid>
    </>
  );
};

const FormComponents = () => {
  const singleLineInputProps = {
    label: 'Single line input',
    labelPosition: 'top' as const,
    value: 'Value',
  };
  const withIconProps = {
    ...singleLineInputProps,
    label: 'Input with icon',
    icon: 'pic',
  };
  const multilineInputProps = {
    ...singleLineInputProps,
    label: 'Multiline input',
    multiline: true,
    minRows: 3,
  };
  return (
    <>
      <h2>{'Inputs'}</h2>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={2}>
          <Input {...singleLineInputProps} />
        </Grid>
        <Grid item xs={2}>
          <Input {...singleLineInputProps} error />
        </Grid>
        <Grid item xs={2}>
          <Input {...singleLineInputProps} disabled />
        </Grid>
      </Grid>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={2}>
          <Input {...withIconProps} />
        </Grid>
        <Grid item xs={2}>
          <Input {...withIconProps} error />
        </Grid>
        <Grid item xs={2}>
          <Input {...withIconProps} disabled />
        </Grid>
      </Grid>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={2}>
          <Input {...multilineInputProps} />
        </Grid>
        <Grid item xs={2}>
          <Input {...multilineInputProps} error />
        </Grid>
        <Grid item xs={2}>
          <Input {...multilineInputProps} disabled />
        </Grid>
      </Grid>
      <h2>Select</h2>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={2}>
          <Select {...SELECT_PROPS} label="Single selection" />
        </Grid>
        <Grid item xs={2}>
          <Select {...SELECT_PROPS} label="Multiple selection" multiple />
        </Grid>
        <Grid item xs={2}>
          <Select {...SELECT_PROPS} label="Disabled" disabled />
        </Grid>
      </Grid>
      <h2>Datepicker</h2>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={2}>
          <DatePicker label="Single datepicker" />
          <DatePicker label="Range datepicker" onRangeChange={noop} />
        </Grid>
        <Grid item xs={2}>
          <DatePicker label="Range datepicker" disabled />
        </Grid>
      </Grid>
      <h2>Checkbox</h2>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={2}>
          <Checkbox name="single" label={OPTIONS[0].label} />
        </Grid>
        <Grid item xs={2}>
          <Checkbox name="multiple" label={OPTIONS[0].label} checked />
          <Checkbox name="multiple" label={OPTIONS[1].label} indeterminate />
          <Checkbox name="multiple" label={OPTIONS[2].label} />
        </Grid>
        <Grid item xs={2}>
          <Checkbox name="disabled" label={OPTIONS[0].label} disabled />
        </Grid>
      </Grid>
      <h2>Switch</h2>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={2}>
          <div>
            <Switch checked />
          </div>
          <div>
            <Switch />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div>
            <Switch checked disabled />
          </div>
          <div>
            <Switch disabled />
          </div>
        </Grid>
      </Grid>
      <h2>Radio</h2>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={4}>
          <RadioGroup
            name="inline"
            label="Inline group"
            options={OPTIONS}
            inline
          />
          <RadioGroup name="column" label="Column group" options={OPTIONS} />
        </Grid>
        <Grid item xs={4}>
          <RadioGroup
            name="inline"
            label="Inline group"
            options={OPTIONS}
            inline
            disabled
          />
          <RadioGroup
            name="column"
            label="Column group"
            options={OPTIONS}
            disabled
          />
        </Grid>
      </Grid>
    </>
  );
};

const CommonComponents = () => {
  return (
    <>
      <h2>Dropdown</h2>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={2}>
          <Dropdown label="Dropdown" options={OPTIONS} />
        </Grid>
        <Grid item xs={2}>
          <Dropdown label="Dropdown" options={OPTIONS} disabled />
        </Grid>
      </Grid>
      <h2>Toggle list</h2>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={2}>
          <ToggleList {...SELECT_PROPS} />
        </Grid>
        <Grid item xs={2}>
          <ToggleList {...SELECT_PROPS} initialValue="first" />
        </Grid>
        <Grid item xs={2}>
          <ToggleList {...SELECT_PROPS} initialValue="first" disabled />
        </Grid>
      </Grid>
      <h2>Pagination</h2>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={2}>
          <Pagination page={1} count={3} onPageChange={noop} />
        </Grid>
        <Grid item xs={4}>
          <Pagination page={4} count={15} onPageChange={noop} />
        </Grid>
      </Grid>
      <h2>Loader</h2>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={2}>
          <Box sx={{ position: 'relative', height: 100 }}>
            <Loader active size="container" />
          </Box>
        </Grid>
      </Grid>
      <h2>Tooltip</h2>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={2}>
          {['top', 'bottom'].map((direction) => (
            <Box key={direction} sx={{ margin: '42px 36px' }}>
              <Tooltip
                visible
                content={`${_capitalize(direction)} arrow`}
                placement={direction as BasePlacement}
              >
                <span>Tooltip text</span>
              </Tooltip>
            </Box>
          ))}
        </Grid>
        <Grid item xs={2}>
          {['left', 'right'].map((direction) => (
            <Box key={direction} sx={{ margin: '36px' }}>
              <Tooltip
                visible
                content={`${_capitalize(direction)} arrow`}
                placement={direction as BasePlacement}
              >
                <span>Tooltip text</span>
              </Tooltip>
            </Box>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

const ModalComponents = () => {
  const [modalShown, setModalShown] = useState(false);
  return (
    <>
      <h2>Modals</h2>
      <Grid container spacing={1} rowSpacing={1}>
        <Grid item xs={1}>
          <Button label="show" onClick={() => setModalShown(true)} />
          {modalShown && (
            <Modal
              open
              title="Modal title"
              message="Modal message"
              onClose={() => setModalShown(false)}
              actionButton={{
                label: 'Action',
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export const Components = () => {
  return (
    <Box
      sx={{
        padding: '0 16px',
        '& .MuiToggleButtonGroup-root, & .MuiLoadingButton-root': {
          marginRight: '8px',
        },
      }}
    >
      <ButtonComponents />
      <FormComponents />
      <CommonComponents />
      <ModalComponents />
    </Box>
  );
};
