import type { FC } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { dataLoadingSelector } from 'features/common/commonSelectors';
import {
  AddButton,
  CardHeader,
  Layout,
  Loadable,
  SearchResult,
} from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { useHistory } from 'react-router-dom';
import { siAllocationRulesetsSelector } from 'features/siAllocationRuleset/siAllocationRulesetSelectors';
import { getSiAllocationRulesets } from 'features/siAllocationRuleset/siAllocationRulesetActions';
import { SiAllocationRulesetCard } from 'routes/siAllocationRuleset/SiAllocationRulesetCard';
import { CardContent, Grid } from '@mui/material';

interface SiAllocationRulesetCardsProps {}

export const SiAllocationRulesetCards: FC<
  SiAllocationRulesetCardsProps
> = () => {
  const loading = useSelector(dataLoadingSelector);
  const data = useSelector(siAllocationRulesetsSelector);
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSiAllocationRulesets());
  }, [dispatch]);
  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader title={<TransTitle i18nKey="siAllocationRuleset" />}>
            <AddButton
              onClick={() => history.push('/allocation-rulesets/create')}
            >
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
      >
        <SearchResult
          loading={loading}
          results={data?.length}
          i18nKey="noDataAdded"
        >
          <CardContent sx={{ p: 2, flexGrow: 1 }}>
            <Grid container spacing={3}>
              {data?.map((card) => (
                <Grid key={card.id} item sm={4} md={3}>
                  <SiAllocationRulesetCard {...card} />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </SearchResult>
      </Layout>
    </Loadable>
  );
};
