import classNames from 'classnames';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'store/utils';
import { loadingKeySelector } from 'features/common/commonSelectors';
import { Box, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CircularProgressProps } from '@mui/material/CircularProgress';

const useStyles = makeStyles(
  (theme) => ({
    overlay: {
      display: 'flex',
      zIndex: 9,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'rgba(0,0,0,.1)',
      maxHeight: '100vh',

      '&$small': {
        display: 'inline-block',
        background: 'transparent',
      },
    },
    container: {
      position: 'absolute',
    },
    fullscreen: {
      position: 'fixed',
      maxHeight: '100vh',
    },
    transparent: {
      background: 'transparent',
    },
    spinnerWrapper: {
      display: 'inline-block',
      width: 75,
      height: 75,
      margin: 'auto',
      position: 'relative',

      '& .MuiCircularProgress-root': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        margin: '-50% 0 0 -50%',

        '&:first-of-type': {
          color: 'white',
        },

        '&:last-of-type': {
          zIndex: 2,
          color: theme.palette.primary.main,
        },
      },
    },
    small: {
      width: 16,
      height: 16,
    },
    hidden: {
      opacity: 0,
    },
  }),
  {
    name: 'Loader',
  }
);

interface ConnectedLoaderProps {
  active?: boolean;
  loadingKey?: string;
  hideSpinner?: boolean;
  hideOverlay?: boolean;
  size: 'xs' | 'container' | 'fullscreen';
}

export const ConnectedLoader: FC<ConnectedLoaderProps> = ({
  active,
  loadingKey,
  size,
  hideSpinner = false,
  hideOverlay = false,
}) => {
  const classes = useStyles();
  const currentLoadingKey = useSelector(loadingKeySelector);
  const loaderActive = loadingKey ? loadingKey === currentLoadingKey : active;

  const circularProps = useMemo<CircularProgressProps>(
    () => ({
      size: size === 'xs' ? 16 : 75,
      thickness: size === 'xs' ? 5 : 2,
    }),
    [size]
  );

  if (!loaderActive) return null;

  return (
    <Box
      className={classNames(classes.overlay, {
        [classes.small]: size === 'xs',
        [classes.container]: size === 'container',
        [classes.fullscreen]: size === 'fullscreen',
        [classes.transparent]: hideOverlay,
      })}
    >
      <Box
        className={classNames(classes.spinnerWrapper, {
          [classes.hidden]: hideSpinner,
          [classes.small]: size === 'xs',
        })}
      >
        <CircularProgress
          variant="determinate"
          value={100}
          {...circularProps}
        />
        <CircularProgress {...circularProps} disableShrink />
      </Box>
    </Box>
  );
};
