import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTitle = createTrans({
  addCarriage: <Trans i18nKey="title.addCarriage" defaults="Add carriage" />,
  allocationScoreTemplate: (
    <Trans
      i18nKey="title.allocationScoreTemplate"
      defaults="Allocation score template"
    />
  ),
  amenity: <Trans i18nKey="title.amenity" defaults="Amenity" />,
  angled: <Trans i18nKey="title.angled" defaults="Angled" />,
  beds: <Trans i18nKey="title.beds" defaults="Beds" />,
  blockingCarriage: (
    <Trans i18nKey="title.blockingCarriage" defaults="Blocking carriage" />
  ),
  carriageProperties: (
    <Trans i18nKey="title.carriageProperties" defaults="Carriage properties" />
  ),
  carriageSalesOpening: (
    <Trans
      i18nKey="title.carriageSalesOpening"
      defaults="Carriage sales opening"
    />
  ),
  chooseRank: <Trans i18nKey="title.chooseRank" defaults="Choose rank" />,
  class: <Trans i18nKey="title.class" defaults="Class" />,
  classes: <Trans i18nKey="title.classes" defaults="Classes" />,
  closeBulkEditor: (
    <Trans i18nKey="title.closeBulkEditor" defaults="Close bulk editor" />
  ),
  compartment: <Trans i18nKey="title.compartments" defaults="Compartments" />,
  connectedVehicleCompositionInformation: (
    <Trans
      i18nKey="title.connectedVehicleCompositionInformation"
      defaults="Connected vehicle composition information"
    />
  ),
  contents: <Trans i18nKey="title.contents" defaults="Contents" />,
  defaultProperties: (
    <Trans i18nKey="title.defaultProperties" defaults="Default properties" />
  ),
  deleteConfirmation: (
    <Trans i18nKey="title.deleteConfirmation" defaults="Delete confirmation" />
  ),
  designVehicle: (
    <Trans i18nKey="title.designVehicle" defaults="Design vehicle" />
  ),
  details: <Trans i18nKey="title.details" defaults="Details" />,
  editInProgress: (
    <Trans i18nKey="title.editInProgress" defaults="Edit in progress" />
  ),
  editProperties: (
    <Trans i18nKey="title.editProperties" defaults="Edit properties" />
  ),
  elementProperties: (
    <Trans
      i18nKey="title.elementProperties"
      defaults="(1)[{{element}} properties];(2-inf)[Multiple type properties];"
    />
  ),
  floorElementSettings: (
    <Trans
      i18nKey="title.floorElementSettings"
      defaults="Floor element settings"
    />
  ),
  floorNumber: (
    <Trans i18nKey="title.floorNumber" defaults="Floor {{number}}" />
  ),
  internal: <Trans i18nKey="title.internal" defaults="Internal elements" />,
  inventory: <Trans i18nKey="title.inventory" defaults="Inventory" />,
  inventoryClass: (
    <Trans i18nKey="title.inventoryClass" defaults="Inventory class" />
  ),
  lineTemplate: <Trans i18nKey="title.lineTemplate" defaults="Line template" />,
  lineTemplateInformation: (
    <Trans
      i18nKey="title.lineTemplateInformation"
      defaults="Line template information"
    />
  ),
  lineTemplates: (
    <Trans i18nKey="title.lineTemplates" defaults="Line templates" />
  ),
  lineTemplatesConnected: (
    <Trans
      i18nKey="title.lineTemplatesConnected"
      defaults="Line templates connected to vehicle composition"
    />
  ),
  location: <Trans i18nKey="title.location" defaults="Location" />,
  manageCompositionForLineTemplate: (
    <Trans
      i18nKey="title.manageCompositionForLineTemplate"
      defaults="Manage composition for line template"
    />
  ),
  manageCompositionForTrip: (
    <Trans
      i18nKey="title.manageCompositionForTrip"
      defaults="Manage composition for trip"
    />
  ),
  manageableSpaceForFloorNumber: (
    <Trans
      i18nKey="title.manageableSpaceForFloorNumber"
      defaults="Manageable space for floor {{number}}"
    />
  ),
  modificationHistory: (
    <Trans
      i18nKey="title.modificationHistory"
      defaults="Modification history"
    />
  ),
  mounting: <Trans i18nKey="title.mounting" defaults="Mounting" />,
  nearby: <Trans i18nKey="title.nearby" defaults="Nearby" />,
  newAllocationScoreTemplate: (
    <Trans
      i18nKey="title.newAllocationScoreTemplate"
      defaults="New allocation score template"
    />
  ),
  newInventoryClass: (
    <Trans i18nKey="title.newInventoryClass" defaults="New inventory class" />
  ),
  newServiceSiAllocationRuleset: (
    <Trans
      i18nKey="title.newServiceSiAllocationRuleset"
      defaults="New Service sleeper inventory allocation rulesets"
    />
  ),
  newSiAllocationRuleset: (
    <Trans
      i18nKey="title.newSiAllocationRuleset"
      defaults="New Sleeper inventory allocation rulesets"
    />
  ),
  noVehicleConnections: (
    <Trans
      i18nKey="title.noVehicleConnections"
      defaults="No vehicle connections"
    />
  ),
  openBulkEditor: (
    <Trans i18nKey="title.openBulkEditor" defaults="Open bulk editor" />
  ),
  orientation: <Trans i18nKey="title.orientation" defaults="Orientation" />,
  place: <Trans i18nKey="title.place" defaults="place" />,
  properties: <Trans i18nKey="title.properties" defaults="Properties" />,
  rank: <Trans i18nKey="title.rank" defaults="Rank" />,
  replaceConfirmation: (
    <Trans
      i18nKey="title.replaceConfirmation"
      defaults="Replace confirmation"
    />
  ),
  salesSequenceToBeIgnoredForProperties: (
    <Trans
      i18nKey="title.salesSequenceToBeIgnoredForProperties"
      defaults="Sales sequence to be ignored for properties"
    />
  ),
  search: <Trans i18nKey="title.search" defaults="Search" />,
  seatClass: <Trans i18nKey="title.seatClass" defaults="Seat class" />,
  seatProperties: (
    <Trans i18nKey="title.seatProperties" defaults="Seat properties" />
  ),
  seating: <Trans i18nKey="title.seating" defaults="Seating" />,
  seats: <Trans i18nKey="title.seats" defaults="Seats" />,
  seatsNumber: <Trans i18nKey="title.seatsNumber" defaults="Seats number" />,
  selectBy: <Trans i18nKey="title.selectBy" defaults="Select by" />,
  serviceSiAllocationRuleset: (
    <Trans
      i18nKey="title.serviceSiAllocationRuleset"
      defaults="Service sleeper inventory allocation rulesets"
    />
  ),
  siAllocationRuleset: (
    <Trans
      i18nKey="title.siAllocationRuleset"
      defaults="Sleeper inventory allocation rulesets"
    />
  ),
  sideBySide: (
    <Trans i18nKey="title.sideBySide" defaults="Side by side seats" />
  ),
  sign: <Trans i18nKey="title.sign" defaults="Signs" />,
  signs: <Trans i18nKey="title.signs" defaults="Signs" />,
  straight: <Trans i18nKey="title.straight" defaults="Straight" />,
  tables: <Trans i18nKey="title.tables" defaults="Tables" />,
  temporary: <Trans i18nKey="title.temporary" defaults="Temporary" />,
  'trip.add': (
    <Trans
      i18nKey="title.trip.add"
      defaults="Assign vehicle composition to trip"
    />
  ),
  'trip.addComposition': (
    <Trans
      i18nKey="title.trip.addComposition"
      defaults="Assign vehicle composition to trips"
    />
  ),
  'trip.copyComposition': (
    <Trans
      i18nKey="title.trip.copyComposition"
      defaults="Assign vehicle composition to trip"
    />
  ),
  'trip.delete': (
    <Trans i18nKey="title.trip.delete" defaults="Remove confirmation" />
  ),
  'trip.replace': (
    <Trans
      i18nKey="title.trip.replace"
      defaults="Update vehicle composition for trip"
    />
  ),
  tripInformation: (
    <Trans i18nKey="title.tripInformation" defaults="Trip information" />
  ),
  tripString: <Trans i18nKey="title.tripString" defaults="Trip" />,
  trips: <Trans i18nKey="title.trips" defaults="Trips" />,
  tripsConnected: (
    <Trans
      i18nKey="title.tripsConnected"
      defaults="Trips connected to vehicle composition"
    />
  ),
  vehicleComposition: (
    <Trans i18nKey="title.vehicleComposition" defaults="Vehicle composition" />
  ),
  vehicleCompositions: (
    <Trans
      i18nKey="title.vehicleCompositions"
      defaults="Vehicle compositions"
    />
  ),
  vehiclePlans: <Trans i18nKey="title.vehiclePlans" defaults="Vehicle plans" />,
  vehicleProperties: (
    <Trans i18nKey="title.vehicleProperties" defaults="Vehicle properties" />
  ),
  vehicleSalesOpening: (
    <Trans
      i18nKey="title.vehicleSalesOpening"
      defaults="Vehicle sales opening"
    />
  ),
  walls: <Trans i18nKey="title.walls" defaults="Walls" />,
  windowsAndDoors: (
    <Trans i18nKey="title.windowsAndDoors" defaults="Windows & Doors" />
  ),
});
