import { DragEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getCompositions } from 'features/composition/compositionActions';
import { useDispatch, useSelector } from 'store/utils';
import { compositionsSelector } from 'features/composition/compositionSelectors';
import { useTranslation } from 'react-i18next';
import { Input, Tooltip } from '@fleet/shared/mui';
import { TransTitle } from 'i18n/trans/title';
import { Paper, Typography } from '@mui/material';
import { LayoutColScrollable } from 'components/layout/LayoutColScrollable';
import Grid from '@mui/material/Grid';

export const CompositionsPanel = () => {
  const [searchStr, setSearchStr] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompositions());
  }, [dispatch]);
  const vehicleCompositions = useSelector(compositionsSelector);
  const handleDragStart = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('text', event.currentTarget.id);
  }, []);

  const handleDragEnd = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.dataTransfer.clearData();
  }, []);

  const filteredCompositions = useMemo(
    () =>
      vehicleCompositions.filter(
        ({ name }) =>
          name.toLocaleLowerCase().indexOf(searchStr.toLocaleLowerCase()) > -1
      ),
    [vehicleCompositions, searchStr]
  );

  return (
    <LayoutColScrollable>
      <Typography variant="h2" paragraph>
        <TransTitle i18nKey="vehicleCompositions" />
      </Typography>
      <Grid container columns={1} spacing={2}>
        <Grid item xs={1}>
          <Input
            className="search-input"
            placeholder={t('label.find', 'Find')!}
            icon="search"
            value={searchStr}
            onChange={(event) => setSearchStr(event.target.value)}
          />
        </Grid>
        {filteredCompositions.map(({ id, name }) => (
          <Grid key={id} item xs={1}>
            <Tooltip content={name}>
              <Paper
                id={`${id}`}
                className="palette-elements-item"
                draggable="true"
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                sx={{ p: 1 }}
              >
                {name}
              </Paper>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </LayoutColScrollable>
  );
};
