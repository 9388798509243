import { selector } from 'store/utils';
import { createSelector } from '@reduxjs/toolkit';

export const siAllocationRulesetsSelector = selector(
  (state) => state.siAllocationRuleset.list
);
export const siAllocationRulesetOptionsSelector = createSelector(
  siAllocationRulesetsSelector,
  (rulesets = []) =>
    rulesets.map(({ id, name }) => ({
      value: id,
      label: name,
    }))
);

export const siAllocationRulesetMapSelector = createSelector(
  siAllocationRulesetsSelector,
  (rulesets = []) => new Map(rulesets.map(({ id, name }) => [id, name]))
);

export const currentSiAllocationRulesetSelector = selector(
  (state) => state.siAllocationRuleset.current
);

export const currentSiAllocationRulesetRulesSelector = selector(
  (state) => state.siAllocationRuleset.current?.allocationRules
);
