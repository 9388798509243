import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransNav = createTrans({
  allocationRuleset: (
    <Trans i18nKey="nav.allocationRuleset" defaults="Allocation rulesets" />
  ),
  allocationScoreTemplate: (
    <Trans
      i18nKey="nav.allocationScoreTemplate"
      defaults="Allocation score templates"
    />
  ),
  designVehicle: (
    <Trans i18nKey="nav.designVehicle" defaults="Design vehicle" />
  ),
  elements: <Trans i18nKey="nav.elements" defaults="Elements" />,
  inventoryClass: (
    <Trans i18nKey="nav.inventoryClass" defaults="Inventory class" />
  ),
  lineTemplates: (
    <Trans i18nKey="nav.lineTemplates" defaults="Line templates" />
  ),
  manage: <Trans i18nKey="nav.manage" defaults="Manage" />,
  siServiceAllocationRuleset: (
    <Trans
      i18nKey="nav.siServiceAllocationRuleset"
      defaults="Service allocation rulesets"
    />
  ),
  sleeperInventory: (
    <Trans i18nKey="nav.sleeperInventory" defaults="Sleeper inventory" />
  ),
  trips: <Trans i18nKey="nav.trips" defaults="Trips" />,
  vehicleCompositions: (
    <Trans i18nKey="nav.vehicleCompositions" defaults="Vehicle compositions" />
  ),
});
