import type { FC, ReactNode } from 'react';
import type { CardHeaderProps } from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import { CardHeader, Layout } from '@fleet/shared';
import classNames from 'classnames';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'ManageComposition',
  }
);

interface ManageCompositionProps extends Pick<CardHeaderProps, 'title'> {
  className?: string;
  table: ReactNode;
}

export const ManageComposition: FC<ManageCompositionProps> = ({
  className,
  title,
  table,
  children,
}) => {
  const classes = useStyles();

  return (
    <Layout
      className={classNames(classes.root, className)}
      header={<CardHeader title={title} />}
    >
      <>
        {table}
        {children}
      </>
    </Layout>
  );
};
