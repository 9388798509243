import { selector } from 'store/utils';

export const lineTemplateFilterSelector = selector(
  (state) => state.lineTemplate.filter
);
export const lineTemplateListSelector = selector(
  (state) => state.lineTemplate.list
);
export const lineTemplateCurrentSelector = selector(
  (state) => state.lineTemplate.current
);
