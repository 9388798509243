import { useContext, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import _mapValues from 'lodash/mapValues';
import _values from 'lodash/values';
import { sortBy } from '@fleet/shared/utils/array';
import { useSelector } from 'store/utils';
import { Collapsible, Input } from '@fleet/shared/mui';
import { floorElementsSelector } from 'features/classification/classificationSelectors';
import { ViewerContext } from '@fleet/widget/components/viewer/Context';
import {
  PaletteElementCategory,
  PreparedPaletteElement,
} from '@fleet/widget/dto/element';
import { TransTitle } from 'i18n/trans/title';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { PalettePanelGroup } from 'components/palettePanel/PalettePanelGroup';
import { LayoutColScrollable } from 'components/layout/LayoutColScrollable';

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    elements: {
      '& .MuiAccordionSummary-root': {
        boxShadow: theme.shadows[1],
        padding: theme.spacing(1),
      },
    },
  }),
  {
    name: 'PalettePanel',
  }
);

export const PalettePanel = () => {
  const { currentFloor } = useContext(ViewerContext);
  const [searchStr, setSearchStr] = useState<string>('');
  const collapsibleOpen = useMemo(() => !!searchStr, [searchStr]);
  const { t } = useTranslation();
  const floorElements = useSelector(floorElementsSelector);
  const floorElementsByCategory = useMemo(() => {
    // return null;
    if (!floorElements) return null;
    return _values(floorElements).reduce(
      (acc, { paletteCategory, ...el }) => {
        if (
          paletteCategory === 'seats' &&
          el.subTypeId !== 'PLACE_SUB_TYPE.AVAILABLE_SEAT'
        )
          return acc;
        if (!acc[paletteCategory]) return acc;
        return {
          ...acc,
          [paletteCategory]: [...acc[paletteCategory], el],
        };
      },
      {
        compartments: [],
        walls: [],
        wallsAngled: [],
        wallsTemp: [],
        doors: [],
        seats: [],
        tables: [],
        signs: [],
        beds: [],
      }
    ) as Record<PaletteElementCategory, PreparedPaletteElement[]>;
  }, [floorElements]);

  const filteredEls = useMemo(
    () =>
      _mapValues(floorElementsByCategory, (els, category) => {
        const filtered = els.filter((el) =>
          el?.name?.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase())
        );
        if (category === PaletteElementCategory.beds)
          return sortBy(filtered, 'subTypeId');
        return filtered;
      }),
    [floorElementsByCategory, searchStr]
  );
  const classes = useStyles();
  if (!floorElementsByCategory) return <LayoutColScrollable />;
  const {
    walls,
    wallsAngled,
    wallsTemp,
    doors,
    compartments,
    seats,
    beds,
    tables,
    signs,
  } = filteredEls;

  return (
    <LayoutColScrollable
      className={classNames(classes.root, {
        'Mui-disabled': !currentFloor,
      })}
    >
      <Typography variant="h2">
        <TransTitle i18nKey="inventory" />
      </Typography>
      <Input
        className="search-input"
        placeholder={t('label.find', 'Find')!}
        icon="search"
        value={searchStr}
        onChange={(event) => setSearchStr(event.target.value)}
        margin="normal"
      />
      <div className={classes.elements}>
        <Collapsible title={<TransTitle i18nKey="walls" />} open={!!searchStr}>
          <PalettePanelGroup
            title={<TransTitle i18nKey="straight" />}
            elements={walls}
          />
          <PalettePanelGroup
            title={<TransTitle i18nKey="angled" />}
            elements={wallsAngled}
          />
          <PalettePanelGroup
            title={<TransTitle i18nKey="temporary" />}
            elements={wallsTemp}
          />
        </Collapsible>

        <Collapsible
          title={<TransTitle i18nKey="windowsAndDoors" />}
          open={collapsibleOpen}
        >
          <PalettePanelGroup elements={doors} />
        </Collapsible>

        <Collapsible
          title={<TransTitle i18nKey="compartment" />}
          open={collapsibleOpen}
        >
          <PalettePanelGroup elements={compartments} />
        </Collapsible>

        <Collapsible
          title={<TransTitle i18nKey="seating" />}
          open={collapsibleOpen}
        >
          <PalettePanelGroup elements={seats} />
        </Collapsible>

        <Collapsible
          title={<TransTitle i18nKey="beds" />}
          open={collapsibleOpen}
        >
          <PalettePanelGroup elements={beds} offset={24} />
        </Collapsible>

        <Collapsible
          title={<TransTitle i18nKey="tables" />}
          open={collapsibleOpen}
        >
          <PalettePanelGroup elements={tables} />
        </Collapsible>

        <Collapsible
          title={<TransTitle i18nKey="signs" />}
          open={collapsibleOpen}
        >
          <PalettePanelGroup elements={signs} columns={5} />
        </Collapsible>
      </div>
    </LayoutColScrollable>
  );
};
