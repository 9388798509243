import type { FC } from 'react';
import type { TableColumns } from '@fleet/shared';
import type { PaginationParams } from '@fleet/shared/dto/pagination';
import type { SiServiceAllocationRuleset } from 'dto/siServiceAllocationRuleset';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import {
  deleteSiServiceAllocationRuleset,
  getSiServiceAllocationRulesets,
} from 'features/siServiceAllocationRuleset/siServiceAllocationRulesetActions';
import {
  selectSiServiceAllocationRulesets,
  selectSiServiceAllocationRulesetsFilter,
} from 'features/siServiceAllocationRuleset/siServiceAllocationRulesetSelectors';
import {
  ConfirmDeleteModal,
  formatDate,
  Icon,
  SearchResult,
  Table,
  TableCaption,
  useIndeterminateRowSelectCheckbox,
  useModal,
  useRowActive,
  useTable,
  useTableRowHighlight,
} from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { siAllocationRulesetMapSelector } from 'features/siAllocationRuleset/siAllocationRulesetSelectors';
import { Link, useHistory, useParams } from 'react-router-dom';
import { SiServiceAllocationRulesetSearchForm } from 'routes/siServiceAllocationRuleset/SiServiceAllocationRulesetSearchForm';
import { Button, Divider } from '@mui/material';
import { Row, usePagination, useRowSelect } from 'react-table';
import { useAlert } from 'react-alert';
import { TransModal } from 'i18n/trans/modal';
import { TransMessage } from 'i18n/trans/message';
import { TransButton } from 'i18n/trans/button';

interface SiServiceAllocationRulesetTableProps {}

export const SiServiceAllocationRulesetTable: FC<
  SiServiceAllocationRulesetTableProps
> = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();

  const siServiceAllocationRulesets = useSelector(
    selectSiServiceAllocationRulesets
  );
  const filter = useSelector(selectSiServiceAllocationRulesetsFilter);

  const getPage = useCallback(
    (pageSize: number) => {
      if (filter) {
        const { limit = pageSize, offset = 0 } = filter;
        return offset / limit;
      }
      return 0;
    },
    [filter]
  );

  const data = useMemo(
    () => siServiceAllocationRulesets?.items ?? [],
    [siServiceAllocationRulesets?.items]
  );

  const link = useCallback(
    (row: Row<SiServiceAllocationRuleset>) =>
      `/service-allocation-rulesets/edit/${row.original.id}`,
    []
  );

  const siAllocationRulesetMap = useSelector(siAllocationRulesetMapSelector);
  const columns = useMemo<TableColumns<SiServiceAllocationRuleset>>(
    () => [
      {
        accessor: 'sleeperInventoryAllocationRulesetId',
        Header: <TransTableHead i18nKey="siAllocationRuleset" />,
        Cell: ({ row, value }) => (
          <Link to={`/service-allocation-rulesets/edit/${row.original.id}`}>
            {siAllocationRulesetMap.get(value)}
          </Link>
        ),
      },
      {
        accessor: 'serviceCodes',
        Header: <TransTableHead i18nKey="serviceCodes" />,
      },
      {
        accessor: 'isException',
        Header: <TransTableHead i18nKey="isException" />,
        Cell: ({ value }) => value && <Icon name="check" color="success" />,
        width: 56,
      },
      {
        accessor: 'validityPeriod',
        Header: <TransTableHead i18nKey="validity" />,
        Cell: ({ value }) =>
          [value.from, value.to]
            .filter(Boolean)
            .map((date) => formatDate(date))
            .join(' - '),
      },
      {
        accessor: 'exceptionDates',
        Header: <TransTableHead i18nKey="exceptionDates" />,
        Cell: ({ value }) =>
          (value || []).map((date) => formatDate(date)).join(', '),
      },
    ],
    [siAllocationRulesetMap]
  );

  const getRowId = useCallback(
    (row: SiServiceAllocationRuleset) => `${row.id}`,
    []
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) => {
      dispatch(
        getSiServiceAllocationRulesets({ ...filter, ...paginationParams })
      );
    },
    [filter, dispatch]
  );

  const table = useTable<SiServiceAllocationRuleset>(
    {
      data,
      columns,
      getRowId,
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
        pageSize: filter.limit ?? state.pageSize,
      }),
      manualPagination: true,
      total: siServiceAllocationRulesets?.totalCount,
      onPageChange: handlePageChange,
    },
    usePagination,
    useRowActive,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const deletionModal = useModal();
  const { selectedFlatRows } = table;

  useTableRowHighlight(id, table);

  const handleRowsRemove = useCallback(async () => {
    await dispatch(
      deleteSiServiceAllocationRuleset(
        selectedFlatRows.map(({ original }) => original.id)
      )
    ).unwrap();
    deletionModal.onClose();
    alert.success(
      <TransMessage i18nKey="serviceSiAllocationRulesetsDeleted" />
    );
  }, [dispatch, selectedFlatRows, deletionModal, alert]);
  return (
    <>
      <SiServiceAllocationRulesetSearchForm />

      <Divider />

      <SearchResult results={!!data.length}>
        <Table
          caption={
            <TableCaption>
              <Button
                startIcon={<Icon name="trash" />}
                sx={{ p: 0, whitespace: 'nowrap' }}
                onClick={deletionModal.onOpen}
                disabled={!Object.keys(table.state.selectedRowIds).length}
                color="error"
              >
                <TransButton i18nKey="deleteSelected" />
              </Button>
            </TableCaption>
          }
          table={table}
          getRowProps={(_, { row }) => ({
            onClick: () => history.push(link(row)),
          })}
        />
      </SearchResult>

      <ConfirmDeleteModal
        handleDelete={handleRowsRemove}
        title={<TransModal i18nKey="serviceSiAllocationRulesetDeletionTitle" />}
        description={
          <TransModal i18nKey="serviceSiAllocationRulesetDeletionDescription" />
        }
        isOpen={deletionModal.open}
        onClose={deletionModal.onClose}
      />
    </>
  );
};
