import type { FC } from 'react';
import { Layout, Loadable } from '@fleet/shared';
import { Route, Switch } from 'react-router-dom';
import { SiServiceAllocationRulesetTable } from 'routes/siServiceAllocationRuleset/SiServiceAllocationRulesetTable';
import { SiServiceAllocationRulesetDetails } from 'routes/siServiceAllocationRuleset/SiServiceAllocationRulesetDetails';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { getSiAllocationRulesets } from 'features/siAllocationRuleset/siAllocationRulesetActions';
import { serviceSiAllocationRulesetsLoadingSelector } from 'features/loading/loadingSelectors';

interface SiServiceAllocationRulesetListProps {}

export const SiServiceAllocationRulesetList: FC<
  SiServiceAllocationRulesetListProps
> = () => {
  const dispatch = useDispatch();
  const loading = useSelector(serviceSiAllocationRulesetsLoadingSelector);

  useEffect(() => {
    dispatch(getSiAllocationRulesets());
  }, [dispatch]);

  return (
    <Loadable loading={loading}>
      <Layout
        drawer={
          <Switch>
            <Route
              path={`/service-allocation-rulesets/:action(create|edit)/:id?`}
              component={SiServiceAllocationRulesetDetails}
              exact
            />
          </Switch>
        }
      >
        <SiServiceAllocationRulesetTable />
      </Layout>
    </Loadable>
  );
};
