import {
  DragEvent,
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'store/utils';
import {
  compositionConstructSelector,
  compositionConstructVehicleSelector,
} from 'features/composition/compositionSelectors';
import { ViewerProvider } from '@fleet/widget/components/viewer/Context';
import { ViewerControls } from 'components/viewer/ViewerControls';
import { ViewerCanvasContainer } from '@fleet/widget/components/viewer/CanvasContainer';
import { ViewerCanvas } from '@fleet/widget/components/viewer/Canvas';
import { currentVehicleSelector } from 'features/vehicle/vehicleSelector';
import {
  activeFloorSelector,
  floorListSelector,
} from 'features/floor/floorSelectors';
import { isDraggingFromPaletteSelector } from 'features/common/commonSelectors';
import {
  KonvaMouseEvent,
  useSelection,
} from '@fleet/widget/hooks/useSelection';
import { ViewerTransformer } from '@fleet/widget/components/viewer/Transformer';
import { FloorElement, PreparedFloor } from '@fleet/widget/dto/floor';
import { ConnectedLoader } from 'components/common/loader/ConnectedLoader';
import {
  CompositionVehicleTabs,
  CompositionVehicleTabsProps,
} from '@fleet/widget/components/CompositionVehicleTabs';
import { COMPOSITION_VEHICLE_LOADING } from 'routes/composition/CompositionVehicle';
import { CompositionsDropdown } from 'components/compositionManage/CompositionsDropdown';
import { PlacePropertiesEdit } from 'components/propertiesPanel/PlacePropertiesEdit';
import {
  CarriageProperties,
  CarriagePropertiesProps,
} from 'components/propertiesPanel/CarriageProperties';
import { TripEditPayload, TripVehicle } from 'dto/trip';
import classNames from 'classnames';
import { Icon, Layout } from '@fleet/shared';
import { CompositionDirection } from '@fleet/widget/dto/composition';
import { ViewerShapeProps } from '@fleet/widget/components/viewer/Shape';
import { Classifier } from '@fleet/shared/dto/classifier';
import { CompositionElements } from 'dto/composition';
import { LayoutColScrollable } from 'components/layout/LayoutColScrollable';

interface CompositionManageProps {
  compositionsList: Array<Classifier<number>>;
  dropArea?: ReactNode;
  palettePanel: ReactNode;
  onViewerDrop?: (id: string) => void;
  vehicleTabsRenderFn: CompositionVehicleTabsProps['children'];
  updateTripComposition?: (composition: TripEditPayload) => void;
  updateVehicleElements?: (elements: CompositionElements) => Promise<void>;
  readOnly?: boolean;
}

export const CompositionManage: FC<CompositionManageProps> = ({
  palettePanel,
  onViewerDrop,
  dropArea,
  vehicleTabsRenderFn,
  compositionsList,
  updateTripComposition,
  updateVehicleElements,
  readOnly,
}) => {
  const [filteredPlaces, setFilteredPlaces] = useState<Array<FloorElement>>([]);
  const selectedPlaceIds = useMemo(
    () => filteredPlaces.map(({ id }) => id),
    [filteredPlaces]
  );
  const getShapeSelected = useCallback<
    Required<ViewerShapeProps>['getShapeSelected']
  >((element) => selectedPlaceIds.includes(element.id), [selectedPlaceIds]);

  const isDraggingFromPalette = useSelector(isDraggingFromPaletteSelector);
  const compositionConstruct = useSelector(compositionConstructSelector);
  const currentCompositionVehicle = useSelector(
    compositionConstructVehicleSelector
  );
  const compositionVehicles = useMemo(() => {
    return compositionConstruct?.compositionVehicles || [];
  }, [compositionConstruct?.compositionVehicles]);
  const isTrainComposition = useMemo(
    () =>
      compositionConstruct?.transportationTypeId ===
      'LINE_TRANSPORTATION_TYPE.TRAIN',
    [compositionConstruct?.transportationTypeId]
  );
  const currentVehicle = useSelector(currentVehicleSelector);
  const floors = useSelector(floorListSelector);
  const currentFloor = useSelector(activeFloorSelector);
  const { onClick, transformerRef } = useSelection({
    allowSelectReserved: true,
    allowSelectBlocked: true,
  });

  useEffect(() => {
    if (!filteredPlaces.length) {
      transformerRef.current?.getNodes().length &&
        transformerRef.current.nodes([]);
    }
  }, [filteredPlaces, transformerRef]);

  const preparedCurrentFloor = useMemo(
    () => ({
      ...currentFloor,
      elements: currentFloor?.elements.map((el) => ({
        ...el,
        ...(['compartment', 'place'].includes(el.category)
          ? { isSelected: selectedPlaceIds.includes(el.id) }
          : { listening: false }),
      })),
    }),
    [currentFloor, selectedPlaceIds]
  );
  const onDragOver = useCallback((event: DragEvent) => {
    event.preventDefault();
  }, []);

  const handleCompositionDrop = useCallback(
    (event: DragEvent) => {
      onViewerDrop && onViewerDrop(event.dataTransfer.getData('text'));
      setFilteredPlaces([]);
    },
    [onViewerDrop]
  );

  const onClickHandler = useCallback(
    (e: KonvaMouseEvent) => {
      onClick(e, setFilteredPlaces);
    },
    [onClick, setFilteredPlaces]
  );

  const updateManageableSpace = useCallback(
    (payload) => updateVehicleElements?.({ manageableSpaces: [payload] }),
    [updateVehicleElements]
  );

  const updateCurrentCarriage = useCallback(
    async (payload: Partial<TripVehicle>) => {
      const { id, vehicleCompositionDirection } = compositionConstruct!;
      updateTripComposition!({
        tripRelationId: id,
        vehicleCompositionDirectionId: vehicleCompositionDirection!.id!,
        tripVehicleCompositionVehicles: compositionVehicles.map((vehicle) =>
          vehicle.vehicleId === currentCompositionVehicle?.vehicleId
            ? {
                ...currentCompositionVehicle,
                ...payload,
              }
            : vehicle
        ),
      });
    },
    [
      compositionConstruct,
      compositionVehicles,
      currentCompositionVehicle,
      updateTripComposition,
    ]
  );
  const updateCarriageDirection = useCallback(async () => {
    await updateCurrentCarriage({
      isVehicleFlipped: !currentCompositionVehicle!.isVehicleFlipped,
    });
  }, [currentCompositionVehicle, updateCurrentCarriage]);

  const updateCarriageNumber = useCallback<
    Required<CarriagePropertiesProps>['onCarriageChange']
  >((payload) => updateCurrentCarriage(payload), [updateCurrentCarriage]);

  return (
    <div
      className={classNames(
        'composition-manage',
        updateTripComposition ? 'trip-composition' : 'line-template-composition'
      )}
      onDragOver={onDragOver}
      onDragEnter={onDragOver}
    >
      <Layout
        sx={{ p: '0 !important' }}
        width={[isTrainComposition ? 320 : 0, 0, 320]}
      >
        {isTrainComposition ? palettePanel : <span />}
        <div
          className={classNames('composition-viewer', {
            'is-dragging': isDraggingFromPalette,
          })}
          onDrop={handleCompositionDrop}
        >
          {dropArea}
          <div className="composition-viewer-container">
            <ConnectedLoader
              loadingKey={COMPOSITION_VEHICLE_LOADING}
              size="container"
              hideOverlay
            />
            {currentVehicle && compositionConstruct && (
              <ViewerProvider
                vehicle={currentVehicle}
                floors={floors}
                currentFloor={preparedCurrentFloor as PreparedFloor}
              >
                <div className="fleet-viewer scrollable">
                  <ViewerControls>
                    <CompositionsDropdown
                      currentCompositionId={compositionConstruct.id}
                      compositions={compositionsList}
                    />
                  </ViewerControls>
                  <div className="composition-wrapper">
                    <div className="left-padding" />
                    <ViewerCanvasContainer>
                      <ViewerCanvas
                        {...(currentCompositionVehicle?.isVehicleFlipped && {
                          rotation: 180,
                        })}
                        {...(updateTripComposition
                          ? { onClick: onClickHandler, getShapeSelected }
                          : {})}
                      >
                        <ViewerTransformer
                          ref={transformerRef}
                          visible={false}
                        />
                      </ViewerCanvas>
                    </ViewerCanvasContainer>
                    <div className="composition-vehicles right-padding">
                      <div
                        className={classNames('composition-tabs', {
                          'read-only': readOnly,
                          hidden: !isTrainComposition,
                        })}
                      >
                        <CompositionVehicleTabs
                          component={Fragment}
                          vehicles={compositionVehicles}
                          children={vehicleTabsRenderFn}
                        />
                        {compositionConstruct.vehicleCompositionDirection && (
                          <>
                            {compositionConstruct.vehicleCompositionDirection
                              .id === CompositionDirection.FORWARD && (
                              <Icon
                                className="composition-tabs-direction"
                                name="direction-forward-v"
                              />
                            )}
                            {compositionConstruct.vehicleCompositionDirection
                              .id === CompositionDirection.REVERSE && (
                              <Icon
                                className="composition-tabs-direction"
                                name="direction-reverse-v"
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </ViewerProvider>
            )}
          </div>
        </div>

        <LayoutColScrollable className="properties-edit-panel">
          {currentCompositionVehicle && (
            <>
              <PlacePropertiesEdit
                filteredPlaces={filteredPlaces}
                setFilteredPlaces={setFilteredPlaces}
                updatePlaceProperties={updateVehicleElements}
              />
              <CarriageProperties
                {...(updateTripComposition && updateVehicleElements
                  ? {
                      onManageableSpaceChange: updateManageableSpace,
                      onDirectionChange: updateCarriageDirection,
                      onCarriageChange: updateCarriageNumber,
                      allowStandingPlacesEdit: true,
                      allowBlocking: true,
                    }
                  : { allowBlocking: true })}
              />
            </>
          )}
        </LayoutColScrollable>
      </Layout>
    </div>
  );
};
