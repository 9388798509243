import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/utils';
import { api } from '@fleet/shared';
import {
  SiAllocationRuleset,
  SiAllocationRulesetValues,
  SiAllocationRulesetRule,
} from 'dto/siAllocationRuleset';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import qs from 'qs';
import { currentSiAllocationRulesetSelector } from 'features/siAllocationRuleset/siAllocationRulesetSelectors';

export const setSiAllocationRuleset = createAction<
  SiAllocationRuleset | undefined
>('sleeperInventory/setAllocationRuleset');

export const getSiAllocationRulesets = createAsyncThunk<
  Array<SiAllocationRuleset>
>(
  'sleeperInventory/getAllocationRulesets',
  async (_, { getState }) =>
    (
      await api.get<{ items: Array<SiAllocationRuleset> }>(
        `/sleeper-inventory/allocation-rulesets${qs.stringify(
          { ownerId: currentBusinessEntityIdSelector(getState()) },
          {
            addQueryPrefix: true,
          }
        )}`
      )
    ).data.items
);

export const getSiAllocationRuleset = createAsyncThunk<
  SiAllocationRuleset,
  string
>('sleeperInventory/getAllocationRuleset', async (id, { dispatch }) => {
  const { data } = await api.get(
    `/sleeper-inventory/allocation-rulesets/${id}`
  );
  dispatch(setSiAllocationRuleset(data));
  return data;
});

export const createSiAllocationRuleset = createAsyncThunk<
  SiAllocationRuleset,
  Partial<SiAllocationRuleset>
>('sleeperInventory/crateAllocationRuleset', async (values, { dispatch }) => {
  const { data } = await api.post<SiAllocationRuleset>(
    `/sleeper-inventory/allocation-rulesets`,
    values
  );
  dispatch(setSiAllocationRuleset(data));
  return data;
});

export const updateSiAllocationRuleset = createAsyncThunk<
  SiAllocationRuleset,
  Partial<SiAllocationRuleset>
>(
  'sleeperInventory/updateAllocationRuleset',
  async (values, { dispatch, getState }) => {
    const allocationRuleset = currentSiAllocationRulesetSelector(getState())!;
    const { data } = await api.put<SiAllocationRuleset>(
      `/sleeper-inventory/allocation-rulesets/${allocationRuleset.id}`,
      values
    );
    dispatch(setSiAllocationRuleset(data));
    return data;
  }
);

export const copySiAllocationRuleset = createAsyncThunk<SiAllocationRuleset>(
  'sleeperInventory/copyAllocationRuleset',
  async (_, { dispatch, getState }) => {
    const allocationRuleset = currentSiAllocationRulesetSelector(getState())!;
    const { data } = await api.post<SiAllocationRuleset>(
      `/sleeper-inventory/allocation-rulesets/${allocationRuleset.id}/copy`
    );
    dispatch(setSiAllocationRuleset(data));
    return data;
  }
);

export const deleteSiAllocationRuleset = createAsyncThunk<void, string>(
  'sleeperInventory/deleteAllocationRuleset',
  async (id) => {
    (
      await api.delete(
        `/sleeper-inventory/allocation-rulesets/bulk-delete${qs.stringify(
          { ids: [id].join(',') },
          { addQueryPrefix: true }
        )}`
      )
    ).data;
  }
);

export const createUpdateSiAllocationRulesetRule = createAsyncThunk<
  SiAllocationRulesetRule,
  SiAllocationRulesetValues
>(
  'sleeperInventory/createUpdateAllocationRulesetRule',
  async ({ id, ...values }, { dispatch, getState }) => {
    const allocationRuleset = currentSiAllocationRulesetSelector(getState())!;
    const { data } = await (id ? api.put : api.post)<SiAllocationRulesetRule>(
      [
        `/sleeper-inventory/allocation-rulesets/${allocationRuleset.id}/rules`,
        id && `/${id}`,
      ]
        .filter(Boolean)
        .join(''),
      values
    );
    dispatch(getSiAllocationRuleset(allocationRuleset.id));
    return data;
  }
);

export const deleteSiAllocationRulesetRule = createAsyncThunk<
  void,
  Array<SiAllocationRulesetRule['id']>
>(
  'sleeperInventory/deleteAllocationRulesetRule',
  async (ids, { dispatch, getState }) => {
    const allocationRuleset = currentSiAllocationRulesetSelector(getState())!;
    (
      await api.delete(
        `/sleeper-inventory/allocation-rulesets/${
          allocationRuleset.id
        }/rules/bulk-delete${qs.stringify(
          { ids: ids.join(',') },
          { addQueryPrefix: true }
        )}`
      )
    ).data;
    dispatch(getSiAllocationRuleset(allocationRuleset.id));
  }
);
