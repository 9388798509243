import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransSubtitle = createTrans({
  allocationsQty: (
    <Trans
      i18nKey="subtitle.allocationsQty"
      defaults="(0)[no allocations];(1)[{{count}} allocation];(2-inf)[{{count}} allocations];"
    />
  ),
  details: <Trans i18nKey="subtitle.details" defaults="Details" />,
  rules: <Trans i18nKey="subtitle.rules" defaults="Rules" />,
  search: <Trans i18nKey="subtitle.search" defaults="Search" />,
  rulesQty: (
    <Trans
      i18nKey="subtitle.rulesQty"
      defaults="(0)[no rules];(1)[{{count}} rule];(2-inf)[{{count}} rules];"
    />
  ),
  searchResults: (
    <Trans i18nKey="subtitle.searchResults" defaults="Search results" />
  ),
});
