import { createSlice } from '@reduxjs/toolkit';
import { SiAllocationRuleset } from 'dto/siAllocationRuleset';
import {
  getSiAllocationRulesets,
  setSiAllocationRuleset,
} from 'features/siAllocationRuleset/siAllocationRulesetActions';

export interface SiAllocationRulesetState {
  list?: Array<SiAllocationRuleset>;
  current?: SiAllocationRuleset;
}

const initialState: SiAllocationRulesetState = {};

export const { reducer: siAllocationRulesetReducer } = createSlice({
  name: 'siAllocationRuleset',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSiAllocationRulesets.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(setSiAllocationRuleset, (state, action) => {
        state.current = action.payload;
      });
  },
});
