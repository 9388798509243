import { FC, useEffect, useState } from 'react';
import { getCompositions } from 'features/composition/compositionActions';
import {
  getLines,
  getSubContractors,
} from 'features/classification/classificationActions';
import { useDispatch } from 'store/utils';
import { Loader } from '@fleet/shared/mui';

interface RelationsSearchWrapperProps {
  className?: string;
  onMount?: () => void;
}

export const RelationsSearchWrapper: FC<RelationsSearchWrapperProps> = ({
  onMount,
  children,
}) => {
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      onMount && onMount(),
      dispatch(getCompositions()),
      dispatch(getLines()),
      dispatch(getSubContractors()),
    ]).then(() => setInitialLoading(false));
  }, [dispatch, onMount]);

  if (initialLoading) return <Loader active size="fullscreen" />;

  return <>{children}</>;
};
