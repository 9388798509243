import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Paper } from '@mui/material';
import classNames from 'classnames';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(2),
      flex: 1,
    },
  }),
  {
    name: 'LayoutColScrollable',
  }
);

interface LayoutColScrollableProps {
  className?: string;
}

export const LayoutColScrollable: FC<LayoutColScrollableProps> = ({
  className,
  children,
}) => {
  const classes = useStyles();

  return (
    <Paper
      variant="surface"
      className={classNames(classes.root, 'scrollable', className)}
    >
      {children}
    </Paper>
  );
};
