import { createSlice } from '@reduxjs/toolkit';
import { AllocationScoreTemplate } from 'dto/allocationScoreTemplate';
import {
  clearAllocationScoreTemplates,
  getAllocationScoreTemplate,
  getAllocationScoreTemplates,
  setAllocationScoreTemplate,
} from 'features/allocationScoreTemplate/allocationScoreTemplateActions';

export interface AllocationScoreTemplateState {
  list?: Array<AllocationScoreTemplate>;
  current?: AllocationScoreTemplate;
}

const initialState: AllocationScoreTemplateState = {};

export const { reducer: allocationScoreTemplateReducer } = createSlice({
  name: 'allocationScoreTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllocationScoreTemplates.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(clearAllocationScoreTemplates, (state) => {
        state.list = undefined;
      })
      .addCase(getAllocationScoreTemplate.fulfilled, (state, action) => {
        state.current = action.payload;
      })
      .addCase(setAllocationScoreTemplate, (state, { payload }) => {
        state.current = payload;
      });
  },
});
