import { createAsyncThunk } from 'store/utils';
import {
  InventoryClass,
  InventoryClassFilter,
  InventoryClassPayload,
} from 'dto/inventoryClass';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import { Pagination } from '@fleet/shared/dto/pagination';
import { api } from '@fleet/shared';

export const clearInventoryClasses = createAction(
  'inventoryClass/clearInventoryClasses'
);

export const setInventoryClass = createAction<InventoryClass | undefined>(
  'inventoryClass/setInventoryClass'
);

export const getInventoryClasses = createAsyncThunk<
  Pagination<InventoryClass>,
  Partial<InventoryClassFilter> | undefined
>(
  'inventoryClass/getInventoryClasses',
  async (filter, { getState, dispatch }) => {
    if (filter) {
      dispatch(setInventoryClassFilter(filter));
    }

    return (
      await api.get(
        `/business-entities/${currentBusinessEntityIdSelector(
          getState()
        )}/inventory-classes${qs.stringify(filter, { addQueryPrefix: true })}`
      )
    ).data;
  }
);

export const getInventoryClass = createAsyncThunk<InventoryClass, string>(
  'inventoryClass/getInventoryClass',
  async (id, { getState }) =>
    (
      await api.get(
        `/business-entities/${currentBusinessEntityIdSelector(
          getState()
        )}/inventory-classes/${id}`
      )
    ).data
);

export const createOrUpdateInventoryClass = createAsyncThunk<
  InventoryClass,
  Partial<InventoryClassPayload>
>(
  'inventoryClass/createOrUpdateInventoryClass',
  async ({ id, inventoryType, ...payload }, { getState }) => {
    const inventoryTypeName = inventoryType?.split('.')[1];

    return (
      await (id ? api.put : api.post)(
        `/business-entities/${currentBusinessEntityIdSelector(
          getState()
        )}/inventory-classes/${inventoryTypeName}${id ? `/${id}` : ''}`,
        payload
      )
    ).data;
  }
);

export const setInventoryClassFilter = createAction<
  Partial<InventoryClassFilter>
>('inventoryClass/setInventoryClassFilter');
