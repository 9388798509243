import {
  FC,
  FocusEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import _uniq from 'lodash/uniq';
import { Input, RadioGroup, Select, Tooltip } from '@fleet/shared/mui';
import { useDispatch } from 'store/utils';
import {
  addManageableSpace,
  deleteFloor,
  deleteManageableSpace,
  updateFloor,
  updateManageableSpace,
} from 'features/floor/floorActions';
import { Floor, ManageableSpace } from '@fleet/widget/dto/floor';
import { TransTitle } from 'i18n/trans/title';
import { TransLabel } from 'i18n/trans/label';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';

type FloorPropertiesProps = Floor & {
  floorCount: number;
};

export const FloorProperties: FC<FloorPropertiesProps> = ({
  id,
  manageableSpaces,
  number,
  size,
  floorCount,
  places,
}) => {
  const dispatch = useDispatch();
  const numberInputRef = useRef<HTMLInputElement>(null);

  const [localManageableSpaces, setLocalManageableSpaces] =
    useState<ManageableSpace[]>(manageableSpaces);
  const updateLocalManageableSpace = useCallback((spaceToUpdate) => {
    setLocalManageableSpaces((spaces) =>
      spaces.map((space) =>
        space.id === spaceToUpdate.id ? spaceToUpdate : space
      )
    );
  }, []);

  useEffect(() => {
    setLocalManageableSpaces(manageableSpaces);
  }, [manageableSpaces]);

  const manageableSpaceTypeOptions = useClassificationOptions(
    ClassificationGroup.MANAGEABLE_SPACE_TYPE
  );

  const removeFloor = useCallback(
    () => dispatch(deleteFloor(id)),
    [dispatch, id]
  );

  const getUpdateFloorHandler = useCallback(
    (axis: 'x' | 'y') => (e: FocusEvent<HTMLInputElement>) =>
      dispatch(updateFloor({ id, size: { ...size, [axis]: e.target.value } })),
    [dispatch, id, size]
  );

  const availableCountManageableSpaceTypes = useCallback(
    (id?: string) => {
      const presentCountTypes = _uniq(
        manageableSpaces.map(({ type }) => type!.id)
      );

      return manageableSpaceTypeOptions.filter(
        ({ value }) =>
          (id && id === value) || presentCountTypes.indexOf(value) === -1
      );
    },
    [manageableSpaceTypeOptions, manageableSpaces]
  );

  const disableAddingSpaces = useMemo(
    () => localManageableSpaces.find(({ id }) => !id),
    [localManageableSpaces]
  );

  const addSpace = useCallback(() => {
    if (disableAddingSpaces) return;
    setLocalManageableSpaces((spaces) => [
      ...spaces,
      {
        type: null,
        count: 1,
        number: null,
      },
    ]);
  }, [disableAddingSpaces]);

  const getDeleteSpaceHandler = useCallback(
    (manageableSpaceId?: number) => () => {
      if (manageableSpaceId) {
        dispatch(
          deleteManageableSpace({
            floorId: id,
            id: manageableSpaceId,
          })
        );
      } else {
        setLocalManageableSpaces((spaces) => spaces.filter(({ id }) => id));
      }
    },
    [dispatch, id]
  );

  const updateSpace = useCallback(
    async (
      manageableSpace: ManageableSpace,
      updatePayload: Partial<ManageableSpace>
    ) => {
      const preparedPayload = { ...manageableSpace, ...updatePayload };
      const shouldFocusNumberField =
        updatePayload.typeId &&
        preparedPayload.count === 1 &&
        !preparedPayload.number;
      updateLocalManageableSpace(preparedPayload);

      if (shouldFocusNumberField) {
        return numberInputRef.current!.focus();
      }

      if (preparedPayload.count) {
        await (manageableSpace.id
          ? dispatch(
              updateManageableSpace({
                floorId: id,
                id: manageableSpace.id,
                updatePayload: preparedPayload,
              })
            )
          : dispatch(
              addManageableSpace({
                floorId: id,
                manageableSpace: preparedPayload,
              })
            ));
      }
    },
    [dispatch, id, updateLocalManageableSpace]
  );

  const getSpaceNumberHandler = useCallback(
    (manageableSpace: ManageableSpace) =>
      ({ target: { value } }: FocusEvent<HTMLInputElement>) => {
        if (manageableSpace.number !== value) {
          updateSpace(manageableSpace, {
            number: value,
          });
        }
      },
    [updateSpace]
  );

  return (
    <div key={id} className="floor-size">
      <h4>
        <TransTitle i18nKey="floorNumber" values={{ number }} />
        <Tooltip content={<TransLabel i18nKey="removeFloor" />}>
          <span
            className={classNames('circle-btn small', {
              hidden: floorCount === 1,
            })}
            onClick={removeFloor}
          >
            —
          </span>
        </Tooltip>
      </h4>
      <Input
        label={<TransLabel i18nKey="width" />}
        value={size.x}
        type="number"
        onBlur={getUpdateFloorHandler('x')}
        required
        margin="dense"
      />
      <Input
        label={<TransLabel i18nKey="height" />}
        value={size.y}
        type="number"
        onBlur={getUpdateFloorHandler('y')}
        required
        margin="dense"
      />
      <div className="input-wrapper seats-quantity">
        <label>
          <TransLabel i18nKey="numberOfSeats" />
        </label>
        <span>{places.length}</span>
      </div>
      {localManageableSpaces.map((manageableSpace, idx) => (
        <Fragment key={manageableSpace.id || idx}>
          <h4 className="manageable-space">
            <TransTitle
              i18nKey="manageableSpaceForFloorNumber"
              values={{ number }}
            />
            <Tooltip content={<TransLabel i18nKey="removeManageableSpace" />}>
              <span
                className="circle-btn small"
                onClick={getDeleteSpaceHandler(manageableSpace.id)}
              >
                —
              </span>
            </Tooltip>
          </h4>
          <RadioGroup
            name={String(manageableSpace.id)}
            defaultValue={manageableSpace.count === 1 ? 'unit' : 'count'}
            options={[
              {
                label: <TransLabel i18nKey="asCount" />,
                value: 'count',
              },
              {
                label: <TransLabel i18nKey="asSingleUnit" />,
                value: 'unit',
              },
            ]}
            onChange={(value) => {
              updateLocalManageableSpace({
                ...manageableSpace,
                count: value !== 'count' ? 1 : null,
                number: null,
              });
            }}
            inline
          >
            {(activeValue) => (
              <>
                <Select
                  label={<TransLabel i18nKey="type" />}
                  options={
                    activeValue === 'count'
                      ? availableCountManageableSpaceTypes(
                          manageableSpace.type?.id
                        )
                      : manageableSpaceTypeOptions
                  }
                  value={manageableSpace.type?.id}
                  onChange={(value) => {
                    const typeId = value as string;
                    updateSpace(manageableSpace, {
                      typeId,
                      type: { id: typeId },
                    });
                  }}
                  margin="dense"
                />
                {activeValue === 'count' ? (
                  <Input
                    label={<TransLabel i18nKey="count" />}
                    type="number"
                    value={manageableSpace.count || ''}
                    onBlur={({ target: { value } }) => {
                      value &&
                        updateSpace(manageableSpace, {
                          count: +value,
                        });
                    }}
                    margin="dense"
                  />
                ) : (
                  <Input
                    label={<TransLabel i18nKey="seatNumber" />}
                    inputRef={numberInputRef}
                    disabled={!manageableSpace.type}
                    value={manageableSpace.number || ''}
                    onBlur={getSpaceNumberHandler(manageableSpace)}
                    margin="dense"
                    resetOnFail
                  />
                )}
              </>
            )}
          </RadioGroup>
        </Fragment>
      ))}
      <div
        className={classNames('link add-space', {
          disabled: disableAddingSpaces,
        })}
        onClick={addSpace}
      >
        <TransLabel i18nKey="addNewSpace" />
      </div>
    </div>
  );
};
