import { createReducer } from '@reduxjs/toolkit';
import type {
  ClassificationKey,
  ClassifierWithLocalizations,
  ServiceCode,
} from 'dto/classification';
import { ClassificationGroup, LineDto } from 'dto/classification';
import {
  getBusinessEntities,
  getClassificationGroups,
  getFloorElementsList,
  getInventoryBlockingReasons,
  getInventoryClasses,
  getLines,
  getServiceCodes,
  getSubContractors,
} from 'features/classification/classificationActions';
import type { InventoryClass } from 'dto/inventoryClass';
import type { InventoryBlockingReason } from 'dto/organization';
import type { Classifier } from '@fleet/shared/dto/classifier';
import { PreparedPaletteElement } from '@fleet/widget/dto/element';
import { BusinessEntity } from '@fleet/shared/dto/businessEntity';

type ClassificationState = Record<
  ClassificationKey,
  Array<ClassifierWithLocalizations>
> & {
  [ClassificationGroup.BUSINESS_ENTITY]: Array<BusinessEntity<number>>;
  [ClassificationGroup.INVENTORY_CLASS]: Array<InventoryClass>;
  [ClassificationGroup.INVENTORY_BLOCKING_REASON]: Array<InventoryBlockingReason>;
  [ClassificationGroup.LINE]: Array<LineDto>;
  [ClassificationGroup.SUB_CONTRACTOR]: Array<Classifier>;
  SERVICE_CODE: Array<ServiceCode>;
  FLOOR_ELEMENT: Record<string, PreparedPaletteElement>;
};

const initialState = {
  FLOOR_ELEMENT: {},
} as ClassificationState;

export const classificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getClassificationGroups.fulfilled, (state, action) => {
      action.payload.forEach((data) => (state[data.id] = data.classifications));
    })
    .addCase(getBusinessEntities.fulfilled, (state, action) => {
      state.BUSINESS_ENTITY = action.payload;
    })
    .addCase(getInventoryClasses.fulfilled, (state, action) => {
      state.INVENTORY_CLASS = action.payload;
    })
    .addCase(getInventoryBlockingReasons.fulfilled, (state, action) => {
      state.INVENTORY_BLOCKING_REASON = action.payload;
    })
    .addCase(getSubContractors.fulfilled, (state, action) => {
      state.SUB_CONTRACTOR = action.payload;
    })
    .addCase(getLines.fulfilled, (state, action) => {
      state.LINE = action.payload;
    })
    .addCase(getFloorElementsList.fulfilled, (state, action) => {
      state.FLOOR_ELEMENT = action.payload;
    })
    .addCase(getServiceCodes.fulfilled, (state, action) => {
      state.SERVICE_CODE = action.payload;
    });
});
