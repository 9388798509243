import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import {
  CardHeader,
  ConditionField,
  DateField,
  DatesArrayField,
  DrawerForm,
  DrawerFormProps,
  FormProvider,
  Icon,
  RadioGroupField,
  SelectField,
  useForm,
} from '@fleet/shared';
import { Button, CardContent, Stack } from '@mui/material';
import { SiServiceAllocationRuleset } from 'dto/siServiceAllocationRuleset';
import type { Config as FormConfig } from 'final-form';
import { TransTitle } from 'i18n/trans/title';
import Grid from '@mui/material/Grid';
import { TransLabel } from 'i18n/trans/label';
import { useDispatch, useSelector } from 'store/utils';
import { TransButton } from 'i18n/trans/button';
import { useHistory, useParams } from 'react-router-dom';
import {
  createUpdateSiServiceAllocationRuleset,
  getSiServiceAllocationRuleset,
  getSiServiceAllocationRulesets,
  setSiServiceAllocationRuleset,
} from 'features/siServiceAllocationRuleset/siServiceAllocationRulesetActions';
import { siAllocationRulesetOptionsSelector } from 'features/siAllocationRuleset/siAllocationRulesetSelectors';
import { selectSiServiceAllocationRuleset } from 'features/siServiceAllocationRuleset/siServiceAllocationRulesetSelectors';
import { useAlert } from 'react-alert';
import { TransMessage } from 'i18n/trans/message';
import { serviceCodeOptionsSelector } from 'features/classification/classificationSelectors';

const useStyles = makeStyles(() => ({ root: {} }), {
  name: 'SiServiceAllocationRulesetDetails',
});

interface SiServiceAllocationRulesetDetailsProps {}

const defaultValues = {
  exceptionDates: [],
  isException: false,
};

export const SiServiceAllocationRulesetDetails: FC<
  SiServiceAllocationRulesetDetailsProps
> = () => {
  const { action, id } = useParams<{
    action: 'create' | 'edit';
    id?: string;
  }>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSiServiceAllocationRuleset());
    if (action === 'edit' && id) {
      dispatch(getSiServiceAllocationRuleset(id));
    }
    return () => {
      dispatch(setSiServiceAllocationRuleset());
    };
  }, [action, dispatch, id]);

  const siServiceAllocationRuleset = useSelector(
    selectSiServiceAllocationRuleset
  );
  const siAllocationRulesetOptions = useSelector(
    siAllocationRulesetOptionsSelector
  );
  const serviceCodeOptions = useSelector(serviceCodeOptionsSelector);

  const initialValues = useMemo<Partial<SiServiceAllocationRuleset>>(
    () => ({
      ...defaultValues,
      ...(siServiceAllocationRuleset && {
        ...siServiceAllocationRuleset,
        exceptionDates:
          siServiceAllocationRuleset?.exceptionDates ||
          defaultValues.exceptionDates,
      }),
    }),
    [siServiceAllocationRuleset]
  );

  const alert = useAlert();
  const history = useHistory();
  const onSubmit = useCallback<
    FormConfig<SiServiceAllocationRuleset>['onSubmit']
  >(
    async ({ isException, exceptionDates, validityPeriod, ...values }) => {
      const data = await dispatch(
        createUpdateSiServiceAllocationRuleset({
          isException,
          ...(isException ? { exceptionDates } : { validityPeriod }),
          ...values,
        })
      ).unwrap();
      if (!siServiceAllocationRuleset) {
        history.replace(`/service-allocation-rulesets/edit/${data.id}`);
      }

      alert.success(
        <TransMessage
          i18nKey={
            siServiceAllocationRuleset
              ? 'serviceSiAllocationRulesetUpdated'
              : 'serviceSiAllocationRulesetCreated'
          }
        />
      );

      dispatch(getSiServiceAllocationRulesets());
    },
    [alert, dispatch, history, siServiceAllocationRuleset]
  );
  const { form, handleSubmit, dirty, pristine, submitting } =
    useForm<SiServiceAllocationRuleset>({
      initialValues,
      onSubmit,
      subscription: { dirty: true, pristine: true, submitting: true },
    });

  useEffect(() => {
    if (!siServiceAllocationRuleset) {
      form.restart(defaultValues);
    }
  }, [siServiceAllocationRuleset, form]);

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  const handleGoBack = useCallback(() => {
    history.replace('/service-allocation-rulesets');
  }, [history]);

  const handleCloseEditForm: DrawerFormProps['onClose'] = useCallback(
    (_, reason) => {
      if (reason === 'close') {
        handleGoBack();
      }
    },
    [handleGoBack]
  );

  const classes = useStyles();

  return (
    <DrawerForm
      open
      classes={{ modal: classes.root }}
      onClose={handleCloseEditForm}
    >
      <FormProvider form={form}>
        <Stack component="form" onSubmit={handleSubmit} flexDirection="column">
          <CardHeader
            isLight
            title={
              <TransTitle
                i18nKey={
                  siServiceAllocationRuleset
                    ? 'serviceSiAllocationRuleset'
                    : 'newServiceSiAllocationRuleset'
                }
              />
            }
          />
          <CardContent>
            <Grid container columns={3} spacing={2}>
              <Grid item xs={1}>
                <SelectField
                  name="sleeperInventoryAllocationRulesetId"
                  label={
                    <TransLabel i18nKey="sleeperInventoryAllocationRuleset" />
                  }
                  options={siAllocationRulesetOptions}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <SelectField
                  name="serviceCodes"
                  label={<TransLabel i18nKey="serviceCodes" />}
                  options={serviceCodeOptions}
                  multiple
                  required
                />
              </Grid>

              <Grid item xs={1}>
                <RadioGroupField
                  name="isException"
                  label={<TransLabel i18nKey="isException" />}
                  options="BOOL_ONLY"
                  inline
                />
              </Grid>
              <Grid item xs={2} />

              <ConditionField when="isException" is={false}>
                <>
                  <Grid item xs={1}>
                    <DateField
                      name="validityPeriod.from"
                      label={<TransLabel i18nKey="validFrom" />}
                      dateType="start"
                      isClearable
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <DateField
                      name="validityPeriod.to"
                      label={<TransLabel i18nKey="validTo" />}
                      dateType="end"
                      isClearable
                    />
                  </Grid>
                </>
              </ConditionField>

              <ConditionField when="isException" is={true}>
                <Grid item xs={3}>
                  <DatesArrayField
                    name="exceptionDates"
                    label={<TransLabel i18nKey="exceptionDates" />}
                  />
                </Grid>
              </ConditionField>

              <Grid item sx={{ ml: 'auto', mt: 'auto' }}>
                {siServiceAllocationRuleset ? (
                  <>
                    <Button onClick={handleReset} disabled={!dirty}>
                      <TransButton i18nKey="resetChanges" />
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<Icon name="check" />}
                      disabled={pristine || submitting}
                      type="submit"
                    >
                      <TransButton i18nKey="save" />
                    </Button>
                  </>
                ) : (
                  <>
                    <Button onClick={handleGoBack}>
                      <TransButton i18nKey="cancel" />
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<Icon name="plus" />}
                      disabled={submitting}
                      type="submit"
                    >
                      <TransButton i18nKey="create" />
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Stack>
      </FormProvider>
    </DrawerForm>
  );
};
