import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { setActiveFloorIdx, addElement } from 'features/floor/floorActions';
import { setCurrentVehicle } from 'features/vehicle/vehicleActions';
import {
  getComposition,
  getAssignedComposition,
} from 'features/composition/compositionActions';

import type { Floor, ManageableSpace } from '@fleet/widget/dto/floor';

export interface FloorState {
  list: Array<Floor>;
  activeFloorIdx: number;
  manageableSpaces: ManageableSpace[];
}

const initialState: FloorState = {
  list: [],
  activeFloorIdx: 0,
  manageableSpaces: [],
};

export const { reducer: floorReducer } = createSlice({
  name: 'floor',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setCurrentVehicle, (state, { payload: vehicle }) => {
        if (vehicle) {
          state.list = vehicle?.floors;
        } else {
          state.list = [];
        }
      })
      .addCase(setActiveFloorIdx, (state, action) => {
        state.activeFloorIdx = action.payload;
      })
      .addCase(addElement, (state, action) => {
        const { floorElements } = action.payload;
        const category = floorElements[0].floorCategory;

        state.list[state.activeFloorIdx][category].push(...floorElements);
      })
      .addMatcher(
        isAnyOf(getComposition.fulfilled, getAssignedComposition.fulfilled),
        (state) => {
          state.activeFloorIdx = 0;
        }
      );
  },
});
