import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { RouteComponentProps } from 'react-router-dom';
import { CardHeader, Layout, Loadable, SearchResult } from '@fleet/shared';
import {
  clearCurrentComposition,
  getComposition,
} from 'features/composition/compositionActions';
import { useDispatch, useSelector } from 'store/utils';
import { compositionConstructSelector } from 'features/composition/compositionSelectors';
import { TripSearch } from 'components/trip/TripSearch';
import { TripDto, TripVehicleComposition } from 'dto/trip';
import { Row } from 'react-table';
import { RelationsSearchWrapper } from 'components/relationsSearchWrapper/RelationsSearchWrapper';
import { TripEditModal } from 'routes/assignment/TripEditModal';
import { TripDetails } from 'components/trip/TripDetails';
import { useCompositionManage } from 'hooks/useCompositionManage';
import { Button, Icon, RadioGroup } from '@fleet/shared/mui';
import { Stack, Typography } from '@mui/material';
import { ModalProps } from '@fleet/shared/mui/Modal';
import { RadioGroupProps } from '@fleet/shared';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { TransButton } from 'i18n/trans/button';
import { getCompositionTrips, setTrips } from 'features/trip/tripActions';
import {
  TripSearchTable,
  TripSearchTableProps,
} from 'components/trip/TripSearchTable';
import { getCompositionTripsLoadingSelector } from 'features/loading/loadingSelectors';

const useStyles = makeStyles(
  () => ({
    assigned: {
      flex: 'unset',
      minHeight: 'unset',
      paddingBottom: 0,
    },
  }),
  {
    name: 'LineAssignment',
  }
);

interface LineAssignmentProps
  extends RouteComponentProps<{ compositionId: string }> {}

export const TripAssignment: FC<LineAssignmentProps> = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setTrips());
      dispatch(clearCurrentComposition());
    };
  }, [dispatch]);

  const { compositionId } = match.params;
  const { state, onModalClose, getModalHandler } = useCompositionManage<
    TripDto,
    TripVehicleComposition
  >();
  const [connectedTripsStatus, setConnectedTripsStatus] = useState(true);
  const loading = useSelector(getCompositionTripsLoadingSelector);
  const compositionConstruct = useSelector(compositionConstructSelector);
  const getCurrentCompositionTrips = useCallback(
    async (params?: PaginationParams) => {
      await dispatch(
        getCompositionTrips({
          vehicleCompositionId: +compositionId,
          isTripActive: connectedTripsStatus,
          ...params,
        })
      ).unwrap();
    },
    [compositionId, connectedTripsStatus, dispatch]
  );
  const tripActiveHandler = useCallback<Required<RadioGroupProps>['onChange']>(
    (val) => setConnectedTripsStatus(Boolean(+val)),
    []
  );

  const getCompositionWithTrips = useCallback(async () => {
    await dispatch(getComposition(compositionId));
    await getCurrentCompositionTrips();
  }, [compositionId, dispatch, getCurrentCompositionTrips]);

  const getCurrentVehicleComposition = useCallback(
    (trip: TripDto) =>
      trip.vehicleCompositions.find(
        (comp) =>
          comp.originatesFromVehicleCompositionId === compositionConstruct?.id
      ),
    [compositionConstruct]
  );

  const onModalCloseHandler = useCallback<Required<ModalProps>['onClose']>(
    async (event, reason) => {
      reason === 'action' && (await getCurrentCompositionTrips());
      onModalClose(event, reason);
    },
    [getCurrentCompositionTrips, onModalClose]
  );

  const connectedTripsControls = useCallback(
    (trips: TripDto[]) => {
      const vehicleComposition = getCurrentVehicleComposition(trips[0])!;
      return (
        <>
          <Button
            variant="text"
            startIcon={<Icon name="replace" />}
            onClick={getModalHandler({
              data: trips,
              vehicleComposition,
              action: 'replace',
            })}
            label={
              <Typography variant="body2">
                <TransButton i18nKey="update" />
              </Typography>
            }
          />
          <Button
            variant="text"
            startIcon={<Icon name="close" />}
            onClick={getModalHandler({
              data: trips,
              vehicleComposition,
              action: 'delete',
            })}
            label={
              <Typography variant="body2">
                <TransButton i18nKey="delete" />
              </Typography>
            }
          />
        </>
      );
    },
    [getCurrentVehicleComposition, getModalHandler]
  );

  const searchTripsControl = useCallback(
    (trips: TripDto[]) => {
      return (
        <Button
          variant="text"
          startIcon={<Icon name="plus" />}
          onClick={getModalHandler({
            data: trips,
            vehicleComposition: {
              name: compositionConstruct!.name,
              originatesFromVehicleCompositionId: compositionConstruct!.id,
            },
            action: 'add',
          })}
          label={
            <Typography variant="body2">
              <TransLabel i18nKey="addComposition" />
            </Typography>
          }
        />
      );
    },
    [compositionConstruct, getModalHandler]
  );
  const getSubRow = useCallback<Required<TripSearchTableProps>['getSubRow']>(
    (row) => (
      <TripDetails
        path="/manage/trip"
        getModalHandler={getModalHandler}
        {...row}
      />
    ),
    [getModalHandler]
  );

  const [currentAssignedTrips, totalAssignedTrips] = useMemo(() => {
    if (!compositionConstruct || !compositionConstruct.trips) return [[], 0];

    return [
      compositionConstruct.trips.data,
      compositionConstruct.trips.totalCount,
    ];
  }, [compositionConstruct]);
  const assignedTripsPresent = !!compositionConstruct?.trips?.data?.length;
  const title = useMemo(() => {
    if (!compositionConstruct) return '';
    const { name, code } = compositionConstruct;

    return [name, code].filter(Boolean).join('/');
  }, [compositionConstruct]);

  return (
    <RelationsSearchWrapper onMount={getCompositionWithTrips}>
      <Layout
        className={classes.assigned}
        header={<CardHeader title={title} />}
      >
        <Loadable loading={loading}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle" sx={{ p: 3 }}>
              <TransTitle i18nKey="tripsConnected" />
              {assignedTripsPresent && (
                <Typography variant="body2" component="span" sx={{ ml: 2 }}>
                  <TransLabel
                    i18nKey="qtyTemplates"
                    values={{
                      count: compositionConstruct!.trips!.totalCount,
                    }}
                    tOptions={{ postProcess: 'interval' }}
                  />
                </Typography>
              )}
            </Typography>
            <RadioGroup
              name="isConnectedTripActive"
              label={<TransLabel i18nKey="active" />}
              labelPosition="left"
              defaultValue={connectedTripsStatus}
              options={[
                { label: <TransLabel i18nKey="yes" />, value: true },
                { label: <TransLabel i18nKey="no" />, value: false },
              ]}
              onChange={tripActiveHandler}
              inline
            />
          </Stack>

          <SearchResult
            loading={false}
            results={assignedTripsPresent}
            message={<TransLabel i18nKey="noTripsAssigned" />}
            icon={false}
          >
            {assignedTripsPresent && (
              <>
                <TripSearchTable
                  vehicleConstructId={compositionConstruct!.id}
                  data={currentAssignedTrips!}
                  total={totalAssignedTrips}
                  onPageChange={getCurrentCompositionTrips}
                  controlsAccessor={connectedTripsControls}
                  getSubRow={(row: Row<TripDto>) => (
                    <TripDetails
                      path="/manage/trip"
                      vehicleConstructId={+compositionId}
                      {...row}
                    />
                  )}
                  plugins={false}
                />
              </>
            )}
          </SearchResult>
        </Loadable>
      </Layout>

      <TripSearch
        vehicleCompositionRelation="all"
        refreshSearch={state.refreshSearch}
        controlsAccessor={searchTripsControl}
        getSubRow={getSubRow}
        height="auto"
      />
      {!!state.data?.length && (
        <TripEditModal
          trips={state.data}
          vehicleComposition={state.vehicleComposition!}
          action={state.action}
          onClose={onModalCloseHandler}
        />
      )}
    </RelationsSearchWrapper>
  );
};
