import type { ReactChild } from 'react';
import { makeStyles } from '@mui/styles';
import {
  type TableRowProps,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';

const useStyles = makeStyles(
  () => ({
    tableCell: {
      borderBottom: 0,
    },
  }),
  {
    name: 'ElementDetailsRow',
  }
);

interface ElementElementDetailsProps extends TableRowProps {
  columns: Array<ReactChild | null | undefined>;
}

export function ElementDetailsRow({
  columns,
  ...props
}: ElementElementDetailsProps) {
  const [label, value] = columns;
  const classes = useStyles();
  return (
    <TableRow {...props}>
      {value ? (
        <>
          <TableCell className={classes.tableCell}>
            <Typography component="div" color="textSecondary">
              {label}
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Typography component="div">{value}</Typography>
          </TableCell>
        </>
      ) : (
        <TableCell colSpan={2} className={classes.tableCell}>
          <Typography component="div" fontWeight="bold">
            {label}
          </Typography>
        </TableCell>
      )}
    </TableRow>
  );
}
