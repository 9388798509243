import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DepartureTimes } from 'dto/vehicle';
import { WEEK_DAYS } from 'features/utils';
import classNames from 'classnames';
import { formatTime } from '@fleet/shared/utils/date';
import { Icon } from '@fleet/shared/mui';
import { TransLabel } from 'i18n/trans/label';

interface TimetableProps {
  departureTimes: DepartureTimes[];
  selectInitialValues?: boolean;
  onChange?: (payload: TimetableProps['departureTimes']) => void;
  initialValue?: DepartureTimes[];
}

export const Timetable: FC<TimetableProps> = ({
  departureTimes,
  onChange,
  initialValue,
}) => {
  const [updatedTimes, setDepartureTimes] = useState<Array<string>[]>(
    WEEK_DAYS.map(() => [])
  );
  const preparedInitialValue = useMemo(() => {
    if (!initialValue) return;

    if (initialValue.length < 7) {
      return WEEK_DAYS.map(
        (day) =>
          initialValue.find(({ departureDay }) => departureDay === day) || {
            day,
            departureTimes: [],
          }
      );
    }

    return initialValue;
  }, [initialValue]);

  const departureTimesByDays: Record<string, DepartureTimes['departureTimes']> =
    useMemo(
      () =>
        departureTimes &&
        departureTimes.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.departureDay]: cur.departureTimes,
          }),
          {}
        ),
      [departureTimes]
    );

  useEffect(() => {
    if (preparedInitialValue) {
      setDepartureTimes(
        preparedInitialValue.map(({ departureTimes }) => departureTimes)
      );
    }
  }, [initialValue, preparedInitialValue]);

  const getClickHandler = useCallback(
    (dayIdx: number, time: string) => () => {
      if (!onChange) return;

      const currentDayTimes = updatedTimes[dayIdx];
      const updatedCurrentDay = currentDayTimes.includes(time)
        ? currentDayTimes.filter((t) => t !== time)
        : [...currentDayTimes, time];
      const updatedDepartureTimes = [
        ...updatedTimes.slice(0, dayIdx),
        updatedCurrentDay,
        ...updatedTimes.slice(dayIdx + 1),
      ];
      setDepartureTimes(updatedDepartureTimes);

      onChange(
        updatedDepartureTimes.map((times, idx) => ({
          departureDay: WEEK_DAYS[idx],
          departureTimes: times,
        }))
      );
    },
    [onChange, updatedTimes]
  );

  const getFormattedTime = useCallback((timeStr) => formatTime(timeStr), []);

  return (
    <div className="line-template-timetable">
      {WEEK_DAYS.map((day, dayIdx) => (
        <div className="column" key={day}>
          <div className="column-header">
            <TransLabel i18nKey={`day${day}`} />
          </div>
          {(departureTimesByDays[day] ?? []).map((time, idx) => {
            const isSelected = updatedTimes[dayIdx].includes(time);
            return (
              <div
                className={classNames('time', {
                  editable: !!onChange,
                  selected: isSelected,
                })}
                key={idx}
                onClick={getClickHandler(dayIdx, time)}
              >
                {onChange && isSelected && <Icon name="tick" color="white" />}
                {getFormattedTime(time)}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
