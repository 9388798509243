import type { FC } from 'react';
import type { LineTemplateSearchTableProps } from 'components/lineTemplate/LineTemplateSearchTable';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { RelationsSearchWrapper } from 'components/relationsSearchWrapper/RelationsSearchWrapper';
import { useCompositionManage } from 'hooks/useCompositionManage';
import { LineTemplateSearch } from 'components/lineTemplate/LineTemplateSearch';
import { LineTemplateDetails } from 'components/lineTemplate/LineTemplateDetails';
import { LineTemplateComposition } from 'routes/management/LineTemplateComposition';
import { LineTemplateEditModal } from 'routes/assignment/LineTemplateEditModal';
import { Button, Icon } from '@fleet/shared/mui';
import { Typography } from '@mui/material';
import { TransLabel } from 'i18n/trans/label';
import { TransButton } from 'i18n/trans/button';
import qs from 'qs';
import {
  LineTemplateDto,
  LineTemplateVehicleComposition,
} from 'dto/lineTemplate';
import { useDispatch } from 'store/utils';
import { setLineTemplates } from 'features/lineTemplate/lineTemplateActions';

const useStyles = makeStyles(
  () => ({
    button: { whiteSpace: 'nowrap' },
  }),
  {
    name: 'LineTemplate',
  }
);

interface LineTemplateProps extends RouteComponentProps {}

export const LineTemplate: FC<LineTemplateProps> = ({
  match: { path },
  location,
}) => {
  const dispatch = useDispatch();
  useEffect(
    () => () => {
      dispatch(setLineTemplates());
    },
    [dispatch]
  );

  const currentCompositionId = qs.parse(location.search.substring(1))
    .compositionId as string;

  const { state, onModalClose, getModalHandler } = useCompositionManage<
    LineTemplateDto,
    LineTemplateVehicleComposition
  >();

  const classes = useStyles();
  const addCompositionControl = useCallback<
    Required<LineTemplateSearchTableProps>['controlsAccessor']
  >(
    (lineTemplates) => (
      <Button
        variant="text"
        className={classes.button}
        startIcon={<Icon name="plus" />}
        onClick={getModalHandler({
          data: lineTemplates,
          vehicleComposition: {},
          action: 'addComposition',
        })}
        label={
          <Typography variant="body2">
            <TransLabel i18nKey="addComposition" />
          </Typography>
        }
      />
    ),
    [classes.button, getModalHandler]
  );

  const updateCompositionControl = useCallback<
    Required<LineTemplateSearchTableProps>['controlsAccessor']
  >(
    ([lineTemplate]) => {
      return (
        <Button
          variant="text"
          startIcon={<Icon name="replace" />}
          onClick={getModalHandler!({
            data: [lineTemplate],
            vehicleComposition: lineTemplate.vehicleCompositions.find(
              ({ lineTemplateVehicleCompositionId }) =>
                lineTemplateVehicleCompositionId === +currentCompositionId
            )!,
            action: 'replace',
          })}
          label={
            <Typography variant="body2">
              <TransButton i18nKey="update" />
            </Typography>
          }
        />
      );
    },
    [currentCompositionId, getModalHandler]
  );

  const getSubRow = useCallback<
    Required<LineTemplateSearchTableProps>['getSubRow']
  >(
    (row) => (
      <LineTemplateDetails
        path={path}
        getModalHandler={getModalHandler}
        {...row}
      />
    ),
    [getModalHandler, path]
  );

  return (
    <RelationsSearchWrapper>
      <Switch>
        <Route exact path={path}>
          <LineTemplateSearch
            vehicleCompositionRelation="has_compositions"
            refreshSearch={state.refreshSearch}
            controlsAccessor={addCompositionControl}
            getSubRow={getSubRow}
          />
        </Route>
        <Route path={`${path}/:lineTemplateId`}>
          <LineTemplateComposition
            controlsAccessor={updateCompositionControl}
            refreshComposition={state.refreshSearch}
          />
        </Route>
      </Switch>
      {Boolean(state.data?.length) && (
        <LineTemplateEditModal
          lineTemplates={state.data}
          vehicleComposition={state.vehicleComposition!}
          action={state.action}
          onClose={onModalClose}
        />
      )}
    </RelationsSearchWrapper>
  );
};
