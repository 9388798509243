import type { FC, FocusEventHandler } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TransKeys } from 'i18n/trans';
import { TransTitle } from 'i18n/trans/title';
import { Divider, FormHelperText, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Input } from '@fleet/shared/mui';
import { decimalToPercentage, percentageToDecimal } from '@fleet/shared';
import { TransLabel } from 'i18n/trans/label';
import { Vehicle } from '@fleet/widget/dto/vehicle';
import { TransMessage } from 'i18n/trans/message';
import { ReadOnlyField as Field } from '@fleet/shared/mui/ReadOnlyField';

const useStyles = makeStyles(() => ({ root: {} }), {
  name: 'SalesOpening',
});

type SalesOpeningValues = Pick<
  Vehicle,
  'salesOpeningPriority' | 'salesOpeningThresholdPercentage'
>;

export interface SalesOpeningProps {
  title: TransKeys<typeof TransTitle>;
  initialValues: SalesOpeningValues;
  onChange?: (values: SalesOpeningValues) => void;
}

export const SalesOpening: FC<SalesOpeningProps> = ({
  title,
  initialValues,
  onChange,
}) => {
  const classes = useStyles();
  const [values, setValues] = useState<SalesOpeningValues>(initialValues);
  const isValid = useCallback((values: SalesOpeningValues) => {
    const collection = [
      values.salesOpeningPriority,
      values.salesOpeningThresholdPercentage,
    ];
    return !(collection.some(Boolean) && !collection.every(Boolean));
  }, []);
  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const handleValuesChanged = useCallback<FocusEventHandler<HTMLInputElement>>(
    (event) => {
      const { id: name, value } = event.target;
      const newValues = { ...values, [name]: value };
      if (name === 'salesOpeningThresholdPercentage' && value) {
        newValues.salesOpeningThresholdPercentage = percentageToDecimal(
          Number(value)
        );
      }
      setValues(newValues);
      if (isValid(newValues)) onChange?.(newValues);
    },
    [onChange, isValid, values]
  );

  return (
    <div className={classes.root}>
      <Divider sx={{ my: 3 }} />

      <Typography variant="paragraph" fontWeight={700}>
        <TransTitle i18nKey={title} />
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs>
          <Input
            name="salesOpeningPriority"
            label={<TransLabel i18nKey="priorityOrder" />}
            value={values?.salesOpeningPriority ?? ''}
            onBlur={handleValuesChanged}
            margin="dense"
            type="number"
            innerSpin
          />
        </Grid>
        <Grid item xs>
          <Input
            name="salesOpeningThresholdPercentage"
            label={<TransLabel i18nKey="threshold" />}
            value={
              (values?.salesOpeningThresholdPercentage &&
                decimalToPercentage(values?.salesOpeningThresholdPercentage)) ??
              ''
            }
            onBlur={handleValuesChanged}
            margin="dense"
            type="number"
            inputProps={{ min: 0, max: 100, step: 1 }}
            innerSpin
          />
        </Grid>
        <Grid item xs={1} sx={{ textAlign: 'center', pt: 1 }}>
          <Field label={<>&bnbsp;</>} value="%" margin="dense" />
        </Grid>
        {!isValid(values) && (
          <Grid
            item
            xs={12}
            component={FormHelperText}
            sx={{
              pt: '0 !important',
              color: 'text.secondary',
              whiteSpace: 'break-spaces',
            }}
          >
            <TransMessage i18nKey="bothSalesOpeningFilled" />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
