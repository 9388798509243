import { FC, useMemo } from 'react';
import { Select, SelectProps } from '@fleet/shared/mui';
import { useSelector } from 'store/utils';
import { inventoryBlockingReasonsSelector } from 'features/classification/classificationSelectors';
import { InventoryBlockingLevel } from 'dto/organization';
import { TransLabel } from 'i18n/trans/label';

interface InventoryBlockingReasonSelectProps
  extends Omit<SelectProps<string>, 'options'> {
  level?: InventoryBlockingLevel;
}
export const InventoryBlockingReasonSelect: FC<InventoryBlockingReasonSelectProps> =
  ({ level, ...props }) => {
    const blockingReasons = useSelector(inventoryBlockingReasonsSelector);
    const options = useMemo(() => {
      return (
        level
          ? blockingReasons.filter(
              (blockingReason) => blockingReason.blockingType.id === level
            )
          : blockingReasons
      ).map(({ name, id }) => ({ label: name, value: id }));
    }, [blockingReasons, level]);

    return (
      <Select
        label={<TransLabel i18nKey="property.blockingReason" />}
        options={options}
        showEmptyOption
        margin="normal"
        {...props}
      />
    );
  };
