import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname, state } = useLocation<{ disableScrollTo?: boolean }>();

  useEffect(() => {
    if (!state?.disableScrollTo) window.scrollTo(0, 0);
  }, [pathname, state]);

  return null;
};
