import type { FC } from 'react';
import type { TableColumns } from '@fleet/shared/components/Table';
import type { TripDto } from 'dto/trip';
import { useMemo } from 'react';
import { ExternalLink } from '@fleet/shared/mui';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import {
  ManageTable,
  type ManageTableBaseProps,
} from 'components/manage/ManageTable';
import { TransLabel } from 'i18n/trans/label';

export interface TripSearchTableProps<D extends TripDto = TripDto>
  extends ManageTableBaseProps<D> {}

export const TripSearchTable: FC<TripSearchTableProps> = ({
  vehicleConstructId,
  controlsAccessor,
  data,
  ...tableProps
}) => {
  // const data = useMemo(() => trip?.items ?? [], [trip?.items]);
  const columns = useMemo<TableColumns<TripDto>>(
    () => [
      {
        id: 'departureDateTime',
        Header: <TransLabel i18nKey="departureDateTime" />,
        accessor: ({ departureDateTime, id }) => (
          <ExternalLink
            path={`/Trips/Trip/Edit/${id}`}
            content={formatDate(departureDateTime, currentDateTimeFormat)}
          />
        ),
      },
      {
        accessor: 'name',
        Header: <TransLabel i18nKey="name" />,
      },
      {
        accessor: 'serviceCode',
        Header: <TransLabel i18nKey="serviceCode" />,
      },
      {
        accessor: 'lineNumber',
        Header: <TransLabel i18nKey="lineNumber" />,
      },
      {
        id: 'subContractor',
        accessor: ({ subContractor }) =>
          subContractor || <TransLabel i18nKey="noSubContractor" />,
        Header: <TransLabel i18nKey="subContractor" />,
      },
    ],
    []
  );

  return (
    <ManageTable
      columns={columns}
      data={data}
      vehicleConstructId={vehicleConstructId}
      controlsAccessor={controlsAccessor}
      {...tableProps}
    />
  );
};
